export interface Giftcard {
  correlationId: number;
  transactionStatus: string;
  cardholderName: string;
  ccNumber: number;
  scv: number;
  amount: number;
  previousBalance: number;
  currentBalance: number;
}
