import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";

@ObjectType({ isAbstract: true })
export class AdQuantityBreakBase extends EntityBase<AdQuantityBreakBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field((type) => Int, { nullable: true })
  bundleId: number;
  @Field((type) => Int, { nullable: true })
  quantityBreakPoint: number;
  @Field({ nullable: true })
  qbSaleType: string;
  @Field({ nullable: true })
  qbSaleAmount: number;
}

@ObjectType("AdQuantityBreak", {})
export class AdQuantityBreak
  extends AdQuantityBreakBase
  implements Entity<AdQuantityBreakBase>
{
  toObject(): AdQuantityBreakBase {
    return null;
  }
}

@InputType()
export class AdQuantityBreakInput extends OmitType(
  AdQuantityBreak,
  [] as const,
  InputType
) {}
