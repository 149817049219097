import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { AdItemBase } from "../ad-item.model";

@ObjectType()
export class SearchAdItem {
  @Field(() => Int)
  position: number;
  @Field()
  itemNumber: string;
}
@InputType()
export class SearchAdItemParams {
  @Field(() => [Int])
  bundleIds: number[];
  @Field()
  itemNumber: string;
}

@InputType()
export class AdItemInput extends OmitType(
  AdItemBase,
  ["item"] as const,
  InputType
) {}

@InputType()
export class AdItemParams {
  @Field(() => Int)
  pageNumber: number;
  @Field(() => Int)
  size: number;
  @Field(() => [Int])
  bundleIds: number[];
  @Field({ nullable: true })
  manufacturer?: string;
  @Field({ nullable: true })
  relation?: "all" | "none";
  @Field({ nullable: true })
  sortBy?: string;
  @Field({ nullable: true })
  sortDir?: string;
  @Field({ nullable: true })
  itemDescription?: string;
  @Field({ nullable: true })
  itemNumber?: string;
}
