import { Field, Int, ObjectType } from '@nestjs/graphql';
import GraphQLJSON from 'graphql-type-json';

import { QuantityBreak } from '../../../../domain/promotions';
import { Entity, EntityBase } from '../../../../interfaces/entity.interface';
import { Image } from '../../../../interfaces/image.interface';
import { Brand } from '../../brand/brand.model';
import { Category } from '../../category/category.model';
import { KioskItem } from '../../item/graphql/kiosk-item.graphql';
import { Species } from '../../species/species.model';
import { Product } from '../product.model';

@ObjectType({ isAbstract: true })
export class KioskProductBase extends EntityBase<KioskProductBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  @Field({ nullable: true })
  title: string;

  @Field({ nullable: true })
  description: string;

  @Field((type) => Brand, { nullable: true })
  brand?: Brand;

  @Field((type) => [Species], { nullable: "itemsAndList" })
  species?: Species[];

  @Field((type) => [KioskItem], { nullable: "itemsAndList" })
  items?: KioskItem[];

  @Field(() => GraphQLJSON, { nullable: true })
  image?: Partial<Image>;

  @Field((type) => GraphQLJSON)
  highlights?: string[];

  @Field((type) => GraphQLJSON)
  categories?: Category[];

  @Field()
  totalProjectedSales: number;

  /** Elastic search score */
  @Field({ nullable: true })
  _score: number;

  constructor(props: KioskProductBase) {
    super(props);

    this.items = props.items?.map((i) => new KioskItem(i));
  }
}

@ObjectType("KioskProduct", {})
export class KioskProduct
  extends KioskProductBase
  implements Entity<KioskProductBase>
{
  /**
   * Gets the most abundant item's promotion/retail price and quantity available for a specific store.
   *
   * @param store - Store id
   */
  getStoreData(store: number) {
    return this.items.reduce(
      (
        highest: {
          id: string;
          displayPrice: number;
          promotionPrice: number;
          qtyAvailable: number;
          retailPrice: number;
          quantityBreak: QuantityBreak;
        },
        item
      ) => {
        const data = item.getStoreData(store);
        return data.qtyAvailable >= highest.qtyAvailable ? data : highest;
      },
      {
        id: null,
        displayPrice: null,
        promotionPrice: null,
        qtyAvailable: null,
        retailPrice: null,
        quantityBreak: null,
      }
    );
  }

  toObject() {
    return null;
  }

  /**
   * Transforms a Product into a KisokProduct
   *
   * @param product - Product
   * @returns
   */
  static fromProduct(product: Product): KioskProduct {
    return new KioskProduct({
      id: product.id,
      title: product.title,
      description: product.description,
      brand: product.brand,
      species: product.species,
      categories: product.categories,
      highlights: product.highlights,
      image: product.images[0] ?? null,
      items: product.items.map((i) => KioskItem.fromItem(i)),
      totalProjectedSales: product.totalProjectedSales,
      _score: null,
    });
  }
}
