/**
 * @packageDocumentation
 * @module Signages
 */

import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { SignTemplate } from ".";
import { Entity, EntityBase } from "../../interfaces";

@ObjectType({ isAbstract: true })
export class SignLabelBase extends EntityBase<SignLabelBase> {
  /**
   * Unique id for sign template
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Collection label is associated with
   */
  @Field((type) => Int, { nullable: true })
  collectionId: number;

  /**
   * This is the id that associates a Sign Label with an AdSignage.
   * Sign Labels don't have to have this relation though. It is
   * possible that they were created by the Sign Collection and
   * just belong to the Collection.
   */
  @Field((type) => Int, { nullable: true })
  signageId: number;

  /**
   * Name of entity associated with this label
   */
  @Field({ nullable: true })
  sourceType?: string;

  /**
   * id of entity associated with this label
   */
  @Field((type) => Int, { nullable: true })
  sourceId?: number;

  @Field((type) => Int, { nullable: true })
  quantity: number;

  @Field(() => GraphQLJSON, { nullable: true })
  template?: SignTemplate;

  @Field(() => GraphQLJSON, { nullable: true })
  storeNumbers?: number[];

  constructor(props) {
    super(props);
    this.template = props.template ? new SignTemplate(props.template) : null;
  }
}

@ObjectType()
export class SignLabel extends SignLabelBase implements Entity<SignLabelBase> {
  toObject() {
    return null;
  }
}

@InputType()
export class SignLabelInput extends OmitType(
  SignLabel,
  [] as const,
  InputType
) {}
