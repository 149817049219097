import { Field, InputType, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { CartItem } from "../..";

@ObjectType()
export class BestPricingResponse {
  @Field()
  totalDiscount: number;

  @Field(() => GraphQLJSON)
  cart: {
    item: CartItem;
    discount: any;
  }[];
}
