import { Field, Int, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";
import { Customer } from "../../customers";

/** @ignore */
@ObjectType({ isAbstract: true })
export class EcomUserBase extends EntityBase<EcomUserBase> {
  /**
   * The ID is of the User on Ecom
   */
  @Field(() => Int, { nullable: true })
  id: number;

  /**
   * This is the ID of the Customer account
   */
  @Field((type) => String, { nullable: true })
  customerNumber: Customer["id"];

  @Field((type) => String, { nullable: true })
  categoryPlan: string;

  @Field((type) => String, { nullable: true })
  email: string;

  @Field({ nullable: true })
  dateCreated: string;

  @Field((type) => GraphQLJSON, { nullable: true })
  billing: {
    firstName: string;
    lastName: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    email: string;
    phone: string;
  };

  @Field((type) => GraphQLJSON, { nullable: true })
  shipping: {
    firstName: string;
    lastName: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
}

/**
 * A User is a user account on Ecom
 */
@ObjectType()
export class EcomUser extends EcomUserBase implements Entity<EcomUserBase> {
  toObject() {
    return null;
  }
}
