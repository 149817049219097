import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";
@ObjectType({ isAbstract: true })
export class AdDateBase extends EntityBase<AdDateBase> {
  @Field((type) => Int, { nullable: true })
  id: number;
  @Field((type) => Int, { nullable: true })
  mediaId: number;
  @Field({ nullable: true })
  dateType: string;
  @Field({ nullable: true })
  dateValue: string;
}

@ObjectType("AdDate", {})
export class AdDate extends AdDateBase implements Entity<AdDateBase> {
  toObject() {
    return null;
  }
}

@InputType()
export class AdDateInput extends OmitType(AdDate, [] as const, InputType) {}
