import { Field, InputType, Int } from "@nestjs/graphql";
import createEnum from "../../../utils/create-enum";

const SIGNAGE_TYPES = {
  GENERIC: "generic",
  AD_ITEM: "ad-item",
  ASSORTMENT: "assortment",
};

export const [SignageTypeEnum, signageTypes] = createEnum(SIGNAGE_TYPES);

/**
 * Params used to search for a SignCollection
 */
@InputType({
  description: `Params used to search for a SignCollection`,
})
export class SignCollectionParams {
  /**
   * This can be the name of the Collection's name or
   * its associated media's name
   */
  @Field({
    nullable: true,
    description: `This can be the name of the Collection's name or its associated media's name`,
  })
  name?: string;

  /**
   * Get all collections that were created by a user (Used in Other collections)
   */
  @Field({
    nullable: true,
    description: `This only applies to generic collections that have been created by a user`,
  })
  userName?: string;

  // Deprecated
  @Field({ nullable: true })
  relations?: "media";

  /**
   * If true, will only show collections whose media archived
   * field is true and vice versa
   */
  @Field({
    nullable: true,
    description: `If true, will only show collections whose media archived field is true and vice versa`,
  })
  archived?: boolean;

  /**
   * This is a permission check. Some users are allowed to review
   * Collections and others require that a collection be approved in
   * order to view them.
   */

  /**
   * Filters collections based on whether their media end date has passed
   */
  @Field({
    nullable: true,
    description:
      "Filters collections based on whether their media end date has passed",
  })
  isExpired?: boolean;

  /**
   * Returns collections based on user's permissions to review
   */
  @Field({
    nullable: true,
    description: "Returns collections based on user's permissions to review",
  })
  canReview?: boolean;

  /**
   * Store number that the user is assigned
   */
  @Field(() => Int, {
    nullable: true,
    description: "Store number that the user is assigned",
  })
  storeNumber?: number;
}

/**
 * Params used to create a SignLabel in the Collection App
 */
@InputType({
  description: `Params used to create a SignLabel in the Collection App`,
})
export class LabelConfig {
  /**
   * The number of labels to create when generating PDF
   */
  @Field(() => Int, {
    description: `The number of labels to create when generating PDF`,
  })
  quantity: number;

  /**
   * The Sign Template associated with this label
   */
  @Field(() => Int, {
    description: `The Sign Template associated with this label`,
  })
  templateId: number;

  /**
   * The type of signage that we are dealing with; Assortment or AdItem.
   * More types may come up later.
   */
  @Field(() => String, {
    description: `The type of signage that we are dealing with; Assortment or AdItem. More types may come up later.`,
  })
  signageType: "generic" | "ad-item" | "assortment";

  /**
   * It is possible to pass a comma separated string of itemNumbers to this
   * query during creation and use their information to populate token data
   * of the Sign Label.
   */
  @Field(() => String, {
    description: `It is possible to pass a comma separated string of itemNumbers to this
     query during creation and use their information to populate token data
     of the Sign Label.`,
  })
  products: string;

  @Field(() => [Int], {
    nullable: true,
    description: `Id of the store creating a collection. 
    If an id isn't passed, only admins will be able to see them`,
  })
  storeNumbers?: number[];
}

/**
 * Payload that the SignCollection PDF generator expects
 */
@InputType({
  description: `Payload that the SignCollection PDF generator expects`,
})
export class GeneratorPayload {
  /**
   * Collection id that belongs to this payload
   */
  @Field(() => Int, {
    description: "Collection id that belongs to this payload",
  })
  collectionId: number;

  /**
   * The name of the template we are using
   */
  @Field(() => String, {
    description: "The name of the template we are using",
  })
  template: string;

  /**
   * The name of the version we are using, if any
   */
  @Field({
    nullable: true,
    description: "The name of the version we are using, if any",
  })
  version?: string;

  /**
   * Whether or not we want the original Sign Template image as
   * background in our final PDF
   */
  @Field({
    nullable: true,
    description:
      "Whether or not we want the original Sign Template image as background in our final PDF",
  })
  withSource?: boolean;
}

export class CollectionApproval {
  /**
   * Name of the user that approved a collection
   */
  name: string;

  /**
   * The timestamp that they approved it at
   */
  approvedAt: string;
}
