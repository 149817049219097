import { Field, InputType, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

@InputType()
export class S3ParamsInput {
  @Field(() => String, { nullable: true })
  bucket: string;
  @Field(() => String, { nullable: true })
  key: string;
  @Field(() => String, { nullable: true })
  contentType: string;

  @Field(() => String)
  body?: any;
  @Field(() => String, { nullable: true })
  contentEncoding?: string;
  @Field(() => String, { nullable: true })
  contentDisposition?: string;
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class S3ObjectBase extends EntityBase<S3ObjectBase> {
  /**
   * The key of the object
   */
  id: string;

  bucket: string;

  contents: string;
}

@ObjectType()
export class S3Object extends S3ObjectBase implements Entity<S3ObjectBase> {
  toObject() {
    return null;
  }
}
