import { EmailImageBlock, EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

/**
 * If we are sending package sent order, replace billing info with
 * shipping address and remove contact info
 */
const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailTextBlock("Pickup Instructions", {
      font: { size: 16, weight: 800 },
    }),
    new EmailTextBlock(
      `<p>Default pickup instructions</p>
      `,
      { font: { size: 12 } }
    ),
  ],
  [new EmailImageBlock("/assets/images/email/logo-Wilco.png")],
];

export class EmailWilcoInstructions extends Structure {
  readonly icon = "assignment";
  readonly name = "instructions";
  readonly options;

  constructor(options?: IEmailStructureOptions) {
    super("cols_12", ELEMENTS, options);
  }
}
