import { Field, InputType, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { EntityBase } from "../../../../interfaces/entity.interface";
import { KioskProduct } from "./kiosk-product.graphql";

/** @ignore */
@InputType()
export class ProductKioskSearchInput {
  /**
   * The search phrase entered by the user. This is optional.
   */
  @Field({ nullable: true })
  searchTerm: string;

  /**
   * TODO: Description of what the `categories` property does and accepts...
   */
  @Field({ nullable: true })
  categoryId: string;

  @Field({ nullable: true })
  storeId?: number;
}

// /** @ignore */
// @ObjectType()
// export class ProductKioskSearchResult {
//   /**
//    * Relevancy score of this Product based on the search criteria
//    */

//   /**
//    * {@link Product} that matched the search criteria
//    */
//   @Field((type) => KioskProduct, { nullable: true })
//   product: KioskProduct;
// }

/** @ignore */
@ObjectType()
export class ProductKioskSearchResults extends EntityBase<ProductKioskSearchResults> {
  /**
   * The product results from the search
   */
  @Field((type) => [KioskProduct])
  products: KioskProduct[];

  /**
   * The featured product results from the search
   */
  @Field((type) => [KioskProduct])
  featuredProducts: KioskProduct[];

  @Field((type) => GraphQLJSON)
  filters: ProductKioskFilter[];

  /**
   * Total number of products matched by this search
   */
  @Field()
  total: number;
  /**
   * Current page the query has requested and total pages that can still be fetched with
   * same query
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  page?: { current: number; total: number };
}

interface ProductKioskFilter {
  name: string;
  data: {
    name: string;
    count: number;
  };
}
