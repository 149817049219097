import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export type TEmailSpacerBlockOptions = Pick<
  IEmailBlockOptions,
  "height" | "width"
>;

export class EmailSpacerBlock implements EmailBlock {
  readonly type = "spacer";
  readonly icon = "vertical_align_center";

  options: TEmailSpacerBlockOptions = {
    height: {
      value: 20,
      unit: "px",
      units: ["px"],
    },
  };

  constructor(
    options?: TEmailSpacerBlockOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
