import { Field, ObjectType, registerEnumType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";

export enum ResponseStatus {
  SUCCESS = "success",
  ERROR = "error",
}

registerEnumType(ResponseStatus, {
  name: "ResponseStatus",
  description: "Domain Response statuses",
});

@ObjectType()
export class DomainResponse<T = any> {
  @Field()
  status: ResponseStatus;
  @Field(() => GraphQLJSON, { nullable: true })
  result?: T;
  @Field()
  message: string;

  constructor(props: Partial<DomainResponse>) {
    Object.assign(this, props);
  }

  get isSuccess() {
    return this.status === ResponseStatus.SUCCESS;
  }

  get isError() {
    return this.status === ResponseStatus.ERROR;
  }
}
