import { Field, InputType, Int } from "@nestjs/graphql";

export class PaginatedResponseClass<T> {
  results: T[];
  total: number;
}

@InputType()
export class PaginatedSearchParams {
  @Field(() => Int)
  pageNumber: number;
  @Field(() => Int)
  size: number;
  @Field({ nullable: true })
  search?: string;
}
