import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";
import { IEmailUserTemplate } from "./vo/email.interface";

@ObjectType({ isAbstract: true })
export class EmailTemplateBase extends EntityBase<EmailTemplateBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  @Field(() => String, { nullable: true })
  name: string;

  @Field(() => String, { nullable: true })
  subject: string;

  @Field(() => GraphQLJSON, { nullable: true })
  contentObject: IEmailUserTemplate;
}

@ObjectType()
export class EmailTemplate
  extends EmailTemplateBase
  implements Entity<EmailTemplateBase>
{
  toObject(): EmailTemplateBase {
    return null;
  }
}

@InputType()
export class EmailTemplateInput extends OmitType(
  EmailTemplate,
  [] as const,
  InputType
) {}
