import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
  IEmailSocialNetwork,
} from "../email.interface";

export type TEmailSocialBlockOptions = Pick<
  IEmailBlockOptions,
  | "align"
  | "mode"
  | "font"
  | "iconSize"
  | "lineHeight"
  | "color"
  | "innerPadding"
  | "padding"
>;

export class EmailSocialBlock implements EmailBlock {
  readonly type = "social";
  readonly icon = "share";

  options: TEmailSocialBlockOptions = {
    align: "center",
    mode: "horizontal",
    font: {
      fallback: "Arial, Helvetica, sans-serif",
      family: "Montserrat",
      style: "normal",
      size: 16,
      weight: 400,
    },
    iconSize: {
      value: 30,
      unit: "px",
    },
    lineHeight: {
      value: 16,
      unit: "px",
    },
    color: "#333333",
    innerPadding: {
      top: 4,
      right: 4,
      bottom: 4,
      left: 4,
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25,
    },
  };

  // https://mjml.io/documentation/#mjml-social
  constructor(
    public networks: IEmailSocialNetwork[] = [],
    options?: TEmailSocialBlockOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
