import { Field, ID, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";
import { Category } from "../category/category.model";
import { Item } from "../item/item.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class FinelineBase extends EntityBase<FinelineBase> {
  /**
   * The six digit Fineline code. This comes from Epicor.
   *
   * @readonly
   */
  @Field((type) => ID, { nullable: true })
  id: string;

  /**
   * The name of the Fineline. This comes from Epicor.
   *
   * @readonly
   */
  @Field({ nullable: true })
  name: string;

  /**
   * Option flag that determines whether {@link Item}s belonging to this Fineline are available for delivery.
   */
  @Field(() => Int, { nullable: true })
  deliveryAvailable: number;

  /**
   * Option flag that determines whether {@link Item}s belonging to this Fineline can be shown on the in-store Kiosk.
   */
  @Field({ nullable: true })
  showOnKiosk: boolean;

  /**
   * Option flag that determines whether {@link Item}s belonging to this Fineline require a fraud check when purchased on Ecom.
   */
  @Field({ nullable: true })
  requiresFraudCheck: boolean;

  /**
   * An array of {@link Category} that this Fineline is associated to
   */
  @Field(() => [Category], { nullable: true })
  categories?: Category[];

  /**
   * An array of {@link Item} that this Fineline is associated to
   */
  @Field(() => [Item], { nullable: "itemsAndList" })
  items?: Item[];

  /**
   * An optional association with an Avatax Tax Code.
   *
   * @remarks
   * When syncing a {@link Product} to Ecom, the Products `taxCode` takes priority if set, otherwise we use the Fineline `taxCode`.
   * If none is set, a `null` value is acceptable and implies Avatax will use its default tax handling.
   */
  @Field({ nullable: true })
  taxCode: string;
}

/**
 * A Fineline is a code that any number of {@link Item}s are associated with, forming a collection of related items (like a category).
 *
 * An Fineline is immutable (for the most part)and ingested into the application from Epicor as part of {@link Item Master Data}.
 */
@ObjectType()
export class Fineline extends FinelineBase implements Entity<FinelineBase> {
  toObject() {
    return {
      id: this.id,
      name: this.name,
      deliveryAvailable: this.deliveryAvailable,
      showOnKiosk: this.showOnKiosk,
      requiresFraudCheck: this.requiresFraudCheck,
      taxCode: this.taxCode,
    };
  }
}
