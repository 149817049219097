export interface SaleDate {
  date: string;
  totalSold: number;
}

export interface BlockMetric {
  blockId: number;
  saleDates: SaleDate[];
}

export interface ICalculateMetrics {
  blockIds: number[];
  saleDateMetrics: BlockMetric[];
}
