import { Field, InputType, OmitType } from "@nestjs/graphql";
import { DisplaySceneInput } from "../../display-scene/graphql/display-scene.input";
import { Display } from "../display.model";

@InputType()
export class DisplayParams {
  @Field({ nullable: true })
  name?: string;
}

@InputType()
export class DisplayInput extends OmitType(
  Display,
  ["scenes"] as const,
  InputType
) {
  @Field(() => [DisplaySceneInput])
  scenes?: DisplaySceneInput[];
}
