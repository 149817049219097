import { defaultsDeep } from "lodash";
import { ItemTotals } from "../../../../../domain/orders";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export interface IProductDesc {
  img: string;
  product: { value: string; storeName?: string; pickupTime?: string }; // ItemDescription in productsMeta
}
export type TEmailOrderBlockDynamicOptions = {
  columns: string[];
  rows: IProductDesc[];
  totals: ItemTotals;
  paymentMethod: string;
  accountNumber: string;
  coupon?: number;
};

export type TEmailOrderBlockOptions = Pick<IEmailBlockOptions, null>;

export class EmailOrderTableBlock implements EmailBlock {
  readonly type = "order-table";
  readonly icon = "table_chart";
  mode: "total" | "refund" | "brief";
  public options: TEmailOrderBlockOptions = {};
  public dynamicOptions: TEmailOrderBlockDynamicOptions;

  constructor(
    mode,
    options?: TEmailOrderBlockOptions,
    dynamicOptions?: TEmailOrderBlockDynamicOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
    this.dynamicOptions = defaultsDeep(dynamicOptions, this.dynamicOptions);
    this.mode = mode;
  }
}
