import { Field, HideField, Int, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase, Image } from "../../../interfaces";
import { Fineline } from "../fineline/fineline.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class CategoryBase extends EntityBase<CategoryBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the Category.
   *
   * This is public facing and will be shown on Ecom.
   */
  @Field({ nullable: true })
  name: string;

  /**
   * The ID of the Category on Ecom.
   * It is used for interacting (updating/deleting) with it on Ecom.
   */
  @Field((type) => Int, { nullable: true })
  ecomId: number;

  /**
   * Whether this Category is featured on the Ecom homepage.
   */
  @Field({ nullable: true })
  isFeatured: boolean;

  /**
   * Starting date to start feature this Category.
   */
  @Field({ nullable: true })
  featuredStartDate: string;

  /**
   * Ending date to stop featuring this Category.
   */
  @Field({ nullable: true })
  featuredEndDate: string;

  /**
   * Associates this category with a Google Taxonomy, this is used in the feeds generated for Google Ads.
   *
   * This data is maintaned by Ox Media who work with Wilco directly.
   */
  @Field({ nullable: true })
  googleTaxonomy: string;

  /**
   * An optional Image to represent this Category, it's used on the Ecom homepage if the Category is featured.
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  image: Image;

  @HideField()
  parent?: any;
  @HideField()
  children?: any[];

  /**
   * Any number of {@link Fineline} can be associated with a Category.
   */
  @Field(() => [Fineline], { nullable: "itemsAndList" })
  finelines?: Fineline[];

  /** @internal */
  @Field(() => Int, { nullable: true })
  _parentId: number;
}

/**
 * A Category is a collection of related {@link Product}, and is used throughout Ecom and Kiosk.
 *
 * A Category also has one or more {@link Fineline} associated with it, which is how Product's are categorized within Wilco's Epicor system.
 */
@ObjectType("Category", {})
export class Category extends CategoryBase implements Entity<CategoryBase> {
  /**
   * The parent Category, if this is not a top-level category.
   */
  @Field((type) => Category, { nullable: true })
  parent?: Category;

  /**
   * The children of this Category.
   */
  @Field((type) => [Category], { nullable: true })
  children?: Category[];

  constructor(props) {
    super(props);

    this.parent = props.parent ? new Category(props.parent) : null;
    this.children = props.children
      ? props.children.map((child) => new Category(child))
      : [];
  }

  toObject(): CategoryBase {
    return {
      id: this.id,
      name: this.name,
      ecomId: this.ecomId,
      isFeatured: this.isFeatured,
      featuredStartDate: this.featuredStartDate,
      featuredEndDate: this.featuredEndDate,
      googleTaxonomy: this.googleTaxonomy,
      image: this.image,
      parent: this.parent,
      children: this.children,
      finelines: this.finelines,
      _parentId: this._parentId,
    };
  }
}
