/**
 *
 * These are all of the different error types that need to be handled in the ad-tool.
 * The order of this list matters as the index of the types are used to determine
 * priority of which one to display in the case where there are multiple errors.
 *
 */
export const adErrorTypes = [
  "default",
  "incomplete",
  "noSignage",
  "noProducts",
  "timedOut",
  "pricing",
  "inventory",
  "iMAP",
  "profitLoss",
] as const;

export type AdErrorType = typeof adErrorTypes[number];
