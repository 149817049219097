import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";
import { SignLabel, SignLabelInput } from "../signs";

/**
 * This is a duplicate model right now. Not sure how we should handle
 * current AdSignage migration, if at all...
 */
@ObjectType({ isAbstract: true })
export class AdSignageBase extends EntityBase<AdSignageBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Associated assortment
   */
  @Field((type) => Int, { nullable: true })
  bundleId: number;

  /**
   * Associated sign template
   */
  @Field((type) => Int, { nullable: true })
  templateId: number;

  /**
   * Quantity of sign labels we want
   */
  @Field((type) => Int, { nullable: true })
  quantity: number;

  /**
   * Signage type - assortment/adItem/item/etc
   */
  @Field({ nullable: true })
  signageType: string;

  /**
   * All Sign Labels that we have associated with this particular entity.
   *
   * This association will be made in the UI of ad-tool. The user to add
   * a new signage, specify the quantity and signageType, and then will
   * be launched into a modal.
   *
   * From the modal we will fetch the Sign Template with the template id
   * and create a Sign Label for the assortment or a Sign Label for each
   * of the ad-items depending on the signage type
   *
   * The user will then be able to edit the Sign Label and save when completed
   */
  @Field((type) => [SignLabel], { nullable: "itemsAndList" })
  signLabels?: SignLabel[];

  constructor(props) {
    super(props);

    this.signLabels = props.signLabels
      ? props.signLabels.map((label) => new SignLabel(label))
      : [];
  }
}

@ObjectType("AdSignage", {})
export class AdSignage extends AdSignageBase implements Entity<AdSignageBase> {
  toObject() {
    return null;
  }
}

@InputType()
export class AdSignageInput extends OmitType(
  AdSignage,
  ["signLabels"] as const,
  InputType
) {
  @Field((type) => [SignLabelInput])
  signLabels?: [SignLabelInput];
}
