export interface RecapData {
  promoId: number;
  promotionName: string;
  /**
   * Gross revenue we made from this item
   */
  revenue: number;
  /**
   * Total number of units sold
   */
  unitsSold: number;
  /**
   * % margin we made considering revenue and totalCost
   */
  margin: number;
  /**
   * Total replacement/saleCost
   */
  totalCost: number;
  /**
   * Number of units sold in store
   */
  inStoreSales: number;
  /**
   * Number of units sold online
   */
  onlineSales: number;
  /**
   * Average quantity for each order
   */
  avgQuantity: number;
  /**
   * Number of units sold daily during the promotion
   */
  dailyUnitsSold?: number;
  /**
   * Number of units sold daily during the year
   */
  dailyAnnualUnitsSold?: number;
  /**
   * Number of units sold daily during the last 60 days
   */
  dailySixtyUnitsSold?: number;
}
