import { Field, InputType, Int, OmitType } from "@nestjs/graphql";
import { ItemColorBase } from "../item-color.model";

/**
 * Input of an Item Color
 *
 * @category GQL Input
 */
@InputType()
export class ItemColorInput extends OmitType(
  ItemColorBase,
  [] as const,
  InputType
) {}

@InputType()
export class SearchParams {
  @Field((type) => String, { nullable: true })
  search?: string[];
  @Field((type) => [Int], { nullable: "itemsAndList" })
  withIds?: number[];
  @Field((type) => Int, { nullable: true })
  limit?: number;
  @Field((type) => Int, { nullable: true })
  offset?: number;
}
