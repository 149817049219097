import { Field, ID, Int, ObjectType } from "@nestjs/graphql";
import * as dayjs from "dayjs";
import { GraphQLJSON } from "graphql-type-json";

import { Entity, EntityBase, Image } from "../../../interfaces";
import { QuantityBreak } from "../../promotions/quantity-break/quantity-break.model";
import { Fineline } from "../fineline/fineline.model";
import { ItemColor } from "../item-color/item-color.model";
import { ItemSize } from "../item-size/item-size.model";
import { Product } from "../product/product.model";
import { ItemLocationData } from "./vo/item-location-data.model";
import { ItemLocationSecondaryData } from "./vo/item-location-secondary-data.model";
import { ItemMasterData } from "./vo/item-master-data.model";
import { ItemSalesVelocityData } from "./vo/item-sales-velocity-data.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ItemBase extends EntityBase<ItemBase> {
  /**
   * The ID of an Item will be it's SKU.
   *
   * A SKU is a `string` comprising of numbers, letters and special characters. All SKUs are fed into Back40 from Epicor via the Ingest service.
   *
   * @readonly
   */
  @Field((type) => ID, { description: "The ID of an Item will be it's SKU" })
  readonly id: string;

  /**
   * The ID of the {@link Product} that this Item belongs to.
   *
   * Will be `null` if this Item does not belong to a Product.
   */
  @Field((type) => Int, { nullable: true })
  productId?: number;

  /**
   * A flag to determine whether the Item can be published to Ecom.
   */
  @Field({ nullable: true })
  publish: boolean;

  @Field(() => GraphQLJSON, { nullable: true })
  image: Image;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Master Data received from Epicor",
  })
  masterData?: ItemMasterData;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Data received from Epicor",
  })
  locationData?: ItemLocationData;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Secondary Data received from Epicor",
  })
  locationSecondaryData?: ItemLocationSecondaryData;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Sales Velocity Data received from Epicor",
  })
  salesVelocity?: ItemSalesVelocityData;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "PIM Data if available",
  })
  productsPim?: Object;

  /**
   * The A3 code from the most recent ingest, this gets updated in a post-ingest service
   */
  @Field({ nullable: true })
  lastCodeA3?: string;

  /**
   * Don't use this field directly unless you have a specific use case for it.
   * Use `isActive` as it aggregates our "dropped from feed" logic.
   * @internal
   */
  @Field({ nullable: true })
  droppedFromMaster?: boolean;

  /**
   * Don't use this field directly unless you have a specific use case for it.
   * Use `isActive` as it aggregates our "dropped from feed" logic.
   * @internal
   */
  @Field({ nullable: true })
  droppedFromLocation?: boolean;

  /**
   * Don't use this field directly unless you have a specific use case for it.
   * Use `isActive` as it aggregates our "dropped from feed" logic.
   * @internal
   */
  @Field({ nullable: true })
  droppedFromLocationSecondary?: boolean;

  @Field(() => GraphQLJSON, { nullable: true })
  upcs?: string[];

  @Field(() => Int, { nullable: true })
  _colorId: number;

  @Field(() => Int, { nullable: true })
  _groupId?: number;

  @Field(() => Int, { nullable: true })
  _sizeId?: number;

  @Field({ nullable: true })
  _fineline?: string;

  /**
   * The {@link Product} that this Item belongs to.
   *
   * This could be `null` if the Item is not associated with a Product.
   */
  @Field(() => [Product], { nullable: "itemsAndList" })
  product?: Product;

  /**
   * Every SKU belongs to a Fineline, this association is fed into Back40 from Epicor.
   */
  @Field((type) => Fineline, { nullable: true })
  fineline?: Fineline;

  @Field((type) => ItemSize, { nullable: true })
  size?: ItemSize;

  @Field((type) => QuantityBreak, { nullable: true })
  quantityBreak?: QuantityBreak;
  /**
   * Color is one of two attributes (along with Size) that differentiates Items belonging to a Product.
   */
  @Field(() => ItemColor, { nullable: true })
  color?: ItemColor;

  constructor(props: ItemBase) {
    super(props);

    if (props?.quantityBreak)
      this.quantityBreak = new QuantityBreak(props.quantityBreak);
  }
}

@ObjectType("Item", {})
export class Item extends ItemBase implements Entity<ItemBase> {
  /**
   * Will return `true` if this Item has been dropped from the Epicor ingest feed.
   * This means the SKU is no longer "active" and in use by Wilco, and therefore should not be used by the application.
   *
   * @remarks
   * The main effect of this being `false` is that it invalidates the Item from being able to be published to Ecom.
   *
   * @returns `true` if this SKU is action, or `false` if no longer an active SKU (i.e. dropped from Epicor feed)
   *
   */
  get isActive(): boolean {
    return !(
      this.droppedFromMaster ||
      this.droppedFromLocation ||
      this.droppedFromLocationSecondary
    );
  }

  /**
   * If this Item is flagged as discontinued, this will return `true`.
   *
   * And Item may be discontinued (`true`), but still have stock - in this case the Item is a "cleearance item" until sold out.
   * Once it no longer has any inventory, it's no longer an active Item.
   */
  get isDiscontinued(): boolean {
    if (!this.masterData) return false;

    return this.masterData.discontinued === "Y";
  }

  /**
   * Get a list of excluded states for this Item, based on the applied business logic.
   *
   * TODO - complete the biz logic
   */
  get excludedStates(): string[] {
    const excludedStates = new Set<string>();

    if (this.masterData["Code A3"] === "C") excludedStates.add("CA");

    return [...excludedStates];
  }

  /**
   * Total of the Quantity Available for all stores.
   *
   * @remarks
   * This does not exclude negative quantities.
   */
  get totalQuantityAvailable(): number {
    return this.locationData
      ? Object.keys(this.locationData)
          .filter(
            (key) =>
              key.includes("quantityAvailable") &&
              this.locationData[key] !== null
          )
          .reduce((total, key) => total + this.locationData[key], 0)
      : 0;
  }

  /**
   * Total of the Quantity On Order for all stores.
   */
  get totalQuantityOnOrder(): number {
    return this.locationSecondaryData
      ? Object.keys(this.locationSecondaryData)
          .filter(
            (key) =>
              key.includes("quantityOnOrder") &&
              this.locationSecondaryData[key] !== null
          )
          .reduce((total, key) => total + this.locationSecondaryData[key], 0)
      : 0;
  }

  /**
   * Projected sales has various uses through the application.
   * It is used to determine a projected sales volume for an Item, based on the current sales velocity data.
   *
   * As sales velocity data is a trailing-twelve-month, we are actually calculating this on historical data.
   * Because of this, new Items will always have a 0 sales velocity for 12 months.
   */
  get projectedSales(): number {
    if (!this.salesVelocity) return 0;

    const thisMonth = dayjs().format("MMM").toLowerCase();
    const nextMonth = dayjs().add(1, "month").format("MMM").toLowerCase();

    return (this.salesVelocity[thisMonth] + this.salesVelocity[nextMonth]) / 2;
  }

  toObject(): ItemBase {
    // would only return ItemBase properties
    return null;
  }
}
