import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import * as currency from "currency.js";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
import { AdMedia, AdMediaInput } from "./";
@ObjectType()
export class AdArchive {
  @Field((type) => [AdPromotion], { nullable: "itemsAndList" })
  promotions: AdPromotion[];
  @Field((type) => [AdMedia], { nullable: "itemsAndList" })
  media: AdMedia[];
}

@ObjectType({ isAbstract: true })
export class AdPromotionBase extends EntityBase<AdPromotionBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field({ nullable: true })
  promotionName: string;
  @Field({ nullable: true })
  startDate: string;
  @Field({ nullable: true })
  endDate: string;

  @Field((type) => [AdMedia], { nullable: "itemsAndList" })
  media?: AdMedia[];

  @Field({ nullable: true })
  recapStatus: string;

  @Field({ nullable: true })
  inStorePromo: boolean;

  @Field({ nullable: true })
  archived: boolean;

  @Field({ nullable: true })
  isProcessing: boolean;

  constructor(props: AdPromotionBase) {
    super(props);
    this.media = props.media
      ? props.media.map((_media) => new AdMedia(_media))
      : null;
  }
}

@ObjectType("AdPromotion", {})
export class AdPromotion
  extends AdPromotionBase
  implements Entity<AdPromotionBase>
{
  @Field(() => GraphQLJSON, { nullable: true })
  get projections() {
    return this.media.reduce(
      ({ unitsSold, sales, totalCost }, media) => {
        const projections = media.projections;

        unitsSold = currency(unitsSold).add(projections.unitsSold ?? 0).value;
        sales = currency(sales).add(projections.sales ?? 0).value;
        totalCost = currency(totalCost).add(projections.totalCost ?? 0).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }
  toObject() {
    return null;
  }
}

@InputType()
export class AdPromotionInput extends OmitType(
  AdPromotion,
  ["media"] as const,
  InputType
) {
  @Field(() => [AdMediaInput])
  media?: [AdMediaInput];
}
