import {
  Field,
  Float,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";
import { Package } from "../package/package.model";

/**
 * Context for my thought process here.
 *
 * A domain package can contain multiple order-items that may or may
 * not need to be shipped in multiple shipments. Each package has
 * one ShipmentCharge, but that "shipment" can generate multiple
 * real packages (not domain packages) that should probably be tracked.
 *
 * Currently these Shipments are being used to track UPS packages, but
 * can be used for Wilco Delivery in the future.
 */
export interface ShipmentMeta {
  shipments: {
    trackingNumber: string; // Tracking Number for package
    label: string; // Host label on S3
  }[];
  totalCharge: number;
  // Tracking numbers added outside of our normal workflow
  additionalTrackingNumbers?: string[];
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class ShippingChargeBase extends EntityBase<ShippingChargeBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  @Field(() => Int, { nullable: true })
  _packageId: Package["id"];

  /**
   * To process refunds through an external source (such as Ecom),
   * we need to know the ID of the entity at the external source.
   *
   * For Ecom, this would be the `WC_Order_Item` ID.
   */
  @Field(() => String, { nullable: true })
  externalId: string;

  /**
   * The method of shipment - which as it stands is either going to be through UPS or Wilco Local Delivery
   */
  @Field(() => String, { nullable: true })
  method: "UPS" | "Wilco Delivery";

  /**
   * The type of shipping service.
   *
   * For Wilco Local Delivery this will be `null`.
   *
   * For UPS this will be Ground, 2nd Day Air, or Next Day Air.
   */
  @Field(() => String, { nullable: true })
  service: string;

  @Field(() => GraphQLJSON, { nullable: true })
  shipmentMeta?: ShipmentMeta;

  /**
   * Tax included in the Shipping Charge
   */
  @Field(() => Float, { nullable: true })
  tax: number;

  /**
   * Total paid by the Customer
   *
   * @remarks EXCLUSIVE of tax
   */
  @Field(() => Float, { nullable: true })
  total: number;

  /**
   * Tax that was refunded
   */
  @Field(() => Float, { nullable: true })
  refundTax: number;

  /**
   * Net Total that was refunded
   *
   * Inclusive of tax.
   */
  @Field(() => Float, { nullable: true })
  refundTotal: number;

  /**
   * The actual cost to Wilco for this Shipment.
   *
   * With UPS, we update this when the label is generated and the cost is returned from UPS.
   *
   * With Deliver, TODO ?
   */
  @Field(() => Float, { nullable: true })
  actualCost: number;
}

/**
 * A ShippingCharge is a charge associated with the fulfillment of a {@link Package}.
 *
 * It will either be a Ship To Home {@link Package} that has a UPS shipment charge. Or a Delivery {@link Package} that has a local delivery charge.
 *
 * We also record the `actualCost` of a shipment for reporting purposes.
 */
@ObjectType()
export class ShippingCharge
  extends ShippingChargeBase
  implements Entity<ShippingChargeBase>
{
  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }

  @Field(() => Float, { nullable: true })
  get netTotal() {
    return currency(this.total).add(this.tax || 0);
  }
}

@InputType()
export class ShippingChargeInput extends OmitType(
  ShippingCharge,
  [] as const,
  InputType
) {}
