/**
 * @packageDocumentation
 * @module Signages
 */

import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
import { IBaseDimensions, ICanvasOptions, TokenValue } from "./signs.interface";

@InputType()
export class SignTemplateParams {
  @Field({ nullable: true })
  name?: string;
  @Field({ nullable: true })
  source?: string;
  @Field((type) => [Int], { nullable: true })
  ids?: number[];
}

@ObjectType({ isAbstract: true })
export class SignTemplateBase extends EntityBase<SignTemplateBase> {
  /**
   * Unique id for sign template
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Name of the template
   */
  @Field({ nullable: true })
  name: string;

  /**
   * Hosted S3 address (pdf converted to an image)
   */
  @Field({ nullable: true })
  source: string;

  /**
   * DataUrl of S3 image
   */
  @Field({ nullable: true })
  dataUrl?: string;

  /**
   * Value used to determine whether or not we should refresh
   * the canvas. This is not stored in the DB.
   */
  @Field(() => Date, { nullable: true })
  fetched?: number;

  /**
   * Number of ups that are expected to be projected for the
   * pdf
   */
  @Field({ nullable: true })
  numberOfUps: number;

  /**
   * Height of original pdf in pixels
   */
  @Field({ nullable: true })
  sourceHeight: number;

  /**
   * Width of original pdf in pixels
   */
  @Field({ nullable: true })
  sourceWidth: number;

  /**
   * Position the template should be presented in
   */
  @Field({ nullable: true })
  sortIndex: number;

  /**
   * If true, template should be rendered as landscape rather than portrait
   */
  @Field({ nullable: true })
  isLandscape: boolean;

  /**
   * If true, template should not be able to be edited
   */
  @Field({ nullable: true })
  isLocked: boolean;

  /**
   * Bounding box drawn by the user. This is drawn over the source pdf/image
   * and is used to determine the placement of the tokens
   */
  @Field(() => GraphQLJSON, { nullable: true })
  boundingBox: IBaseDimensions;

  /**
   * Optional parameters for modifying the behavior of the canvas rendering
   * our template
   */
  @Field(() => GraphQLJSON, { nullable: true })
  canvasOptions: ICanvasOptions;

  /**
   * Tokens that belong to this signage template. A token can be any fabric element
   * (e.g. text, image, rect, circle, etc). A token is just a template though. It only
   * contains positioning data and other characteristcs defined in its attributes property.
   *
   * Once the canvas as been initialized, it will add any tokens it has to the canvas.
   */
  @Field(() => GraphQLJSON, { nullable: true })
  tokens: TokenValue[];

  constructor(props) {
    super(props);

    this.tokens = props.tokens
      ? props.tokens.map((token) => new TokenValue(token))
      : [];
  }
}

@ObjectType()
export class SignTemplate
  extends SignTemplateBase
  implements Entity<SignTemplateBase>
{
  toObject() {
    return null;
  }
}

@InputType()
export class SignTemplateInput extends OmitType(
  SignTemplate,
  [] as const,
  InputType
) {}
