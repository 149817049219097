/**
 * Location Data is immutable information about an {@link Item} that is fed to the application from Epicor.
 *
 * It consists of three store-specific properties: Retail Price, Promotion Price and Quantity Available.
 * The number postfixed to each property is the {@link Store} Number (aka the ID).
 *
 * **We use Store 90 for all Ecom pricing**
 *
 * Retail Price may be null, in which case that Item is **not carried** in that store (i.e. unsellable at that store).
 *
 * Promotion Price may be null, in which case that Item is **not on sale** and the Retail Price should be used.
 *
 * Quantity Available may be a positive number (in stock), a negative number (oversold, out of stock) or null (not carried - i.e. unsellable).
 *
 * It feeds in via the Ingest task every 15 minutes.
 *
 * @example `retailPrice1` is the Retail Price for McMinnville
 *
 * @example `promotionPrice23` is the Promotion Price for Petaluma
 *
 * @readonly
 *
 * @category Value Object
 */
export interface ItemLocationData {
  retailPrice1: number;
  retailPrice2: number;
  retailPrice3: number;
  retailPrice4: number;
  retailPrice5: number;
  retailPrice6: number;
  retailPrice7: number;
  retailPrice8: number;
  retailPrice9: number;
  retailPrice10: number;
  retailPrice11: number;
  retailPrice12: number;
  retailPrice13: number;
  retailPrice14: number;
  retailPrice15: number;
  retailPrice16: number;
  retailPrice17: number;
  retailPrice18: number;
  retailPrice19: number;
  retailPrice20: number;
  retailPrice21: number;
  retailPrice22: number;
  retailPrice23: number;
  retailPrice24: number;
  retailPrice90: number;
  retailPrice99: number;

  promotionPrice1: number;
  promotionPrice2: number;
  promotionPrice3: number;
  promotionPrice4: number;
  promotionPrice5: number;
  promotionPrice6: number;
  promotionPrice7: number;
  promotionPrice8: number;
  promotionPrice9: number;
  promotionPrice10: number;
  promotionPrice11: number;
  promotionPrice12: number;
  promotionPrice13: number;
  promotionPrice14: number;
  promotionPrice15: number;
  promotionPrice16: number;
  promotionPrice17: number;
  promotionPrice18: number;
  promotionPrice19: number;
  promotionPrice20: number;
  promotionPrice21: number;
  promotionPrice22: number;
  promotionPrice23: number;
  promotionPrice24: number;
  promotionPrice90: number;
  promotionPrice99: number;

  quantityAvailable1: number;
  quantityAvailable2: number;
  quantityAvailable3: number;
  quantityAvailable4: number;
  quantityAvailable5: number;
  quantityAvailable6: number;
  quantityAvailable7: number;
  quantityAvailable8: number;
  quantityAvailable9: number;
  quantityAvailable10: number;
  quantityAvailable11: number;
  quantityAvailable12: number;
  quantityAvailable13: number;
  quantityAvailable14: number;
  quantityAvailable15: number;
  quantityAvailable16: number;
  quantityAvailable17: number;
  quantityAvailable18: number;
  quantityAvailable19: number;
  quantityAvailable20: number;
  quantityAvailable21: number;
  quantityAvailable22: number;
  quantityAvailable23: number;
  quantityAvailable24: number;
  quantityAvailable90: number;
  quantityAvailable99: number;
}
