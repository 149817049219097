import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";
import { EcomUser } from "../../ecom";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ProfileBase extends EntityBase<ProfileBase> {
  /**
   * The ID is of the profile on Ecom
   */
  @Field(() => Int, { nullable: true })
  id: number;

  /**
   * This is the ID of the Customer account
   */
  @Field({ nullable: true })
  customerNumber: string;
  @Field({ nullable: true })
  email: string;

  @Field({ nullable: true })
  name: string;

  @Field({ nullable: true })
  dateLinked: string;

  @Field(() => EcomUser, { nullable: true })
  ecomUser?: EcomUser;
}

/**
 * A Profile is a user account on Ecom
 */
@ObjectType()
export class Profile extends ProfileBase implements Entity<ProfileBase> {
  toObject() {
    return null;
  }
}
