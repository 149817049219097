import { InputType, OmitType } from "@nestjs/graphql";
import { ProfileInput } from "../../profile/graphql/profile.input";
import { Customer } from "../customer.model";

@InputType()
export class CustomerInput extends OmitType(
  Customer,
  ["specialtyDiscounts", "profiles"] as const,
  InputType
) {
  profiles: ProfileInput[];
}
