/**
 * Location Secondary Data is immutable information about an {@link Item} that is fed to the application from Epicor.
 *
 * It feeds in via the Ingest task every 1 day.
 *
 * @readonly
 *
 * @category Value Object
 */
export interface ItemLocationSecondaryData {
  location1: string;
  location2: string;
  location3: string;
  location4: string;
  location5: string;
  location6: string;
  location7: string;
  location8: string;
  location9: string;
  location10: string;
  location11: string;
  location12: string;
  location13: string;
  location14: string;
  location15: string;
  location16: string;
  location17: string;
  location18: string;
  location19: string;
  location20: string;
  location21: string;
  location22: string;
  location23: string;
  location24: string;
  location90: string;
  location99: string;

  orderPoint1: number;
  orderPoint2: number;
  orderPoint3: number;
  orderPoint4: number;
  orderPoint5: number;
  orderPoint6: number;
  orderPoint7: number;
  orderPoint8: number;
  orderPoint9: number;
  orderPoint10: number;
  orderPoint11: number;
  orderPoint12: number;
  orderPoint13: number;
  orderPoint14: number;
  orderPoint15: number;
  orderPoint16: number;
  orderPoint17: number;
  orderPoint18: number;
  orderPoint19: number;
  orderPoint20: number;
  orderPoint21: number;
  orderPoint22: number;
  orderPoint23: number;
  orderPoint24: number;
  orderPoint90: number;
  orderPoint99: number;

  loadingRequired1: "Y" | "N" | "P" | null;
  loadingRequired2: "Y" | "N" | "P" | null;
  loadingRequired3: "Y" | "N" | "P" | null;
  loadingRequired4: "Y" | "N" | "P" | null;
  loadingRequired5: "Y" | "N" | "P" | null;
  loadingRequired6: "Y" | "N" | "P" | null;
  loadingRequired7: "Y" | "N" | "P" | null;
  loadingRequired8: "Y" | "N" | "P" | null;
  loadingRequired9: "Y" | "N" | "P" | null;
  loadingRequired10: "Y" | "N" | "P" | null;
  loadingRequired11: "Y" | "N" | "P" | null;
  loadingRequired12: "Y" | "N" | "P" | null;
  loadingRequired13: "Y" | "N" | "P" | null;
  loadingRequired14: "Y" | "N" | "P" | null;
  loadingRequired15: "Y" | "N" | "P" | null;
  loadingRequired16: "Y" | "N" | "P" | null;
  loadingRequired17: "Y" | "N" | "P" | null;
  loadingRequired18: "Y" | "N" | "P" | null;
  loadingRequired19: "Y" | "N" | "P" | null;
  loadingRequired20: "Y" | "N" | "P" | null;
  loadingRequired21: "Y" | "N" | "P" | null;
  loadingRequired22: "Y" | "N" | "P" | null;
  loadingRequired23: "Y" | "N" | "P" | null;
  loadingRequired24: "Y" | "N" | "P" | null;
  loadingRequired90: "Y" | "N" | "P" | null;
  loadingRequired99: "Y" | "N" | "P" | null;

  quantityOnOrder1: number;
  quantityOnOrder2: number;
  quantityOnOrder3: number;
  quantityOnOrder4: number;
  quantityOnOrder5: number;
  quantityOnOrder6: number;
  quantityOnOrder7: number;
  quantityOnOrder8: number;
  quantityOnOrder9: number;
  quantityOnOrder10: number;
  quantityOnOrder11: number;
  quantityOnOrder12: number;
  quantityOnOrder13: number;
  quantityOnOrder14: number;
  quantityOnOrder15: number;
  quantityOnOrder16: number;
  quantityOnOrder17: number;
  quantityOnOrder18: number;
  quantityOnOrder19: number;
  quantityOnOrder20: number;
  quantityOnOrder21: number;
  quantityOnOrder22: number;
  quantityOnOrder23: number;
  quantityOnOrder24: number;
  quantityOnOrder90: number;
  quantityOnOrder99: number;
}
