import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export type TEmailTextBlockOptions = Pick<
  IEmailBlockOptions,
  "align" | "color" | "font" | "lineHeight" | "padding"
>;

export class EmailTextBlock implements EmailBlock {
  readonly type = "text";
  readonly icon = "text_format";
  public options: TEmailTextBlockOptions = {
    color: "#302C2D",
    font: {
      fallback: "Arial, Helvetica, sans-serif",
      family: "Montserrat",
      style: "normal",
      size: 16,
      weight: 400,
    },
    lineHeight: {
      value: 40,
      unit: "none",
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25,
    },
  };

  constructor(
    public innerText?: string,
    options?: Partial<TEmailTextBlockOptions>,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
