/**
 * Master Data is immutable information about an {@link Item} that is fed to the application from Epicor.
 *
 * It feeds in via the Ingest task every hour.
 *
 * @readonly
 *
 * @category Value Object
 */
export interface ItemMasterData {
  iMap: number;
  codeA1: "Y" | "N" | "S";
  codeA2: 1 | 2;
  codeA3: "O" | "W" | "C" | "Z" | 1 | 2 | 3;
  codeB3: string;
  codeC3: string;
  itemDepth: number;
  itemWidth: number;
  itemHeight: number;
  weight: number;
  weightUnit: string;
  visibleOnline: "Yes" | "No";
  itemDescription: string;
  retailPrice: number;
  replacementCost: number;
  brand: string;
  currentClassCode: string;
  currentClassName: string;
  dateAdded: string;

  /** Use `isDiscontinued` accessor on the parent Item entity */
  discontinued: "Y" | "N";
  currentVendorCode: string;
  currentVendorName: string;
  quantityBreakCode: number;
  currentManufacturerCode: string;
  currentManufacturerName: string;
  currentManufacturerPartNumber: string;
  currentFinelineCode: string;
  currentFinelineName: string;
  currentDepartmentCode: string;
  currentDepartmentName: string;
  keepStockData: string;
  sequenceCode: string;
}
