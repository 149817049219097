import {
  Field,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
import { SignCollection, SignCollectionInput } from "../signs";
import { RegionInput } from "../stores";
import { AdDate, AdPage, AdStoreVersion, IncludedMedia } from "./";
import { AdDateInput } from "./ad-date.model";
import { AdPageInput } from "./ad-page.model";
import { AdPromotion, AdPromotionInput } from "./ad-promotion.model";

@ObjectType({ isAbstract: true })
export class AdMediaBase extends EntityBase<AdMediaBase> {
  @Field((type) => Int, { nullable: true })
  id: number;
  @Field((type) => Int, { nullable: true })
  promotionId: number;
  @Field((type) => Int, { nullable: true })
  linkedMediaId: number;

  @Field({ nullable: true })
  mediaName: string;
  @Field({ nullable: true })
  mediaType: string;
  @Field({ nullable: true })
  lockOverride: boolean;
  @Field({ nullable: true })
  designBrief: string;
  @Field({ nullable: true })
  mediaSource: string;
  @Field({ nullable: true })
  budget: number;
  @Field({ nullable: true })
  archived: boolean;

  @Field((type) => GraphQLJSON, { nullable: true })
  emailList: Array<string>;

  @Field((type) => GraphQLJSON, { nullable: true })
  versions: AdStoreVersion[];

  /**
   * The media that blocks can associate with
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  includedMedia: IncludedMedia[];

  /**
   * Source of the promotion (dropbox link)
   */
  @Field({ nullable: true })
  source: string;

  @Field((type) => [AdDate], { nullable: true })
  mediaDates?: AdDate[];

  @Field((type) => SignCollection, { nullable: true })
  collection?: SignCollection;

  @Field((type) => AdPromotion, { nullable: true })
  promotion?: AdPromotion;

  @HideField()
  linkedMedia?: any;

  @Field((type) => [AdPage], { nullable: "itemsAndList" })
  pages?: AdPage[];

  constructor(props: AdMediaBase) {
    super(props);
    this.pages = props.pages ? props.pages.map((p) => new AdPage(p)) : null;
    this.linkedMedia = props.linkedMedia
      ? new AdMedia(props.linkedMedia)
      : null;
  }
}

@ObjectType("AdMedia", {})
export class AdMedia extends AdMediaBase implements Entity<AdMediaBase> {
  /**
   * This field is manually associated by the user to setup a link between two
   * media. This linkedMedia is used for comparison reports in the Manager Report
   * but could be used for other comparisons as well to see how a media did
   * relative to another media.
   */
  @Field((type) => AdMedia, { nullable: true })
  linkedMedia?: AdMedia;

  @Field(() => GraphQLJSON, { nullable: true })
  get projections() {
    return this.pages.reduce(
      ({ unitsSold, sales, totalCost }, page) => {
        const projections = page.projections;

        unitsSold = currency(unitsSold).add(projections.unitsSold ?? 0).value;
        sales = currency(sales).add(projections.sales ?? 0).value;
        totalCost = currency(totalCost).add(projections.totalCost ?? 0).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }

  @Field(() => GraphQLJSON, { nullable: true })
  get recap() {
    return this.pages.reduce(
      ({ unitsSold, sales, totalCost }, page) => {
        const {
          unitsSold: _unitsSold,
          sales: _sales,
          totalCost: _totalCost,
        } = page.recap;

        unitsSold = currency(unitsSold).add(_unitsSold ?? 0).value;
        sales = currency(sales).add(_sales ?? 0).value;
        totalCost = currency(totalCost).add(_totalCost ?? 0).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }

  toObject() {
    return null;
  }
}

@InputType()
export class AdMediaInput extends OmitType(
  AdMedia,
  ["pages", "promotion", "collection", "mediaDates", "linkedMedia"] as const,
  InputType
) {
  @Field(() => [AdPageInput])
  pages?: [AdPageInput];
  @Field(() => [RegionInput])
  regions?: [RegionInput];
  @Field(() => [AdPromotionInput])
  promotion?: [AdPromotionInput];
  @Field(() => SignCollectionInput)
  collection?: SignCollectionInput;
  @Field(() => [AdDateInput])
  mediaDates?: [AdDateInput];
}
