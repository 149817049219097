import { Field, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";

export interface BestPicker {
  storeName: string;
  picker: string;
  total: number;
}

export interface FastestResponse {
  storeName: string;
  time: number;
}

@ObjectType()
export class FulfillmentMetrics {
  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Store with the fastest response time",
  })
  fastestResponse: FastestResponse;

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Picker with the most completed orders",
  })
  bestPicker: BestPicker;
}
