/**
 * @packageDocumentation
 * @module System
 */

import { Field, InputType, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";

@ObjectType()
export class Message {
  response?: string;
}

@InputType()
export class MessageInput {
  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Data received from Epicor",
  })
  event?: {
    exchange: string;
    routingKey: string;
    data: any;
  };

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Data received from Epicor",
  })
  command?: {
    exchange: string;
    routingKey: string;
    data: any;
  };

  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Data received from Epicor",
  })
  query?: {
    exchange: string;
    routingKey: string;
    data: any;
  };
}
