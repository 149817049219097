import { Field, InputType, OmitType } from "@nestjs/graphql";
import { ProductInput } from "../../../../../domain/products";
import { DisplaySceneColumn } from "../scene-column.model";

@InputType()
export class SceneColumnInput extends OmitType(
  DisplaySceneColumn,
  ["products"] as const,
  InputType
) {
  @Field(() => [ProductInput], { nullable: "itemsAndList" })
  products?: ProductInput[];
}
