import { Field, ObjectType } from "@nestjs/graphql";
import { DomainResponse, ResponseStatus } from "../../../interfaces";
import { AdMedia } from "../ad-media.model";
import { AdPromotion } from "../ad-promotion.model";

@ObjectType()
export class CreatePromotionResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => String)
  message: string;

  @Field(() => AdPromotion, { nullable: true })
  result?: AdPromotion;

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}

@ObjectType()
export class CreateMediaResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => String)
  message: string;

  @Field(() => AdMedia, { nullable: true })
  result?: AdMedia;

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}
