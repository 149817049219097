import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Package } from "../package.model";

@ObjectType()
export class PaginatedPackage {
  @Field((type) => [Package])
  results: Package[];

  @Field((type) => Int)
  total: number;
}
