import { TEmailBlocks } from "./blocks";
import {
  IEmailBackground,
  IEmailBorder,
  IEmailFont,
  IEmailLineHeight,
  IEmailMargin,
  IEmailPadding,
  IEmailWidthHeight,
  TEmailAlign,
  TEmailDirection,
  TEmailLinkTarget,
  TEmailVerticalAlign,
} from "./email-styles.interface";
import { EmailWilcoFooter, EmailWilcoHeader } from "./structures";

export abstract class EmailBlock {
  readonly type: string;
  readonly icon: string;
  public options: IEmailBlockOptions;
  public state: IEmailBlockState;
}

export interface IEmailBlockOptions {
  align?: TEmailAlign;
  mode?: "vertical" | "horizontal";
  font?: IEmailFont;
  iconSize?: IEmailLineHeight;
  lineHeight?: IEmailLineHeight;
  color?: string;
  innerPadding?: IEmailPadding;
  padding?: IEmailPadding;
  height?: IEmailWidthHeight;
  width?: IEmailWidthHeight;
  border?: IEmailBorder;
  backgroundColor?: string;
  fullWidth?: boolean;
  link?: IEmailLink;
  title?: string;
}

/**
 * Builder structure types.
 * @default cols_1
 */
export type TEmailStructureTypes =
  | "cols_1"
  | "cols_2"
  | "cols_3"
  | "cols_4"
  | "cols_12"
  | "cols_21";

/**
 * Builder link styles interface.
 */
export interface IEmailLink {
  href: string;
  target: TEmailLinkTarget;
}

/**
 * Builder structure columns' styles interface.
 */
export interface IEmailStructureColumnOptions {
  background?: IEmailBackground;
  border?: IEmailBorder;
  verticalAlign?: TEmailVerticalAlign;
}

/**
 * Builder structure styles interface.
 */
export interface IEmailStructureOptions {
  border?: IEmailBorder;
  background?: IEmailBackground;
  padding?: IEmailPadding;
  margin?: IEmailMargin;
  /**
   * Disable responsive for entire structure
   */
  disableResponsive?: boolean;
  /**
   * Mind the GAPS! Gaps between structure's columns.
   */
  gaps?: [number, number];
  columnsWidth?: number[];
  columns?: IEmailStructureColumnOptions[];
}

/**
 * Builder structure interface.
 */
export interface IEmailStructure {
  readonly type: TEmailStructureTypes;
  // unique IDs for unique class attribute
  readonly id: number;
  /**
   * If it's module, it can't be edited within email body.
   * @default false
   */
  isModule?: boolean;
  options: IEmailStructureOptions;
  // Structure columns.
  elements: TEmailBlocks[][];
  readonly columns: number;
}

/**
 * Builder block state interface. If `disabled: true` it can't be dragged from left sidebar.
 */
export interface IEmailBlockState {
  /**
   * @default false
   */
  disabled: boolean;
  /**
   * Show a message in case it's disabled.
   */
  message: string;
}

/**
 * Builder {@link SocialComponent} network options interface.
 */
export interface IEmailSocialNetwork {
  href: string;
  // target?: string;
  label?: string;
  name:
    | "github"
    | "instagram"
    | "web"
    | "snapchat"
    | "youtube"
    | "vimeo"
    | "medium"
    | "soundcloud"
    | "dribbble"
    | "facebook"
    | "twitter"
    | "pinterest"
    | "linkedin"
    | "tumblr"
    | "xing";
  padding?: IEmailPadding;
}

/**
 * Builder {@link IEmailBuilder} general options interface.
 */
export interface IEmailGeneralOptions {
  width?: IEmailWidthHeight;
  background?: IEmailBackground;
  padding?: IEmailPadding;
  direction?: TEmailDirection;
  name?: string;
  previewText?: string;
  global?: {
    fonts?: string[];
    padding?: IEmailPadding;
  };
}

/**
 * Main builder Email Object interface.
 */
export interface IEmailBuilder {
  general?: IEmailGeneralOptions;
  structures?: IEmailStructure[];
  googleFonts?: string[];
}

/**
 * Builder module interface.
 */
export interface IEmailUserModule {
  /**
   * @deprecated Add name instead
   * @ignore
   */
  thumb?: string;
  name?: string;
  module: IEmailStructure;
}

/**
 * Builder predefined template interface.
 */
export interface IEmailUserTemplate {
  title: string;
  thumbPath: string;
  templateData: IEmailBuilder;
}

/**
 * Builder Template Gallery templates interface.
 */
export interface IEmailUserTemplateCategory {
  category: string;
  templates: IEmailUserTemplate[];
}

/**
 * Builder IMage Gallery images interface.
 */
export interface IEmailUserImageCategory {
  category: string;
  images: string[];
}

/**
 * Template Storage/Cache keys.
 */
export enum EEmailTemplatesStorage {
  LATEST_USED = "NGB_LATEST_USED_TEMPLATES",
  STORAGE = "NGB_TEMP_TEMPLATES_STORAGE",
}

export const DEFAULT_CONTENT_OBJECT: IEmailUserTemplate = {
  title: "",
  thumbPath: "",
  templateData: {
    general: {
      global: {
        fonts: [],
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
      },
      background: {
        repeat: "no-repeat",
        color: "#ffffff",
        size: { value: 100, unit: "%", auto: false },
        url: null,
      },
      padding: { top: 10, right: 25, bottom: 10, left: 25 },
      previewText: "",
      direction: null,
      width: { value: 100, unit: "%" },
    },
    structures: [new EmailWilcoHeader(), new EmailWilcoFooter()],
  },
};
