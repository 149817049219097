import { EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";
// <img src="assets/images/email/icon-address.png">
const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailTextBlock("Store Information", {
      font: { size: 16, weight: 800 },
    }),
    new EmailTextBlock(
      `
      <p><strong>{storeName}</strong></p>
      <p>{storeAddress}</p>
      <p>{storeCity}, {storeState} {storeZip}</p>
      <p>{storePhoneNumber}</p>
      `,

      { font: { size: 12 } }
    ),
  ],
  [
    new EmailTextBlock("Store Hours", { font: { size: 16, weight: 800 } }),
    new EmailTextBlock(
      `
      \n
      <p>{storeHoursWeek}</p>
      <p>{storeHoursWeekend}</p>
    `,
      { font: { size: 12 } }
    ),
  ],
];

export class EmailWilcoStoreInfo extends Structure {
  readonly icon = "image";
  readonly name = "store info";
  readonly options;

  constructor(options?: IEmailStructureOptions) {
    super("cols_2", ELEMENTS, options);
  }
}
