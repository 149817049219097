import { EmailQRCode, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

const ELEMENTS: TEmailBlocks[][] = [[new EmailQRCode("The real real")]];

export class EmailWilcoQRCode extends Structure {
  readonly icon = "receipt";
  readonly name = "qr code";

  constructor(options?: IEmailStructureOptions) {
    super("cols_1", ELEMENTS, options);
  }
}
