import { Field, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore  */
export enum QUANTITY_BREAK_TYPE {
  /**
   * Percentage discount from Retail Price
   *
   * Discount Type: Standard
   */
  PERCENT_DISCOUNT_FROM_RETAIL = "P",

  /**
   * Dollars off from Retail Price
   *
   * Discount Type: Standard
   */
  DOLLARS_OFF_FROM_RETAIL_STANDARD = "D",

  /**
   * Percentage mark up from Replacement Cost
   *
   * Discount Type: Standard
   */
  PERCENT_MARKUP_FROM_COST = "M",

  /**
   * Percentage discount from Total
   *
   * Discount Type: Family
   */
  PERCENT_DISCOUNT_FROM_TOTAL = "F",

  /**
   * Dollars off from Retail Price
   *
   * Discount Type: Family
   */
  DOLLARS_OFF_FROM_RETAIL_FAMILY = "L",
}

/** @ignore */
export type IQuantityBreakDiscount = {
  /** The quantity that need to be purchased before the `discount` applies */
  quantity: number;

  /** The discount amount that is applied as per the `type` of the QuantityBreak */
  discount: number;
};

/** @ignore */
@ObjectType({ isAbstract: true })
export class QuantityBreakBase extends EntityBase<QuantityBreakBase> {
  /**
   * The QuantityBreak Code is used as the ID.
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field((type) => String, { nullable: true })
  type: QUANTITY_BREAK_TYPE;

  @Field({ nullable: true })
  note: string;

  /**
   * A QuantityBreak can have up to 5 different discounts.
   *
   * They are ordered from smallest `quantity` to largest.
   *
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  discounts: [
    IQuantityBreakDiscount?,
    IQuantityBreakDiscount?,
    IQuantityBreakDiscount?,
    IQuantityBreakDiscount?,
    IQuantityBreakDiscount?
  ];
}

/**
 * Quantity Breaks are a type of discount that applies to bulk purchases, i.e. Buy X get $Y off
 *
 * A QB can have up to 5 different discounts / break points. They **must** be ordered from smallest `quantity` required, to largest.
 *
 * There are two categories of QuantityBreak. Simple, that only apply to a single {@link Item}. And Family, where a group of {@link Item}s count towards the quantity.
 */
@ObjectType("QuantityBreak")
export class QuantityBreak
  extends QuantityBreakBase
  implements Entity<QuantityBreakBase>
{
  toObject() {
    return {
      id: this.id,
      type: this.type,
      note: this.note,
      discounts: this.discounts,
    };
  }

  @Field((type) => [String])
  get discountMessages() {
    return this.discounts
      ? this.discounts.map((discount) => this.getDiscountMessage(discount))
      : [];
  }

  /**
   * Given the inputted quantity, returns the best posssible `discount` - or `false` if no discounts apply.
   *
   * @param quantity A quantity of an {@link Item} being purchased
   * @returns The best QuantityBreak `discount` for the given `quantity`
   */
  getBestDiscount(quantity: number) {
    const bestDiscount = this.discounts
      .sort((a, b) => b.quantity - a.quantity)
      .find((discount) => discount.quantity <= quantity);

    return bestDiscount || false;
  }

  calculateDiscount({
    regularPrice,
    replacementCost,
    quantity,
  }: {
    regularPrice: number;
    replacementCost: number;
    quantity: number;
  }) {
    const bestDiscount = this.getBestDiscount(quantity);

    if (!bestDiscount) return regularPrice;

    switch (this.type) {
      // P = % discount from retail price
      case QUANTITY_BREAK_TYPE.PERCENT_DISCOUNT_FROM_RETAIL:
        return regularPrice * ((100 - bestDiscount.discount) / 100);
      // D = $ off from regular price
      case QUANTITY_BREAK_TYPE.DOLLARS_OFF_FROM_RETAIL_STANDARD:
        return regularPrice - bestDiscount.discount;
      // M = % markup from cost
      case QUANTITY_BREAK_TYPE.PERCENT_MARKUP_FROM_COST:
        return replacementCost * ((100 + bestDiscount.discount) / 100);
      // F = % discount from total
      case QUANTITY_BREAK_TYPE.PERCENT_DISCOUNT_FROM_TOTAL:
        return (
          (quantity * regularPrice * ((100 - bestDiscount.discount) / 100)) /
          quantity
        );
      // L = $ off from retail price
      case QUANTITY_BREAK_TYPE.DOLLARS_OFF_FROM_RETAIL_FAMILY:
        return regularPrice - bestDiscount.discount;
    }
  }

  getDiscountMessage(discount: IQuantityBreakDiscount) {
    switch (this.type) {
      case QUANTITY_BREAK_TYPE.DOLLARS_OFF_FROM_RETAIL_FAMILY:
        return `Buy ${discount.quantity} or more and get $${discount.discount} off of the regular price.`;
      case QUANTITY_BREAK_TYPE.DOLLARS_OFF_FROM_RETAIL_STANDARD:
        return `Buy ${discount.quantity} or more and get $${discount.discount} off of the regular price.`;
      case QUANTITY_BREAK_TYPE.PERCENT_DISCOUNT_FROM_RETAIL:
        return `Buy ${discount.quantity} or more and get ${discount.discount}% off of the regular price.`;
      case QUANTITY_BREAK_TYPE.PERCENT_DISCOUNT_FROM_TOTAL:
        return `Buy ${discount.quantity} or more and get ${discount.discount}% off of the total.`;
      case QUANTITY_BREAK_TYPE.PERCENT_MARKUP_FROM_COST:
        return `Buy ${discount.quantity} or more and get a discount off of the regular price.`;
    }
  }
}
