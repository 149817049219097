/**
 * @packageDocumentation
 * @module Stores
 */
import { Field, HideField, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Address, EntityBase, StoreMeta } from "../../../interfaces";

export type StoreUserType = "picker" | "auditor";

@ObjectType({ isAbstract: true })
export class StoreBase extends EntityBase<StoreBase> {
  /**
   * The ID of a Store will always be its Wilco-assigned store number.
   *
   * @example Store 1 is McMinnville
   *
   * @example Store 23 is Petaluma
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The full name of the store.
   *
   * In the case of a physical store, this is typically the City postfixed with "Farm Store"
   *
   * @example e.g. McMinnville Farm Store.
   */
  @Field({ nullable: true })
  name: string;

  @Field({ nullable: true })
  phoneNumber: string;

  @Field({ nullable: true })
  fax?: string;

  @Field(() => Int, { nullable: true })
  ecomId: number;

  @Field(() => GraphQLJSON, { nullable: true })
  address: Address;

  @Field(() => GraphQLJSON, { nullable: true })
  metaData: StoreMeta;

  @Field({ nullable: true })
  storeImage?: string;

  @Field({ nullable: true })
  pickupImage?: string;

  /**
   * A short name of the store.
   *
   * This was introduced as a way of giving control over the display of the store name in more concise areas, i.e. when we don't want the "Farm Store" postfix.
   * However it was really never adopted, and we generally just use the City for this purpose.
   */
  @Field({ nullable: true })
  shortName: string;

  /**
   * Special instructions for Pickup orders relative to a store
   */
  @Field({ nullable: true })
  pickupInstructions: string;

  /**
   * 0 - Inactive stores
   *
   * 1 - Active stores
   *
   * 2 - Up and coming stores
   *
   */
  @Field({ nullable: true })
  active: number;

  /**
   * List of different days/hours that a store is open for
   */
  @Field(() => [String], { nullable: "itemsAndList" })
  storeHours?: String[];

  /**
   * The store code (different from the store number) is a single alphanumeric character
   * that is used by Epicor to identify the store.
   */
  @Field(() => String, { nullable: true })
  storeCode: string;

  /**
   * Email for a store to receive notifications for store specific orders, refunds, etc
   */
  @Field(() => String, { nullable: true })
  notifyEmail?: string;

  constructor(props) {
    super(props);
  }
}

@ObjectType()
export class Store extends StoreBase implements EntityBase<StoreBase> {
  @HideField()
  get shipFrom() {
    return {
      name: this.name,
      phone: this.phoneNumber,
      address: this.address,
    };
  }

  @HideField()
  get formattedAddress() {
    let formattedAddress = this.address.streetAddress1;

    if (this.address.streetAddress2) {
      formattedAddress += `\n${this.address.streetAddress2}`;
    }

    formattedAddress += `\n${this.address.city}, ${this.address.state} ${this.address.zip}`;

    return formattedAddress;
  }
}
