import { Field, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces/entity.interface";
import { DisplaySceneColumn } from "./scene-column/scene-column.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class DisplaySceneBase extends EntityBase<DisplaySceneBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the Scene.
   */
  @Field({ nullable: true })
  name: string;

  /**
   * Dimensions of a scene
   */
  @Field(() => [DisplaySceneColumn], { nullable: true })
  columns: DisplaySceneColumn[];

  /**
   * This is a snapshot of the current scene at a given moment in time.
   * This is what will be displayed on a display and can only be changed
   * when a user has saved all of their changes and deployed them. The
   * act of deploying should update this value.
   */
  @Field(() => GraphQLJSON, { nullable: true })
  deployedScene?: DisplaySceneBase;

  @Field(() => GraphQLJSON, { nullable: true })
  assignedStores?: number[];

  constructor(props: DisplaySceneBase) {
    super(props);
    this.columns = props?.columns?.map((i) => new DisplaySceneColumn(i)) ?? [];
  }
}

/**
 * A Scene is a config that determine the dimensional information of how a display will
 * represent data.
 */
@ObjectType("DisplayScene", {})
export class DisplayScene
  extends DisplaySceneBase
  implements Entity<DisplaySceneBase>
{
  getColumnView(storeNumber: number) {
    return this.columns.map((column) => ({
      ...column,
      productView: column.getProductsView(storeNumber),
    }));
  }

  getProducts() {
    return this.columns
      .map((column) => column?.productSnapshot)
      .filter((snapshot) => !!snapshot)
      .reduce(
        (productIds, snapshot) => [
          ...new Set([...productIds, ...snapshot.map(({ id }) => id)]),
        ],
        []
      );
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      columns: this.columns,
    };
  }
}
