import { Field, InputType, OmitType } from "@nestjs/graphql";
import { ItemColorInput } from "../../item-color/graphql/item-color-input.schema";
import { ItemSizeInput } from "../../item-size/graphql/item-size-input.schema";
import { ProductInput } from "../../product/graphql/product-input.schema";
import { ItemBase } from "../item.model";

/** @ignore */
@InputType()
export class ItemInput extends OmitType(
  ItemBase,
  ["product", "size", "color", "fineline", "quantityBreak"] as const,
  InputType
) {
  @Field(() => ProductInput, { nullable: true })
  product?: ProductInput;

  @Field(() => ItemColorInput, { nullable: true })
  color?: ItemColorInput;

  @Field(() => ItemSizeInput, { nullable: true })
  size?: ItemSizeInput;
}
