import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export type TEmailImageBlockOptions = Pick<
  IEmailBlockOptions,
  "border" | "width" | "height" | "link" | "align" | "title" | "padding"
>;

export class EmailImageBlock implements EmailBlock {
  readonly type = "image";
  readonly icon = "image";
  public options: TEmailImageBlockOptions = {
    border: {
      color: "#cccccc",
      style: "solid",
      width: 0,
      radius: 0,
    },
    width: {
      value: 100,
      unit: "px",
      auto: false,
      units: ["px"],
    },
    height: {
      value: 100,
      unit: "px",
      auto: true,
      units: ["px"],
    },
    link: {
      href: "",
      target: "_blank",
    },
    align: "center",
    title: "",
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  constructor(
    public src = "https://via.placeholder.com/600x200?text=CHANGE+ME",
    options?: TEmailImageBlockOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
