import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";

/**
 * A SignJob is a job to create a PDF. Collections can have multiple
 * versions and templates, which can translate to multiple Sign Jobs
 * that are needed in order to generate all of a collection's PDFs.
 *
 * A single SignJob has a single template/version associated with it.
 */
export class SignJobBase extends EntityBase<SignJobBase> {
  /**
   * Unique id for collection
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Id of the collection that the job is associated with
   */
  collectionId: number;

  /**
   * This is the sign template that is associated with this job
   */
  template: string;

  /**
   * This is the version (OR/CA/WA) that is associated with this job
   */
  version: string;

  /**
   * If withSource is true, then the generator will add the source template
   * file as a background to the PDF. If false, all that will be rendered
   * is SignLabel tokens.
   */
  withSource: boolean;

  /**
   * True if the generator is currently working on this job
   */
  isProcessing: boolean;

  createdAt: string;
  updatedAt: string;
}

@ObjectType()
export class SignJob extends SignJobBase implements Entity<SignJobBase> {
  toObject() {
    return null;
  }
}
