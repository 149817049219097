import {
  Field,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { Entity, EntityBase } from "../../../interfaces";
import { Item } from "../../products";
import { Package } from "../package/package.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class OrderItemBase extends EntityBase<OrderItemBase> {
  @Field(() => Int, { nullable: true })
  id: number;
  @Field(() => Int, { nullable: true })
  _packageId: Package["id"];

  @Field((type) => String, { nullable: true })
  itemNumber: Item["id"];

  @Field((type) => Item, { nullable: true })
  item?: Item;

  /**
   * Quantity of this {@link Item} in this {@link Package}
   */
  @Field(() => Int, { nullable: true })
  quantity: number;

  /**
   * Quantity that has been filled.
   */
  @Field(() => Int, { nullable: true })
  quantityFilled: number;

  /**
   * Quantity that has been refunded.
   *
   * TODO: Maybe delete? Use package.refunds.lineItems instead
   */
  @Field(() => Int, { nullable: true })
  quantityRefunded: number;

  /**
   * Tax charged on this Item
   */
  @Field({ nullable: true })
  tax: number;

  /**
   * Total charged on this Item
   *
   * @remarks Total is EXCLUSIVE of tax
   */
  @Field({ nullable: true })
  total: number;

  /**
   * Unit price charged on this Item
   *
   * @remarks EXCLUSIVE of tax
   */
  @Field({ nullable: true })
  unitPrice: number;

  /**
   * How much was saved on this Item
   *
   * TODO what is included in "savings"?
   */
  @Field({ nullable: true })
  saved: number;

  /**
   * Tax that was refunded
   */
  @Field({ nullable: true })
  refundTax: number;

  /**
   * Total that was refunded
   *
   * @remarks EXCLUSIVE of tax
   */
  @Field({ nullable: true })
  refundTotal: number;

  /**
   * To process refunds through an external source (such as Ecom),
   * we need to know the ID of the entity at the external source.
   *
   * For Ecom, this would be the `WC_Order_Item` ID.
   */
  @Field({ nullable: true })
  externalId: number;

  /**
   * A {@link Package} may optionally be staged per-item, when the OrderItem's
   * may not be staged in one location.
   */
  @Field((type) => String, { nullable: true })
  stagedAt: string;

  constructor(props) {
    super(props);
    this.item = new Item(props.item);
  }
}

@ObjectType()
export class OrderItem extends OrderItemBase implements Entity<OrderItemBase> {
  /**
   * The quantity remaining to be filled.
   *
   * This is calculated as:
   * ```
   * remaining = quantity - quantityFilled - quantityRefunded
   * ```
   */
  @HideField()
  get quantityRemaining() {
    return this.quantity - this.quantityFilled - this.quantityRefunded;
  }

  /**
   * Net Tax after refunds.
   */
  @HideField()
  get netTax() {
    const tax = this.tax ? this.tax : 0;
    const refundTax = this.refundTax ? this.refundTax : 0;
    return currency(tax).subtract(refundTax).value;
  }

  /**
   * Net Total after refunds.
   */
  @HideField()
  get netTotal() {
    const total = this.total ? this.total : 0;
    const refundTotal = this.refundTotal ? this.refundTotal : 0;
    return currency(total).subtract(refundTotal).value;
  }

  @HideField()
  get image() {
    let alt = "";
    let src = "";

    if (this.item?.product?.images && this.item?.product?.images.length > 0) {
      src = this.item?.product?.images[0].image;
      alt = this.item?.product?.images[0].alt;
    } else if (this.item?.image) {
      alt = this.item.image.alt ?? "";
      src = this.item.image.image ?? "";
    }

    return { alt, src };
  }

  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }
}

@InputType()
export class OrderItemInput extends OmitType(
  OrderItem,
  ["item"] as const,
  InputType
) {
  // @Field(() => ItemInput, { nullable: true })
  // item?: ItemInput;
}
