import { Field, InputType, OmitType } from "@nestjs/graphql";
import { Fineline } from "../../fineline/fineline.model";
import { FinelineInput } from "../../fineline/graphql/fineline-input.schema";
import { Category, CategoryBase } from "../category.model";

/** @ignore */
@InputType()
export class CategoryInput extends OmitType(
  CategoryBase,
  ["parent", "children", "finelines"] as const,
  InputType
) {
  @Field((type) => [FinelineInput], { nullable: "itemsAndList" })
  finelines: Fineline[];
  @Field((type) => CategoryInput, { nullable: true })
  parent: Category;
  @Field((type) => [CategoryInput], { nullable: "itemsAndList" })
  children: Category[];
}
