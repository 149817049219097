import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
/**
 * Current usecase:
 *
 * This is being used in the orders app as an option to add rules to
 * an order's packages. These rules are used to build a checklist and
 * additional inputs needed at load-out (and later other places in
 * fulfillment?) to ensure the employee does certain tasks for an
 * order that is considered a fraud risk.
 *
 * Future expectations:
 *
 * Currently this really can only happen to orders that are placed on-hold
 * as there isn't really enough time to apply a dynamic ruleset to anything
 * else because anything that isn't on-hold can be picked in real-time.
 *
 * Eventually an admin will be able to preconfigure global rulesets that will
 * be evaluated in order-sync. During order creation, we can pull these rules
 * and apply their conditions to the order, adding the rules that apply as
 * needed.
 */

/**
 * Note: In theory, I don't see a reason why this pattern can't be applied
 * elsewhere. Right now our use case is antiFraud, but these global rules
 * could apply to anything where we would want to do some sort of arbitrary
 * validation based on our entites. Just a thought.
 */
export const RuleTypes = ["antiFraud"] as const;
export type RuleTypes = typeof RuleTypes[number];

export const RuleActions = ["checklist", "input"] as const;
export type RuleActions = typeof RuleActions[number];

/**
 * This would allow us to statically define common conditions so the user
 * can pick from a list and set them up independently of another condition.
 * Conditions would only be checked/applied in order-sync when the order
 * is initially created.
 *
 * Example Rule
 *
 * value - Check ID (This would be displayed as a label on an input/checklist)
 * action - checklist (Display our label in a checklist)
 * conditions -
 * [
 *  {type: "price", value: 2000} (Add rule if order has an item with price >=2000),
 *  {type: "sku", value: "10006426"} (Add rule if order has an item with this sku),
 *  {type: "afThreshold", value: 20} (Add rule if af score has >= 20),
 *  ]
 */
export type RuleConditon<T> = {
  [Property in keyof T]: T;
};

export const AntiFraudTypes = ["price", "sku", "antiFraudThreshold"] as const;

export type AntiFraud = {
  ["price"]: number;
  ["sku"]: string;
  ["antiFraudThreshold"]: number;
};

@ObjectType({ isAbstract: true })
export class RuleBase<T = any> extends EntityBase<RuleBase> {
  @Field(() => Int, { nullable: true })
  id?: number;
  /**
   * Label that is displayed for checklist/input label
   */
  @Field({ nullable: true })
  label: string;
  /**
   * Might not go this direction later, but we could use rules for other validation
   * that isn' just antifraud
   */
  @Field(() => GraphQLJSON, { nullable: true })
  type: RuleTypes;
  /**
   * List of actions that we can use to add the rule as an input or checklist (both?)
   */
  @Field(() => GraphQLJSON, { nullable: true })
  action: RuleActions;
  /**
   * Rules can be applied without conditions. If a rule has no
   * conditions, then it will be added. Otherwise, only add rule
   * if one or more of the conditions is met.
   */
  @Field(() => GraphQLJSON, { nullable: true })
  conditions?: T[];

  constructor(props: RuleBase) {
    super(props);
  }
}

@ObjectType()
export class Rule<T = any> extends RuleBase implements Entity<RuleBase> {
  toObject(): RuleBase<T> {
    return { label: this.label, type: this.type, action: this.action };
  }
}

@InputType()
export class RuleInput extends OmitType(Rule, [] as const, InputType) {}
