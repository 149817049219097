import {
  Field,
  Float,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
import { DomainError } from "../../interfaces/domain-error.interface";
import { AdAssortment, AdCampaign, SaleDate } from "./";
import { AdAssortmentInput } from "./ad-assortment.model";
import { AdCampaignInput } from "./ad-campaign.model";
import { AdErrorType } from "./ad-item/vo/ad-error.model";

@ObjectType({ isAbstract: true })
export class AdBlockBase extends EntityBase<AdBlockBase> {
  @Field((type) => Int, { nullable: true })
  id: number;
  @Field((type) => Int, { nullable: true })
  pageId: number;

  @Field({ nullable: true })
  type: string;

  @Field({ nullable: true })
  requiresVendorApproval: boolean;

  @Field({ nullable: true })
  vendorCoopAvailable: boolean;

  @Field({ nullable: true })
  vendorCoopDetails: string;

  @Field({ nullable: true })
  salesCopy: string;

  @Field({ nullable: true })
  designNotes: string;

  @Field((type) => Int, { nullable: true })
  unitsSold: number;
  @Field((type) => Int, { nullable: true })
  position: number;

  @Field((type) => GraphQLJSON, { nullable: true })
  featuredSkus: string[];

  @Field((type) => GraphQLJSON, { nullable: true })
  error: DomainError<AdErrorType>;

  @Field((type) => GraphQLJSON, { nullable: true })
  versions: string[];

  /**
   * The media that this block is associated with
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  includedMedia: string[];
  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field({ nullable: true })
  margin: number;

  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field((type) => Float, { nullable: true })
  revenue: number;

  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field((type) => Float, { nullable: true })
  totalCost?: number;

  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field({ nullable: true })
  inStoreSales: number;

  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field({ nullable: true })
  onlineSales: number;

  /**
   * An aggregate value that's calculated by the recap processor
   */
  @Field({ nullable: true })
  avgQuantity: number;

  /**
   * % increase/decrease in quantity sold in our block versus the last
   * year
   */
  @Field({ nullable: true })
  annualLift: number;

  /**
   * % increase/decrease in quantity sold in our block versus the last
   * 60 days
   */
  @Field({ nullable: true })
  sixtyDayLift: number;

  @Field((type) => GraphQLJSON, { nullable: true })
  saleDates: SaleDate[];

  @Field((type) => [AdCampaign], { nullable: "itemsAndList" })
  campaigns?: AdCampaign[];

  @Field((type) => [AdAssortment], { nullable: "itemsAndList" })
  skuBundles?: AdAssortment[];

  constructor(props: AdBlockBase) {
    super(props);
    this.skuBundles = props.skuBundles
      ? props.skuBundles.map((p) => new AdAssortment(p))
      : null;
  }
}

@ObjectType("AdBlock", {})
export class AdBlock extends AdBlockBase implements Entity<AdBlockBase> {
  @HideField()
  get projections() {
    return this.skuBundles.reduce(
      ({ unitsSold, sales, totalCost }, assortment) => {
        unitsSold = currency(unitsSold ?? 0).add(
          assortment.projection ?? 0
        ).value;
        sales = currency(sales ?? 0).add(
          assortment.projectedEarnings ?? 0
        ).value;
        totalCost = currency(totalCost ?? 0).add(
          assortment.projectedCost ?? 0
        ).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }

  @HideField()
  get recap() {
    return {
      unitsSold: this.unitsSold,
      sales: this.revenue,
      totalCost: this.totalCost,
    };
  }

  toObject() {
    return null;
  }
}

@InputType()
export class AdBlockInput extends OmitType(
  AdBlock,
  ["skuBundles", "campaigns"] as const,
  InputType
) {
  @Field(() => [AdAssortmentInput])
  skuBundles?: [AdAssortmentInput];
  @Field(() => [AdCampaignInput])
  campaigns?: [AdCampaignInput];
}
