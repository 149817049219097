export interface ChecklistItem {
  /**
   * Label that displays in the interface
   */
  label: string;

  /**
   * The state of the checklist item
   */
  checked: boolean;

  /**
   * Whether this item is required to be `checked: true` before allowing an Order/Package to progress
   */
  required: boolean;

  /**
   * Optional action associated with this checklist item, e.g. collect a signature, or take a photo
   */
  action: string | null;
}

export type Checklist<T extends string> = Record<T, ChecklistItem[]>;
