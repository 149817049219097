/**
 * An Image that has been uploaded to Ecom.
 *
 * @category Value Object
 */
export interface Image {
  /**
   * This is the Media ID of the image in WordPress' media library.
   * Entities on Ecom often need to be associated with the Media ID.
   */
  ecomId: number;

  /**
   * URL pointing to the original full-resolution image.
   */
  image: string;

  /**
   * URL pointing to a thumbnail version of the image.
   */
  thumbnail: string;

  /**
   * Text that is to be used as the ALT tag for images.
   */
  alt?: string;
}
