import { EmailSocialBlock, EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

const CONTACT_US = "https://www.farmstore.com";
const PRIVACY_POLICY = "https://www.farmstore.com/privacy-policy";

const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailTextBlock(
      `<p>Copyright 2023, Wilco Farm Stores, 200 Industrial Way, Mt. Angel, OR 97362</p>
      <p>
      <a href="${CONTACT_US}" rel="noopener noreferrer" target="_blank">Contact Us</a>   
      <a href="${PRIVACY_POLICY}" rel="noopener noreferrer" target="_blank"> Privacy Policy</a>
      </p>`,
      { font: { size: 10 } }
    ),
  ],
  [
    new EmailSocialBlock(
      [
        {
          href: "https://www.farmstore.com",
          name: "facebook",
        },
        {
          href: "https://www.farmstore.com",
          name: "twitter",
        },
        {
          href: "https://www.farmstore.com",
          name: "instagram",
        },
        {
          href: "https://www.farmstore.com",
          name: "pinterest",
        },
        {
          href: "https://www.farmstore.com",
          name: "linkedin",
        },
      ],
      { padding: { right: 0 } }
    ),
  ],
];

export class EmailWilcoFooter extends Structure {
  readonly icon = "minimize";
  readonly name = "footer";

  constructor(options?: IEmailStructureOptions) {
    super("cols_12", ELEMENTS, {
      ...options,
      background: {
        color: "#F8F5EC",
      },
    });
  }
}
