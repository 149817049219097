/**
 * An [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) string representing a date & time.
 *
 * This should be passed directly into DayJS with `dayjs(datetime)`
 *
 * @remarks
 * To ensure no timezone ambiguity, this should always be saved in GMT time.
 *
 * @example
 * 2018-04-04T16:00:00.000Z
 */
export type DateTime = string;
