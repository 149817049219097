/**
 * Orders are purchases that are made by Customers.
 *
 * Orders can be made by customers via Farmstore (Ecom) or in person at a store.
 * All orders from Ecom come into Back40, but only delivery orders made in-store come into Back40.
 *
 * An {@link Order} is made up of one or more {@link Package}, where a {@link Package} is a subset of the {@link Order} that is fulfilled by a particular {@link Store}
 * through one of three fulfillment methods - Pickup, Ship To Home or Wilco Delivery.
 *
 * For example, an Order (F-12345) could have two Packages, a Pickup from Store 12 (P-10001-12) and a Ship To Home (S-10002-01).
 *
 * Each {@link Package} has one or more {@link OrderItem}.
 *
 * At some point, a store needs to fulfill a {@link Package} (physically pick the goods from the store/warehouse) - this is done through a {@link Fulfillment} job.
 * Most {@link Package}s are fulfilled at time of creation, however some (like a Delivery) are fulfilled a day before they're scheduled for delivery.
 *
 * When some or all {@link OrderItem} cannot be fulfilled, a {@link Refund} may be issued.
 *
 * ## Examples
 *
 * The workflow of fulfillment varies by fulfillment type, the following examples are provided to document each workflow.
 *
 * ### Pickup
 *
 * An Order (F-12345) comes in with one Package (P-10001-01) which instantly creates a Fulfillment (also called P-10001-01)
 *
 * Staff see the Package in the Fulfillment app as `pending` which has a `pending` Fulfillment job. A Staff member starts
 * to fulfill it and both Package and Fulfillment move to `processing`.
 *
 * Once all of the OrderItems have been picked, the Fulfillment moves to `processed` and the Staff member will stage
 * the items. Once staged at a location, the Fulfillment is `completed` and the Package moves to `processed` (ready to pickup).
 *
 * Once picked up, the Package becomes `completed`.
 *
 * ### Ship To Home
 *
 * An Order (F-54637) comes in with one Package (S-10002-01) which instantly creates a Fulfillment (also called S-10002-01)
 *
 * Staff see the Package in the Fulfillment app as `pending` which has a `pending` Fulfillment job. A Staff member starts
 * to fulfill it and both Package and Fulfillment move to `processing`.
 *
 * Once all of the OrderItems have been picked, the Fulfillment moves to `processed` and the Staff member will stage
 * the items. Once staged at a location, the Fulfillment is `completed` and the Package moves to `processed` (ready to ship).
 *
 * Staff will then (generally later in bulk) will pack the items into a box, generate a shipping label, and put it somewhere
 * ready for collection - the Package is now `completed`.
 *
 * ### Delivery
 *
 * An Order (W-98765) comes in with one package (D-10003-01).
 *
 * Staff see the Package in the Delivery app (but not Fulfillment), and start the process of scheduling it (the Package becomees `processing`).
 *
 * Once scheduled, the Package becomes `processed` (pending delivery).
 *
 * At -1 days before delivery, the system generates a Fulfillment job (also called D-10003-01) with a status of `pending`, which now appears in the Fulfillment app.
 *
 * Staff start to pick the Fulfillment (`processing`) and once the items have been picked (`processed`) they stage it
 * at the delivery bay (`completed`).
 *
 * On the day of delivery, the delivery driver loads up the Package, delivers it, and the Package becomes `completed`.
 *
 * @module Orders
 */

export * from "./delivery/delivery.model";
export * from "./fulfillment/fulfillment.model";
export * from "./fulfillment/graphql/fulfillment-metrics.graphql";
export * from "./fulfillment/graphql/fulfillment-result.graphql";
export * from "./order-item/order-item.model";
export * from "./order/graphql/export-order.graphql";
export * from "./order/order.model";
export * from "./order/vo/giftcard.model";
export * from "./package/graphql/package.graphql";
export * from "./package/graphql/paginated-package.graphql";
export * from "./package/package.model";
export * from "./refund/graphql/paginated-refund.graphql";
export * from "./refund/refund.model";
export * from "./shipping-charge/shipping-charge.model";
