import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";

@ObjectType({ isAbstract: true })
export class TransactionBase extends EntityBase<TransactionBase> {
  @Field((type) => Int)
  id: number;

  @Field((type) => String, { nullable: true })
  salesHeader: string;

  @Field((type) => Int, { nullable: true })
  _packageId?: number;

  @Field((type) => Int, { nullable: true })
  _refundId?: number;

  constructor(props?: TransactionBase) {
    super(props);
  }
}

@ObjectType("Transaction", {})
export class Transaction
  extends TransactionBase
  implements Entity<TransactionBase>
{
  toObject() {
    return null;
  }
}
