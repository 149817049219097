import createEnum from "../../utils/create-enum";

/** @ignore **/
export interface IBaseDimensions {
  height: number;
  width: number;
  top: number;
  left: number;
}
/** @ignore **/
export interface IFabricScalars {
  scaleX: number;
  scaleY: number;
}
/** @ignore **/
export interface IFabricColor {
  fill: any; // work around to avoid importing fabric here
}

/** @ignore **/
export interface IBaseAttributes
  extends IBaseDimensions,
    IFabricScalars,
    IFabricColor {
  type: string;
  name: string;
}

// Additional elements that only text fields have
/** @ignore **/
export interface ITextAttributes {
  fontSize?: number;
  text?: string;
  textAlign?: string;
  fontWeight?: string | number;
  fontStyle?: "" | "normal" | "italic" | "oblique";
  underline?: boolean;
  linethrough?: boolean;
  fontFamily?: string;
  opacity?: number;
  tokenType?: TokenType;
}

const TOKENS = {
  TITLE: "title",
  SUB_TITLE: "sub-title",
  PRICE: "price-group",
  DATE: "date-group",
  SKU: "sku-group",
  WITH_REWARDS: "with-rewards",
} as const;

export const [TokenTypeEnum, tokenTypes] = createEnum(TOKENS);

export type TokenType = typeof tokenTypes[number];

export interface ITextToken extends IBaseAttributes, ITextAttributes {}

// Implemented to showcase use of discriminated type - currently not used
export interface IRectAttributes extends IBaseAttributes {
  text: string;
  tokenType: TokenType;
}

/**
 * Discriminated Union type - Attributes are based on type
 *
 * The type refers to the fabric shape
 *  */
export type IToken =
  | {
      type: "textbox";
      objects?: IToken[];
      attributes: ITextToken;
    }
  | {
      type: "group";
      objects?: IToken[];
      attributes: ITextToken;
    }
  | {
      type: "price-group" | "date-group" | "sku-group";
      objects?: IToken[];
      attributes: ITextToken;
    };

export class TokenValue {
  type: "textbox" | "group" | "price-group" | "date-group" | "sku-group";
  objects?: TokenValue[];
  attributes: ITextToken;

  constructor(props) {
    Object.assign(this, props);
  }
}

export interface ICanvasOptions {
  margins: { top: number; bottom: number; left: number; right: number };
}
