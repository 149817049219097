/**
 * Search criteria used to find entites in a database. Each rule is evaluated
 * based on its condition/operator to form a unique where clause to query
 * the field/value provided
 *
 * @example where {field} is {value} and{condition}...
 *
 * @category Value Object
 */
export interface Criteria {
  /**
   * Each rule is its own seperate where clause
   */
  rules: {
    /**
     * Value of the field being queried
     */
    value: string;

    /**
     * Field being queried. Column name on a table.
     */
    field: string;

    /**
     * Operator used to determine type of where clause to use. This can be
     * seen in libs/utils/criteria.ts in the omniapi
     */
    operator: CriteriaOperators;

    /**
     * Condition used by this where clause.
     */
    condition: "and" | "or";
  }[];
}

/**
 * These types are used to determine what type of where clause to use in the criteria
 * builder.
 */
export type CriteriaOperators =
  | "is" // =
  | "is not" // <>
  | "is in" // whereIn(field, (...value))
  | "starts with" // like %value
  | "ends with" // like value%
  | "contains"; // like %value%
