import { Field, InputType, Int } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { IEmailBuilder } from "../vo/email.interface";

/** @ignore */
@InputType()
export class EmailInput {
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field((type) => GraphQLJSON)
  builder: IEmailBuilder;
}
