import createEnum from "../../../utils/create-enum";

const PROMOTION_ENTITIES = {
  PROMOTION: "promotion",
  MEDIA: "media",
  PAGE: "page",
  BLOCK: "block",
  ASSORTMENT: "assortment",
  SIGNAGE: "ad-signage",
};

export const [PromotionEntityEnum, promotionEntities] =
  createEnum(PROMOTION_ENTITIES);
