/**
 * @packageDocumentation
 * @module Vendors
 */

import { Field, ID, ObjectType } from "@nestjs/graphql";

@ObjectType()
export class Vendor {
  @Field((type) => ID)
  id: number;

  vendorName: string;
  requiresPromotionApproval: string;
  hasPromotionCoop: string;
}
