import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";
import { DisplayScene } from "../display-scene/display-scene.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class DisplayBase extends EntityBase<DisplayBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the display.
   */
  @Field({ nullable: true })
  name: string;

  /**
   * Collection of scenes to display. Current MVP is 1 display -> 1 Scene, but
   * this could potentially showcase multiple scenes with animations and set
   * timing configs.
   */
  @Field(() => [DisplayScene], { nullable: true })
  scenes: DisplayScene[];

  constructor(props: DisplayBase) {
    super(props);
    this.scenes = props?.scenes?.map((i) => new DisplayScene(i)) ?? [];
  }
}

/**
 * A display is a specific display that is setup in a store. The idea for this is it
 * gives us a lot of flexibility to add on more displays later on if the need should
 * arise. Ideally we could congfigure a string of scenes together (product information,
 * ads, store specific slides, etc) and set it up to where they can be managed in
 * a store manager app in Back40.
 */
@ObjectType("Display", {})
export class Display extends DisplayBase implements Entity<DisplayBase> {
  constructor(props: DisplayBase) {
    super(props);
    this.scenes = props?.scenes?.map((scene) => new DisplayScene(scene)) ?? [];
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      scenes: this.scenes,
    };
  }
}
