import { Field, InputType, OmitType } from "@nestjs/graphql";
import { ProductInput } from "../../product/graphql/product-input.schema";
import { Product } from "../../product/product.model";
import { BrandBase } from "../brand.model";

/** @ignore */
@InputType()
export class BrandInput extends OmitType(
  BrandBase,
  ["featuredProducts", "newProducts"] as const,
  InputType
) {
  @Field((type) => [ProductInput], { nullable: "itemsAndList" })
  featuredProducts: Product[];
  @Field((type) => [ProductInput], { nullable: "itemsAndList" })
  newProducts: Product[];
}
