import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ChemicalBase extends EntityBase<ChemicalBase> {
  @Field((type) => Int, { nullable: true })
  id: number;
  @Field({ nullable: true })
  name: string;
  @Field({ nullable: true })
  typeOfToxicity: string;
}

@ObjectType("Chemical", {})
export class Chemical extends ChemicalBase implements Entity<ChemicalBase> {
  toObject(): ChemicalBase {
    return null;
  }
}
