import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export type TEmailButtonBlockOptions = Pick<
  IEmailBlockOptions,
  | "backgroundColor"
  | "border"
  | "color"
  | "font"
  | "align"
  | "fullWidth"
  | "lineHeight"
  | "link"
  | "innerPadding"
  | "padding"
>;

export class EmailButtonBlock implements EmailBlock {
  readonly type = "button";
  readonly icon = "radio_button_checked";
  public options: TEmailButtonBlockOptions = {
    backgroundColor: "#003b5c",
    border: {
      color: "#003b5c",
      style: "solid",
      width: 0,
      radius: 5,
    },
    color: "#ffffff",
    font: {
      fallback: "Arial, Helvetica, sans-serif",
      family: "Montserrat",
      size: 14,
      style: "normal",
      weight: 800,
    },

    align: "center",
    fullWidth: false,
    // verticalAlign: 'middle',
    lineHeight: {
      value: 120,
      unit: "%",
    },
    link: {
      href: "",
      target: "_blank",
    },
    innerPadding: {
      top: 10,
      right: 15,
      bottom: 10,
      left: 15,
    },
    padding: {
      top: 10,
      right: 25,
      bottom: 10,
      left: 25,
    },
  };

  constructor(
    public innerText: string = "Click on me",
    options?: TEmailButtonBlockOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
