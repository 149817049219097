import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class SpeciesBase extends EntityBase<SpeciesBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the Species.
   *
   * This is public facing and will be shown on Ecom.
   */
  @Field({ nullable: true })
  name: string;

  /**
   * The ID of the Species on Ecom.
   * It is used for interacting (updating/deleting) with it on Ecom.
   */
  @Field((type) => Int, { nullable: true })
  ecomId: number;
}

/**
 * A Species is a type of animal.
 *
 * A {@link Product} can (optionally) be associated with a Species.
 */
@ObjectType("Species", {})
export class Species extends SpeciesBase implements Entity<SpeciesBase> {
  toObject() {
    return {
      id: this.id,
      name: this.name,
      ecomId: this.ecomId,
    };
  }
}
