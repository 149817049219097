/**
 * Error object used by back40 to discern possible error states.
 *
 * @category Value Object
 */
export interface DomainError<T> {
  /**
   * Custom types that can be used for the error object. Use these
   * types in back40 to discern what the error actually is and
   * perform any additional client-side logic.
   *
   * @example Mapping icons
   */
  type: T;

  /**
   * Message of the error.
   */
  msg: string;

  /**
   * Level of the error. This is currently being used to determine
   * the color of the icons in back40.
   *
   * Danger - red
   * Warning - yellow
   */
  level: DomainErrorLevels;
}

export type DomainErrorLevels = "warning" | "danger";
