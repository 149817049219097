import { defaultsDeep } from "lodash";
import {
  EmailBlock,
  IEmailBlockOptions,
  IEmailBlockState,
} from "../email.interface";

export type TEmailDividerBlockOptions = Pick<
  IEmailBlockOptions,
  "border" | "padding"
>;

export class EmailDividerBlock implements EmailBlock {
  readonly type = "divider";
  readonly icon = "remove";
  public options: TEmailDividerBlockOptions = {
    border: {
      color: "#ddcba4",
      style: "dashed",
      width: 2,
    },
    padding: {
      top: 20,
      right: 0,
      bottom: 20,
      left: 0,
    },
  };

  constructor(
    options?: TEmailDividerBlockOptions,
    public state: IEmailBlockState = {
      disabled: false,
      message: "",
    }
  ) {
    this.options = defaultsDeep(options, this.options);
  }
}
