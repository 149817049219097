import {
  Field,
  Float,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { GraphQLJSON } from "graphql-type-json";
import {
  CreateActivityLogParams,
  EntityTypes,
} from "../../../domain/activity-log";
import { DateTime, Entity, EntityBase } from "../../../interfaces";
import { Transaction } from "../../transactions";
import { OrderItem } from "../order-item/order-item.model";
import { Package, PackageInput } from "../package/package.model";
import { ShippingCharge } from "../shipping-charge/shipping-charge.model";

export interface Notification {
  success: string;
  error: string;
  log: CreateActivityLogParams;
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class RefundBase extends EntityBase<RefundBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  @Field(() => Int, { nullable: true })
  _packageId: Package["id"];

  @Field((type) => String)
  status: "on-hold" | "pending" | "processing" | "completed" | "error";

  /**
   * The ID from the source of the Refund.
   *
   * @readonly
   */
  @Field(() => String, { nullable: true })
  externalId: string;

  /**
   * Total of the refund
   *
   * @remarks This is a positive value
   * @remarks INCLUSIVE of tax
   */
  @Field(() => Float, { nullable: true })
  total: number;

  /**
   * If a coupon was accomodated by this refund, then its total has
   * been decremented by the couponTotal amount.
   *
   * Since it is decremented this way, we need to account for it on
   * the refund. We use this value to determine if the package has been
   * fully refunded without needing to grab our parent order and all
   * of its packages.
   */
  @Field(() => Float, { nullable: true })
  couponTotal: number;

  @Field(() => String, { nullable: true })
  reason: string;

  @Field(() => String, { nullable: true })
  initiatedBy: string;

  @Field((type) => GraphQLJSON, { nullable: true })
  lineItems: {
    orderItemId: OrderItem["id"];
    externalId: number;
    quantity: number;

    /**
     * Refund line item total
     *
     * @remarks This will be a negative value
     * @remarks INCLUSIVE of tax
     */
    total: number;

    /** @remarks This will be a negative value  */
    tax: number;
    adjustInventory: boolean;
  }[];

  @Field((type) => GraphQLJSON, { nullable: true })
  shippingItems: {
    id: ShippingCharge["id"];
    externalId: string;
    tax: number;

    /**
     * Shipping Item total
     *
     * @remarks INCLUSIVE of tax
     */
    total: number;
  }[];

  @Field((type) => Package, { nullable: true })
  package?: Package;

  @Field((type) => String, { nullable: true })
  refundAfter: DateTime;

  @Field((type) => String, { nullable: true })
  error?: string;

  @Field((type) => Boolean, { nullable: true })
  refundPayment?: boolean;

  @Field((type) => Boolean, { nullable: true })
  isProcessing?: boolean;

  @Field(() => String, { nullable: true })
  approvedBy: string;

  @Field((type) => String, { nullable: true })
  approvedAt: DateTime;

  @Field((type) => String, { nullable: true })
  completedAt: DateTime;

  @Field((type) => String, { nullable: true })
  integratedAt?: DateTime;

  @Field((type) => String, { nullable: true })
  integratedRef?: DateTime;

  @Field((type) => String, { nullable: true })
  createdAt?: DateTime;

  @Field((type) => String, { nullable: true })
  updatedAt?: DateTime;

  @Field((type) => Transaction, { nullable: true })
  transaction?: Transaction;

  constructor(props: Partial<RefundBase>) {
    super(props);
    this.package = props.package ? new Package(props.package) : null;
  }
}

/**
 * A Refund is a credit being made to a Customer for their {@link Order}, processed against a {@link Package}.
 *
 * Refunds act like jobs at first, not being processed until after the `refundAfter` time has passed.
 *
 * Once processed, they become immutable records of a refund against an {@link Package}.
 */
@ObjectType()
export class Refund extends RefundBase implements Entity<RefundBase> {
  @HideField()
  get _logEntity() {
    return {
      entity: EntityTypes.PACKAGE,
      entityId: this._packageId,
    };
  }

  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }

  @HideField()
  get netTotals() {
    /** @remarks This is a negative value */
    const lineItemTotal = this.lineItems.reduce(
      (total, item) => total.add(item.total),
      currency(0)
    );

    /** @remarks This is a negative value */
    const shippingItemTotal = this.shippingItems.reduce(
      (total, item) => total.add(item.total),
      currency(0)
    );

    /** @remarks This is a negative value */
    const additionalAmount = currency(this.total)
      .add(lineItemTotal) // adding because lineItemTotal is negative
      .add(shippingItemTotal); // adding because shippingItemTotal is negative

    return { additionalAmount, lineItemTotal, shippingItemTotal };
  }

  /**
   * @deprecated Use `netTotals`
   */
  @HideField()
  get totals() {
    return this.netTotals;
  }

  /**
   * Helper function to get package's reference
   */
  @HideField()
  get reference() {
    return this.package?.reference ?? "-";
  }

  createLog(
    success: string,
    error: string,
    log: string,
    action: string
  ): Notification {
    return {
      success,
      error,
      log: {
        ...this._logEntity,
        user: null,
        action,
        message: log,
      },
    };
  }
}

@InputType()
export class RefundInput extends OmitType(
  Refund,
  ["package", "transaction"] as const,
  InputType
) {
  @Field(() => PackageInput)
  package?: PackageInput;
}
