import { Field, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";
import { CartItem } from "../cart-item/cart-item.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class CartBase extends EntityBase<CartBase> {
  items: CartItem[];

  @Field((type) => String, { nullable: true })
  customerNumber: string;
}

/**
 * Represents a Cart on Ecom (Farmstore)
 */
@ObjectType()
export class Cart extends CartBase implements Entity<CartBase> {
  toObject() {
    return null;
  }
}
