import { Field, ID, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";
import { DomainError } from "../../../interfaces/domain-error.interface";
import { Item } from "../../products";
import { AdErrorType } from "./vo/ad-error.model";
import { RecapData } from "./vo/ad-recap-data.model";

@ObjectType({ isAbstract: true })
export class AdItemBase extends EntityBase<AdItemBase> {
  @Field((type) => ID)
  itemNumber: string;
  @Field((type) => Int)
  skuBundleGroupId: number;

  @Field({ nullable: true })
  projection: number;
  @Field({ nullable: true })
  saleCost: number;
  @Field({ nullable: true })
  salePrice: number;
  @Field({ nullable: true })
  retailPrice: number;

  @Field({ nullable: true })
  violatesImap: boolean;
  @Field({ nullable: true })
  violatesProjection: boolean;

  @Field((type) => GraphQLJSON, { nullable: true })
  recapData?: RecapData;

  @Field((type) => GraphQLJSON, { nullable: true })
  error?: DomainError<AdErrorType>;

  @Field((type) => Item, { nullable: true })
  item?: Item;
}

@ObjectType()
export class AdItem extends AdItemBase implements Entity<AdItemBase> {
  toObject() {
    return null; // TODO
  }
}
