import { ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class EcomRefundBase extends EntityBase<EcomRefundBase> {
  id: number;
  created_at: string;
  amount: string;
  reason: string;
  line_items: {
    id: number;
    subtotal: string;
    subtotal_tax: string;
    total: string;
    total_tax: string;
    price: string;
    quantity: number;
    tax_class: string;
    name: string;
    product_id: number;
    sku: string;
    meta: any[];
  }[];
}

/**
 * An Ecom Refund is a refund that exists on Farmstore, this model reflects how that data is stored.
 *
 * It's not "part of our Domain" though, it only exists here for use in Adapter's that should map data into a Refund.
 */
@ObjectType()
export class EcomRefund
  extends EcomRefundBase
  implements Entity<EcomRefundBase>
{
  toObject() {
    return null;
  }
}
