import { Field, InputType, OmitType } from "@nestjs/graphql";
import { EcomUserInput } from "../../../ecom/ecom-user/graphql/ecom-user.input";
import { Profile } from "../profile.model";

@InputType()
export class ProfileInput extends OmitType(
  Profile,
  ["ecomUser"] as const,
  InputType
) {
  @Field((type) => EcomUserInput, { nullable: true })
  ecomUser?: EcomUserInput;
}
