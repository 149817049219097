import { Field, Int, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase, Image } from "../../../interfaces";
import { Product } from "../product/product.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class BrandBase extends EntityBase<BrandBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the Brand.
   *
   * This is public facing and will be shown on Ecom.
   */
  name: string;

  /**
   * (Optional) An image to be used on Ecom when showcasing this brand.
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  image?: Image;

  /**
   * The ID of the Brand on Ecom.
   * It is used for interacting (updating/deleting) with it on Ecom.
   */
  @Field((type) => Int, { nullable: true })
  ecomId: number;

  /**
   * Associated Products from this Brand to be FEATURED.
   *
   * This is used on the Ecom landing page for the Brand to showcase featured products.
   */
  @Field((type) => [Product], { nullable: true })
  featuredProducts?: Product[];

  /**
   * Associated Products from this brand that are NEW.
   *
   * This is used on the Ecom landing page for the Brand to showcase new products.
   * If none are set, Ecom will automatically use the most recently published products from the Brand.
   */
  @Field((type) => [Product], { nullable: true })
  newProducts?: Product[];
}

/**
 * A Brand is a collection of products manufactured under a particular name.
 * We use Brand's to allow search filtering on Ecom.
 *
 * A {@link Product} can (optionally) be associated with a Brand.
 */
@ObjectType("Brand", {})
export class Brand extends BrandBase implements Entity<BrandBase> {
  toObject() {
    return null;
    // {
    //   id: this.id,
    //   name: this.name,
    //   image: this.image,
    //   ecomId: this.ecomId,
    //   featuredProducts: this.featuredProducts,
    //   newProducts: this.newProducts,
    // };
  }
}
