import { Field, InputType, Int } from "@nestjs/graphql";

/**
 * Params used to search for a Finelines
 */
@InputType({
  description: `Params used to search for a Finelines`,
})
export class IGetFinelinesConfig {
  /**
   * Value to search finelines by - skus
   */
  @Field({
    nullable: true,
    description: `Value to search finelines by`,
  })
  search?: string;

  @Field(() => Int, {
    nullable: true,
    description: `Amount of finelines to return`,
  })
  limit?: number;

  @Field(() => Int, {
    nullable: true,
    description: `Where in the list to search from`,
  })
  offset?: number;

  @Field(() => Boolean, {
    nullable: true,
    description: `Returns only unlinked finelines`,
  })
  unlinked?: boolean;

  @Field(() => [String], {
    nullable: "itemsAndList",
    description: `Returns finelines with selected ids`,
  })
  withIds?: string[];

  @Field(() => [String], {
    nullable: "itemsAndList",
    description: `Returns finelines with category ids`,
  })
  withCategoryIds?: string[];

  @Field(() => [String], {
    nullable: "itemsAndList",
    description: `Returns finelines with selected relations`,
  })
  withRelations?: Array<"featuredProducts" | "newProducts">;
}
