/**
 * @packageDocumentation
 * @module Stores
 */

import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

@ObjectType({ isAbstract: true })
export class RegionBase extends EntityBase<RegionBase> {
  @Field((type) => Int)
  id: number;
  @Field((type) => String)
  regionName: string;
  @Field((type) => Boolean)
  defaultInMedia: boolean;
}

@ObjectType("Region", {})
export class Region extends RegionBase implements Entity<RegionBase> {
  toObject() {
    return null;
  }
}

@InputType()
export class RegionInput extends OmitType(Region, [], InputType) {}
