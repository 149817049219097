import { InputType, OmitType, Field } from "@nestjs/graphql";
import { CartBase } from "../cart.model";
import { CartItemInput } from "../../cart-item/graphql/cart-item-input.schema";
import { CartItem } from "../../cart-item/cart-item.model";

@InputType()
export class CartInput extends OmitType(
  CartBase,
  ["items"] as const,
  InputType
) {
  @Field(() => [CartItemInput])
  items?: CartItem[];
}
