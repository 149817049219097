import {
  Field,
  InputType,
  Int,
  ObjectType,
  OmitType,
  registerEnumType,
} from "@nestjs/graphql";
import { WoosyncBase } from "../woosync.model";

export enum SQSQueue {
  WOOSYNC_PROCESSOR = "WOOSYNC_PROCESSOR",
  WOOSYNC_WORKER = "WOOSYNC_WORKER",
}

registerEnumType(SQSQueue, {
  name: "SQSQueue",
  description: "Current queues supported by our AWS SQS infrastructure",
});

@ObjectType()
export class QueueCount {
  @Field(() => Int, { nullable: true })
  worker: number;
  @Field(() => Int, { nullable: true })
  processor: number;
}

@InputType()
export class PurgeQueueParams {
  @Field((type) => [SQSQueue])
  queues: SQSQueue[];
}

@InputType()
export class WoosyncInput extends OmitType(
  WoosyncBase,
  [] as const,
  InputType
) {}
