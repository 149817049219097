import {
  Field,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { Entity, EntityBase } from "../../interfaces";
import { AdBlock, AdBlockInput } from "./";

@ObjectType({ isAbstract: true })
export class AdPageBase extends EntityBase<AdPageBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field((type) => Int, { nullable: true })
  pageNumber: number;

  @Field({ nullable: true })
  pageName: string;

  @Field((type) => Int, { nullable: true })
  mediaId: number;

  @Field((type) => [AdBlock], { nullable: "itemsAndList" })
  blocks?: AdBlock[];

  constructor(props: AdPageBase) {
    super(props);
    this.blocks = props.blocks ? props.blocks.map((p) => new AdBlock(p)) : null;
  }
}

@ObjectType("AdPage", {})
export class AdPage extends AdPageBase implements Entity<AdPageBase> {
  @HideField()
  get projections() {
    return this.blocks.reduce(
      ({ unitsSold, sales, totalCost }, block) => {
        const projections = block.projections;

        unitsSold = currency(unitsSold).add(projections.unitsSold ?? 0).value;
        sales = currency(sales).add(projections.sales ?? 0).value;
        totalCost = currency(totalCost).add(projections.totalCost ?? 0).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }

  @HideField()
  get recap() {
    return this.blocks.reduce(
      ({ unitsSold, sales, totalCost }, block) => {
        const { unitsSold: _unitsSold, revenue, totalCost: _totalCost } = block;

        unitsSold = currency(unitsSold).add(_unitsSold ?? 0).value;
        sales = currency(sales).add(revenue ?? 0).value;
        totalCost = currency(totalCost).add(_totalCost ?? 0).value;

        return { unitsSold, sales, totalCost };
      },
      {
        unitsSold: 0,
        sales: 0,
        totalCost: 0,
      }
    );
  }

  toObject() {
    return null;
  }
}
@InputType()
export class AdPageInput extends OmitType(
  AdPage,
  ["blocks"] as const,
  InputType
) {
  @Field(() => [AdBlockInput])
  blocks?: [AdBlockInput];
}
