import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces/entity.interface";
import GraphQLJSON from "graphql-type-json";

/** @ignore */
export enum SPECIALTY_DISCOUNT_METHOD {
  /**
   * Percentage markup from the Replacement Cost
   */
  PERCENT_MARKUP_FROM_COST = "B",

  /**
   * Percentage markdwon from the Retail Price
   */
  PERCENT_MARKDOWN_FROM_RETAIL = "D",

  /**
   * Dollars off from Total
   */
  DOLLARS_OFF_FROM_TOTAL = "n",

  /**
   * Dollars off from Retail Price
   */
  DOLLARS_OFF_FROM_RETAIL = "*",
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class SpecialtyDiscountBase extends EntityBase<SpecialtyDiscountBase> {
  /**
   * Unique ID for this SPD
   */
  id: number;

  /**
   * The category relates to the value of the `type`.
   *
   * For example if `type = Department` and `category = 24` then
   * this SPD refers to Department 24.
   */
  category: string;

  /**
   * The type of SPD is used along with `category` to identify what
   * Item(s) this discount applies to.
   *
   * For example if `type = Department` and `category = 24` then
   * this SPD refers to Department 24.
   */
  @Field((type) => String)
  type: "Class" | "Department" | "SKU" | "Fineline";

  /**
   * Description of this SPD, typically the name of the category/type it
   * targets - e.g. Department 24 has a `description = PET SUPPLIES`
   */
  description: string;
  @Field((type) => String)

  /**
   * The discounting method used with this SPD.
   */
  method: SPECIALTY_DISCOUNT_METHOD;

  /**
   * The amount that is aplpied with this SPD.
   *
   * @remarks In the database this is `price_percent` or `price_amount`
   */
  amount: number;

  /**
   * SPDs apply only to certain category plans (that Customer's belong to)
   */
  categoryPlan: string;
}

/**
 */
@ObjectType()
export class SpecialtyDiscount
  extends SpecialtyDiscountBase
  implements Entity<SpecialtyDiscountBase>
{
  toObject() {
    return null;
  }

  /**
   * Calculates the discounted unit price of the item.
   *
   * @param params
   * @returns discounted unitPrice
   */
  calculateDiscount({ regularPrice, replacementCost, quantity }) {
    switch (this.method) {
      case SPECIALTY_DISCOUNT_METHOD.DOLLARS_OFF_FROM_RETAIL:
        return regularPrice - this.amount;
      case SPECIALTY_DISCOUNT_METHOD.DOLLARS_OFF_FROM_TOTAL:
        return (regularPrice * quantity - this.amount) / quantity;
      case SPECIALTY_DISCOUNT_METHOD.PERCENT_MARKDOWN_FROM_RETAIL:
        return regularPrice * (1 - this.amount / 100);
      case SPECIALTY_DISCOUNT_METHOD.PERCENT_MARKUP_FROM_COST:
        return replacementCost * (1 + this.amount / 100);
    }
  }
}
