export interface Entity<T> {
  toObject(): T;
}

export abstract class EntityBase<T> {
  /**
   * @experimental
   *
   * Will return a snapshot of the Entity with all getters/functions evaluated.
   *
   * It doesn't work with functions though ¯\\\_(ツ)\_/¯
   */
  toSnapshot?: () => any;

  constructor(props: Partial<T>) {
    Object.assign(this, props);

    this.toSnapshot = () => {
      const originalClass = this;
      const keys = Object.getOwnPropertyNames(
        Object.getPrototypeOf(originalClass)
      );
      return {
        ...this,
        ...keys.reduce((classAsObj, key) => {
          if (typeof originalClass[key] === "function") {
            classAsObj[key] = originalClass[key].bind(classAsObj);
          } else {
            classAsObj[key] = originalClass[key];
          }
          return classAsObj;
        }, {}),
      };
    };
  }
}
