/**
 * Metadata used for the number of items that were sold per month.
 *
 * @category Value Object
 */
export interface ItemSalesVelocityData {
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}
