import { Field, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../../interfaces/entity.interface";
import { Product } from "../../../products/product/product.model";

export interface ColumnDefinitions {
  description?: string;
  width?: number;
  backgroundImage?: string;
  verticalAlignment?: string;
  horizontalAlignment?: string;
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class DisplaySceneColumnBase extends EntityBase<DisplaySceneColumnBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Sorted position of the column
   */
  @Field((type) => Int, { nullable: true })
  position: number;

  /**
   * The name of the column.
   */
  @Field({ nullable: true })
  title: string;

  /**
   * Dimensions of a scene
   */
  @Field(() => GraphQLJSON, { nullable: true })
  definitions?: ColumnDefinitions[];

  /**
   * Products associated with the column
   */
  @Field(() => [Product], { nullable: "itemsAndList" })
  products?: Product[]; // Ideally this would be more generic

  /**
   * Snapshot of mutable product fields that can be updated via the scene's
   * app. We store them here so that way we can receive updates from item
   * stock/quantity/pricing and still maintain custom titles/descriptions
   */
  @Field(() => GraphQLJSON, { nullable: true })
  productSnapshot?: Partial<Product>[];

  constructor(props: DisplaySceneColumnBase) {
    super(props);
    this.products = props?.products?.map((i) => new Product(i)) ?? [];
  }
}

/**
 * A Scene is a config that determine the dimensional information of how a display will
 * represent data.
 */
@ObjectType("DisplaySceneColumn", {})
export class DisplaySceneColumn
  extends DisplaySceneColumnBase
  implements Entity<DisplaySceneColumnBase>
{
  getProductsView(storeNumber: number) {
    const productView = this.productSnapshot.reduce((_products, snapshot) => {
      const product = this.products.find((p) => p.id === snapshot.id);

      if (!product || product.items.length === 0) return _products;

      let retailPrice = null;
      let promotionPrice = null;

      /**
       * Find the first item that has quantity available for this store and use its
       * retail/promotion pricing
       */
      for (let _item of snapshot.items) {
        const item = product.items.find((item) => item.id === _item.id);

        if (!item) continue;

        // If we have already found a price, use that one
        if (!!retailPrice) continue;

        // Otherwise, search for the first item that has quantity available
        const quantityAvailable = item.locationData
          ? item.locationData[`quantityAvailable${storeNumber}`]
          : null;

        if (!quantityAvailable) continue;

        retailPrice = item.locationData[`retailPrice${storeNumber}`];
        promotionPrice = item.locationData[`promotionPrice${storeNumber}`];
      }

      return [..._products, { ...snapshot, retailPrice, promotionPrice }];
    }, []);

    return productView;
  }

  toObject() {
    return {
      id: this.id,
      position: this.position,
      title: this.title,
      definitions: this.definitions,
    };
  }
}
