import { Field, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/**
 * Not to be confused with ecom users, these are users that are
 * authenticated via the Auth0 api - Back40
 */
@ObjectType({ isAbstract: true })
export class AuthUserBase extends EntityBase<AuthUserBase> {
  @Field((type) => String)
  id: string;
  @Field((type) => String, { nullable: true })
  name: string;
  @Field((type) => String, { nullable: true })
  nickname: string;
  @Field((type) => String, { nullable: true })
  userName: string;
  @Field((type) => String, { nullable: true })
  email: string;
  @Field((type) => String, { nullable: true })
  icon: string;
}

@ObjectType()
export class AuthUser extends AuthUserBase implements Entity<AuthUserBase> {
  toObject() {
    return null;
  }
}
