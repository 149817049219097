import { Field, ID, InputType, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces/entity.interface";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ItemSizeBase extends EntityBase<ItemSizeBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the ItemSize.
   *
   * This is public facing and will be shown on Ecom.
   */
  @Field({ nullable: true })
  name: string;
}

/**
 * A ItemSize is one of two (along with {@link ItemColor}) attributes that is used to differentiate an {@link Item} in a {@link Product}
 *
 * A {@link Product} can (optionally) be associated with an ItemSize.
 */
@ObjectType("ItemSize", {})
export class ItemSize extends ItemSizeBase implements Entity<ItemSizeBase> {
  toObject() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
