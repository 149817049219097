import createEnum from "../utils/create-enum";

const TEMPLATES = {
  ORDER_RECEIVED: "Order Received",
  ORDER_SHIPPED: "Order Shipped (Full)",
  ORDER_SHIPPED_PARTIAL: "Order Shipped (Partial)",
  ORDER_CANCELLED: "Order Cancelled",
  ORDER_PICKED_UP: "Order Picked Up",
  ORDER_AWAITING_PICKUP: "Order Awaiting Pickup",
  PICKUP_READY_FULL: "Pickup Ready (Full)",
  PICKUP_READY_PARTIAL: "Pickup Ready (Partial)",
  REQUIRES_APPROVAL: "Refund Requires Approval",
} as const;

export const [EmailTemplateEnum, emailTemplates] = createEnum(TEMPLATES);

export const getEmailTemplateKey = (template: string) =>
  `email_${template.toLowerCase().replace(/ /g, "_").replace(/\(|\)/g, "")}`;
