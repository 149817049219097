import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";

export type JobTypes = "NEEDS_PROCESSING" | "DAILY" | "CONTINUOUS";

/**
 * Model for CronJobs that need to be run every minute
 */
@ObjectType()
export class CronJob {
  @Field((type) => Int)
  id: number;

  /**
   * Entity that needs to run a job
   */
  entity: string;

  /**
   * Entity's id
   */
  @Field((type) => Int)
  entityId: number;

  /**
   * Type of job to be run
   *
   * Daily - Occurs once every day
   *
   * Needs Processing - Occurs once when sendAfter <= currentTime
   *
   * Continuous - Occurs every cron cycle
   */
  @Field(() => GraphQLJSON)
  type: JobTypes;

  /**
   * Rmq exchange information
   */
  exchange: string;

  /**
   * Rmq routing information
   */
  routingKey: string;

  /**
   * Actual payload to be received by the queue
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  payload: Object;

  /**
   * The time the job should be expected to run (Format: 'YYYY/MM/DD HH:mm:ss')
   */
  sendAfter: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

@InputType()
export class CronJobInput extends OmitType(CronJob, [] as const, InputType) {}
