import { Field, InputType, OmitType } from "@nestjs/graphql";
import { BrandInput } from "../../brand/graphql/brand-input.graphql";
import { CategoryInput } from "../../category/graphql/category-input.schema";
import { ChemicalInput } from "../../chemical/graphql/chemical-input.model";
import { ItemInput } from "../../item/graphql/item-input.schema";
import { ProductWarningInput } from "../../product-warning/graphql/product-warning-input.model";
import { SpeciesInput } from "../../species/graphql/species-input.schema";
import { WoosyncInput } from "../../woosync/graphql/woosync-input.schema";
import { ProductBase } from "../product.model";

/** @ignore */
@InputType()
export class ProductInput extends OmitType(
  ProductBase,
  [
    "items",
    "children",
    "brand",
    "species",
    "relatedCategories",
    "categories",
    "relatedProducts",
    "chemicals",
    "productWarnings",
    "prop65Warning",
    "woosync",
  ] as const,
  InputType
) {
  @Field(() => [ItemInput], { nullable: "itemsAndList" })
  items?: ItemInput[];
  @Field(() => [SpeciesInput], { nullable: "itemsAndList" })
  species?: SpeciesInput[];
  @Field(() => [ProductWarningInput], { nullable: "itemsAndList" })
  productWarnings?: ProductWarningInput[];
  @Field(() => ProductWarningInput, { nullable: true })
  prop65Warning?: ProductWarningInput;
  @Field(() => BrandInput, { nullable: true })
  brand?: BrandInput;
  @Field(() => [CategoryInput], { nullable: "itemsAndList" })
  categories?: CategoryInput[];
  @Field(() => [ProductInput], { nullable: "itemsAndList" })
  relatedProducts?: ProductInput[];
  @Field(() => [ChemicalInput], { nullable: "itemsAndList" })
  chemicals?: ChemicalInput[];
  @Field(() => WoosyncInput, { nullable: true })
  woosync?: WoosyncInput;
}
