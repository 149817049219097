import { Field, InputType, OmitType } from '@nestjs/graphql';
import { GraphQLJSON } from 'graphql-type-json';

import { QuantityBreak, QuantityBreakBase } from '../quantity-break.model';

@InputType()
export class QuantityBreakInput extends OmitType(
  QuantityBreakBase,
  [] as const,
  InputType
) {
  @Field((type) => GraphQLJSON)
  discounts: QuantityBreak["discounts"];
}
