import {
  Field,
  Float,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";
import { Criteria } from "../../interfaces/criteria.interface";
import { DomainError } from "../../interfaces/domain-error.interface";
import { AdItem, AdQuantityBreak, AdQuantityBreakInput } from "./";
import { AdErrorType } from "./ad-item/vo/ad-error.model";
import { AdSignage, AdSignageInput } from "./ad-signage.model";

@ObjectType({ isAbstract: true })
export class AdAssortmentBase extends EntityBase<AdAssortmentBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  @Field((type) => Int, { nullable: true })
  blockId: number;
  @Field((type) => Int)
  position?: number;

  @Field({ nullable: true })
  saleType: string;
  @Field({ nullable: true })
  saleTypeSecondary: string;
  @Field({ nullable: true })
  designNotes: string;

  // Floats
  @Field({ nullable: true })
  saleAmount: number;
  @Field({ nullable: true })
  projectedEarnings: number;

  @Field({ nullable: true })
  status: string;
  @Field({ nullable: true })
  productSelection: string;
  @Field((type) => Int, { nullable: true })
  projection: number;
  @Field(() => Float, { nullable: true })
  projectedCost: number;
  @Field((type) => Int, { nullable: true })
  totalProducts: number;
  @Field((type) => Int, { nullable: true })
  totalSaleCost: number;
  @Field((type) => Int, { nullable: true })
  projectionLift: number;
  @Field({ nullable: true })
  staticProjectionLift: boolean;
  @Field((type) => Int, { nullable: true })
  saleCostLift: number;
  @Field({ nullable: true })
  configured: boolean;
  @Field({ nullable: true })
  assortmentName: string;

  @Field((type) => GraphQLJSON, { nullable: true })
  errors: DomainError<AdErrorType>[];

  @Field({ nullable: true })
  includeDiscontinued: boolean;
  @Field({ nullable: true })
  includeDeadSkus: boolean;
  @Field({ nullable: true })
  includeSpecialOrderSkus: boolean;
  @Field((type) => GraphQLJSON, { nullable: true })
  snapshot: { title: string; skus: string };
  @Field({ nullable: true })
  requiresMarkdown: boolean;
  @Field({ nullable: true })
  requiresSpecialHandling: boolean;
  @Field((type) => Int, { nullable: true })
  bogoGetQuantity: number;
  @Field((type) => Int, { nullable: true })
  bogoBuyQuantity: number;
  @Field({ nullable: true })
  bogoBuyApplication: string;
  @Field({ nullable: true })
  bogoGetApplication: string;
  @Field({ nullable: true })
  bogoSpecificSku: string;
  @Field({ nullable: true })
  needsProcessing: boolean;
  @Field({ nullable: true })
  processBy: string;

  @Field((type) => [AdQuantityBreak], { nullable: "itemsAndList" })
  quantityBreaks?: AdQuantityBreak[];

  @Field((type) => [AdSignage], { nullable: "itemsAndList" })
  signages?: AdSignage[];

  @Field((type) => [AdItem], { nullable: "itemsAndList" })
  adItems?: AdItem[];

  @Field((type) => GraphQLJSON, { nullable: true })
  criteria: Criteria;

  constructor(props) {
    super(props);

    this.signages = props.signages
      ? props.signages.map((sign) => new AdSignage(sign))
      : [];
  }
}

@ObjectType("AdAssortment", {})
export class AdAssortment
  extends AdAssortmentBase
  implements Entity<AdAssortmentBase>
{
  @Field(() => GraphQLJSON, { nullable: true })
  determineSaleType() {
    if (!this.saleType) return null;

    switch (this.saleType) {
      case "fixed":
        return `$${this.saleAmount.toFixed(2)}`;
      case "Quantity Break":
      case "BOGO":
        return this.saleType;
      default:
        return this.saleType && this.saleType.split(" ")[0] === "$"
          ? `$${this.saleAmount.toFixed(2)} off`
          : `${this.saleAmount.toFixed(2)}% off`;
    }
  }

  private _formatDollarValue(saleAmount: number) {
    const CENT_SYMBOL = "\xA2";
    let prefix,
      accessory,
      price,
      suffix = "";
    const [dollar, cents] = saleAmount
      ? saleAmount.toFixed(2).split(".")
      : ["0", "0"];
    const hasDollarSign = dollar ? +dollar >= 1 : false;
    const hasCents = cents ? +cents > 0 : false;
    const hasCentSymbol = !hasDollarSign && hasCents;

    accessory = hasDollarSign ? "$" : "";
    price = hasDollarSign ? dollar : cents;
    suffix = hasCents && !hasCentSymbol ? cents : suffix;

    if (hasCents) {
      suffix = hasCentSymbol ? CENT_SYMBOL : cents;
    }

    return { prefix, accessory, price, suffix };
  }

  formatPriceToken() {
    let prefix,
      accessory,
      price,
      suffix = "";

    switch (this.saleType) {
      case "$ off":
        accessory = "$";
        price = `${Math.trunc(this.saleAmount)} Off`;
        break;
      case "fixed":
        return this._formatDollarValue(this.saleAmount);
      case "% off":
        price = `${Math.trunc(this.saleAmount)}% Off`;
        break;
      case "BOGO":
        price = `Buy ${this.bogoBuyQuantity}, Get ${this.bogoGetQuantity} Free`;
        break;
      case "Quantity Break":
        if (this.quantityBreaks && this.quantityBreaks.length > 0) {
          const { quantityBreakPoint, qbSaleAmount, qbSaleType } =
            this.quantityBreaks[0];

          if (qbSaleType === "fixed" || qbSaleType === "$ off") {
            const value = this._formatDollarValue(qbSaleAmount);
            prefix = `${quantityBreakPoint}/`;
            accessory = `$`;
            price = `${value.price}`;
            suffix = value.suffix;
          } else {
            price = `${quantityBreakPoint}/${Math.trunc(qbSaleAmount)}% Off`;
          }
          break;
        }
      default:
        price = this.determineSaleType();
    }

    return { prefix, accessory, price, suffix };
  }

  toObject() {
    return null;
  }
}

@InputType()
export class AdAssortmentInput extends OmitType(
  AdAssortment,
  ["quantityBreaks", "adItems", "signages"] as const,
  InputType
) {
  @Field(() => [AdQuantityBreakInput])
  quantityBreaks?: [AdQuantityBreakInput];
  @Field(() => [AdSignageInput])
  signages?: [AdSignageInput];
}
