import { InputType, OmitType, Field } from "@nestjs/graphql";
import { SpeciesBase } from "../species.model";

/** @ignore */
@InputType()
export class SpeciesInput extends OmitType(
  SpeciesBase,
  [] as const,
  InputType
) {}
