import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class EcomImageBase extends EntityBase<EcomImageBase> {
  /**
   * The ID is of the media on Ecom
   */
  id: number;

  /**
   * This is the ID of the Customer account
   */
  @Field((type) => String)
  image: string;

  /**
   * This is the ID of the Customer account
   */
  @Field((type) => String, { nullable: true })
  thumbnail: string;

  /**
   * This is the ID of the Customer account
   */
  @Field((type) => String, { nullable: true })
  alt: string;
}

/**
 * An image that exists in the WP media library
 */
@ObjectType()
export class EcomImage extends EcomImageBase implements Entity<EcomImageBase> {
  /**
   * For backwards compatibility
   *
   * @see EcomImageBase.id
   */
  @Field((type) => Int)
  get ecomId() {
    return this.id;
  }

  toObject() {
    return null;
  }
}
