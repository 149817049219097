import { EmailButtonBlock } from "./button-block.def";
import { EmailDividerBlock } from "./divider-block.def";
import { EmailImageBlock } from "./image-block.def";
import { EmailOrderTableBlock } from "./order-table.def";
import { EmailQRCode } from "./qr-code-block.def";
import { EmailSocialBlock } from "./social-block.def";
import { EmailSpacerBlock } from "./spacer-block.def";
import { EmailTextBlock } from "./text-block.def";

export * from "./button-block.def";
export * from "./divider-block.def";
export * from "./image-block.def";
export * from "./order-table.def";
export * from "./qr-code-block.def";
export * from "./social-block.def";
export * from "./spacer-block.def";
export * from "./text-block.def";

export type TDefaultBlock =
  | EmailButtonBlock
  | EmailDividerBlock
  | EmailImageBlock
  | EmailSocialBlock
  | EmailSpacerBlock
  | EmailTextBlock
  | EmailQRCode;

export type TDynamicBlock = EmailOrderTableBlock;
export type TEmailBlocks = TDefaultBlock | TDynamicBlock;

export const isDynamicBlock = (
  block: TDynamicBlock | TDefaultBlock
): block is TDynamicBlock => {
  return (block as TDynamicBlock).dynamicOptions !== undefined;
};
