/**
 * Publish settings
 *
 * @category Value Object
 */
export interface ProductPublishSettings {
  /**
   * What the `publish` status of the {@link Product} should be set to.
   */
  publish: boolean;

  /**
   * Effective immediately or on a date
   */
  effectiveOn: string;

  /**
   * DateTime the change should be made.
   */
  effectiveDate: string;
}
