import { Field, Int, ObjectType } from "@nestjs/graphql";
import { DomainResponse, ResponseStatus } from "../../../../interfaces";

@ObjectType()
export class CreateColorResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => Int, { nullable: true })
  result?: number;

  @Field(() => String)
  message: string;

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}

@ObjectType()
export class UpdateColorResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => Int, { nullable: true })
  result?: number;

  @Field(() => String)
  message: string;

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}

@ObjectType()
export class DeleteColorResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => Int, { nullable: true })
  result?: number;

  @Field(() => String)
  message: string;

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}
