/**
 * @packageDocumentation
 * @module StoresAnalytics
 */
import { Field, Int, ObjectType } from "@nestjs/graphql";
import { EntityBase } from "../../../interfaces";

export enum Period {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YTD = "YTD",
}

@ObjectType({ isAbstract: true })
export class StoreAnalyticsBase extends EntityBase<StoreAnalyticsBase> {
  @Field((type) => Int)
  id: number;
  @Field({ nullable: true })
  name: string;

  /**
   * Number of processed orders for this store today
   */
  @Field((type) => Int, { nullable: true })
  fulfilledOrders?: number;

  /**
   * A Stores average fulfillment time - (Daily average)
   */
  @Field((type) => Int, { nullable: true })
  averageFulfillment?: number;

  /**
   * Number of packages that are pending for a store
   */
  @Field((type) => Int, { nullable: true })
  pending?: number;

  /**
   * Number of packages that are processed for a store
   */
  @Field((type) => Int, { nullable: true })
  customerReady?: number;

  /**
   * Number of packages that need attention
   */
  @Field((type) => Int, { nullable: true })
  needsAttention?: number;
}

@ObjectType()
export class StoreAnalytics
  extends StoreAnalyticsBase
  implements EntityBase<StoreAnalyticsBase> {}
