import { Field, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { DateTime, Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class DeliveryBase extends EntityBase<DeliveryBase> {
  id: number;

  @Field((type) => String)
  status: "pending" | "processing" | "processed" | "completed";

  /**
   * The DateTime this Delivery is scheduled for delivery.
   */
  @Field((type) => String)
  scheduledFor: DateTime;

  /**
   * The amount of time (in minutes) is needed to unload this Delivery.
   *
   * Some deliveries may take longer than others due to their size, complexity, or requirements to "give the Customer a hand"
   */
  unloadTime: number;

  /**
   * The amount of capacity (in percentage) of the Truck is required for the Delivery.
   *
   * A Truck must have `capacity + capacityRequired <= 100`.
   */
  capacityRequired: number;

  /**
   * The OrderItems that are included in this Delivery.
   */
  @Field((type) => GraphQLJSON)
  deliveryItems: {
    title: string;
    quantity: number;
    itemNumber: string;
  }[];

  @Field((type) => GraphQLJSON)
  meta: any;

  @Field((type) => String)
  processingAt: DateTime;

  @Field((type) => String)
  processedAt: DateTime;

  @Field((type) => String)
  completedAt: DateTime;

  @Field((type) => String)
  createdAt: DateTime;

  @Field((type) => String)
  updatedAt: DateTime;
}

/**
 * A Delivery is a scheduled delivery job for a {@link Package} with a Delivery fulfillment method.
 *
 * A {@link Package} may have one or more scheduled Deliveries.
 */
@ObjectType()
export class Delivery extends DeliveryBase implements Entity<DeliveryBase> {
  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }
}
