import { Field, ObjectType } from "@nestjs/graphql";

@ObjectType()
export class ExportOrder {
  @Field((type) => String)
  orderNumber: string;

  @Field((type) => String)
  customerName: string;

  @Field((type) => String)
  orderDate: string;

  @Field({ nullable: true })
  orderTotal: string;

  @Field((type) => String)
  docId: string;
}
