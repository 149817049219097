import {
  Field,
  HideField,
  Int,
  ObjectType,
  registerEnumType,
} from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";

import { Entity, EntityBase, Image } from "../../../interfaces";
import { Brand } from "../brand/brand.model";
import { Category } from "../category/category.model";
import { Chemical } from "../chemical/chemical.model";
import { Item } from "../item/item.model";
import { ProductWarning } from "../product-warning/product-warning.model";
import { Species } from "../species/species.model";
import { Woosync } from "../woosync/woosync.model";
import { ProductImageDefault } from "./vo/product-image-default.model";
import { ProductPublishSettings } from "./vo/product-publish-settings.model";

/** @ignore */
export enum DeliveryAvailable {
  NOT_AVAILABLE = 0,
  DELIVER_WITH_OTHERS = 1,
  INHERIT_FROM_FINELINE = 2,
  AVAILABLE = 3,
}

/** @ignore */
export enum RequiresFraudCheck {
  NO,
  INHERIT_FROM_FINELINE,
  YES,
}

/** @ignore */
export enum Prop65Warning {
  SAFE_HARBOR = "prop65_safe_harbor_message",
  CHEMICALS_SPECIFIED = "prop65_chemicals_specified_message",
  INHALENT = "prop65_inhalent_message",
}

/** @ignore */
export enum Gender {
  FEMALE = "female",
  MALE = "male",
  UNISEX = "unisex",
}

registerEnumType(DeliveryAvailable, {
  name: "DeliveryAvailable",
  description: "Current Delivery values",
});

registerEnumType(RequiresFraudCheck, {
  name: "RequiresFraudCheck",
  description: "Current Fraud check values",
});

registerEnumType(Gender, {
  name: "Gender",
  description: "Gender product is designed for",
});

/** @ignore */
@ObjectType({ isAbstract: true })
export class ProductBase extends EntityBase<ProductBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Title of the **Product** to be used on Farmstore and Kiosk
   */
  @Field({ nullable: true })
  title: string;

  /**
   * Description (long form) of the **Product** to be used on Farmstore.
   */
  @Field({ nullable: true })
  description: string;

  @Field(() => Gender, { nullable: true })
  gender: Gender;

  /**
   * If `true`, the price of this **Product** is display online on Farmstore.
   *
   * If `false`, the price is hidden until added to the cart.
   *
   * @defaultValue true
   */
  @Field({ nullable: true })
  hidePricingOnline: boolean;

  /**
   * If `true`, this **Product** is not sold online and will instead display an "Available in-store only" message.
   *
   * If `false`, it can be purchase online.
   *
   * @defaultValue false
   */
  @Field({ nullable: true })
  notSoldOnline: boolean;

  /**
   * If `true`, this **Product** is only eligible for Pickup and not for Ship To Home or Delivery.
   *
   * If `false`, this **Product** is eligible for all fulfillment methods.
   *
   * @defaultValue false
   */
  @Field({ nullable: true })
  pickupOnly: boolean;

  /**
   * Controls whether this **Product** is available for Delivery.
   *
   * This overwrites (if `NOT_AVAILABLE` or `AVAILABLE`) the setting inherited from the {@link Fineline}.
   *
   * @defaultValue 1 or `DeliveryAvailable.INHERIT_FROM_FINELINE`
   */
  @Field(() => Int, { nullable: true })
  deliveryAvailable: DeliveryAvailable;

  /**
   * Determines whether the product requires extra handling
   */
  @Field({ nullable: true })
  deliveryExtraHandling: boolean;

  /**
   * Controls whether this **Product** is part of a global promotion.
   *
   * This is a rarely (if ever) used feature that adds a graphic over the thumbnail of the image on Farmstore.
   *
   * @defaultValue false
   */
  @Field({ nullable: true })
  globalPromotion: boolean;

  /**
   * Controls whether this **Product** is featured on the homepage of Farmstore.
   *
   * @defaultValue false
   */
  @Field({ nullable: true })
  featured: boolean;

  /**
   * If set, the *System* will change the `featured` flag to `true` on this date.
   */
  @Field({ nullable: true })
  featuredStartDate: string;

  /**
   * If set, the *System* will change the `featured` flag to `false` on this date.
   */
  @Field({ nullable: true })
  featuredEndDate: string;

  /**
   * The position in which the featured Product shows up in the featured products list
   */
  @Field(() => Int, { nullable: true })
  featuredPosition: number;

  /**
   * If set, Farmstore will display an additional tab alongside the `description` that displays the content.
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  customTab: {
    title: string;
    content: string;
    video: string;
  };

  /**
   * Controls the level at which this **Product** (more specifically the selected **Item**) will display as "limited stock" on Farmstore
   *
   * @defaultValue 1
   */
  @Field(() => Int, { nullable: true })
  limitedStockQuantity: number;

  /**
   * If set, enforces the max number of an **Item** that can be purchased on Farmstore.
   *
   * @defaultValue null
   */
  @Field(() => Int, { nullable: true })
  purchaseLimit: number;

  /**
   * Controls whether this **Product** requires a manual fraud check before fulfillment, this is used with "high risk" products.
   *
   * This overwrites (if `YES` or `NO`) the setting inherited from the {@link Fineline}.
   *
   * @defaultValue 1 or `RequiresFraudCheck.INHERIT_FROM_FINELINE`
   */
  @Field(() => Int, { nullable: true })
  requiresFraudCheck: RequiresFraudCheck;

  /**
   * Configures whether a Prop65 warning is displayed on Farmstore.
   *
   * There are three warning types (use the `Prop65Warning` enum), only `CHEMICALS_SPECIFIED` requires additional input (via `chemicals`).
   *
   * @remarks
   * If `prop65Warning` is `CHEMICALS_SPECIFIED`, a **Product** is *not sellable online* in California *unless* the `chemicals` have been configured.
   *
   * @remarks
   * If any of the {@link Item} has `masterData.codeA1 = 'Y'` then the `prop65Warning` *must be* `CHEMICALS_SPECIFIED`.
   */
  @Field(() => String, { nullable: true })
  prop65WarningType: Prop65Warning;

  /**
   * Controls whether this **Product** can be published on Farmstore.
   *
   * If `true`, then publish to Farmstore.
   *
   * @defaultValue true
   */
  @Field({ nullable: true })
  publish: boolean;

  /**
   * Allows the `publish` status to be changed by the **System** on a given date.
   *
   * @deafultValue null
   */
  @Field(() => GraphQLJSON, { nullable: true })
  publishSettings: ProductPublishSettings;

  /**
   * An optional association with an Avatax Tax Code.
   *
   * @remarks
   * If this is set, it takes priority when syncing Products to Ecom. If it's not set, we fall back to the {@link Fineline} `taxCode`
   */
  @Field({ nullable: true })
  taxCode: string;

  @Field({ nullable: true })
  createdAt: string;
  @Field({ nullable: true })
  updatedAt: string;

  @Field(() => [Item], { nullable: "itemsAndList" })
  items?: Item[];

  /**
   * The primary images for this **Product**, the first of which will be the featured image on Farmstore.
   * The rest of the images will be part of the image gallery.
   */
  @Field(() => GraphQLJSON, { nullable: true })
  images: Image[];

  /**
   * Assign default images for {@link Item} on Farmstore.
   *
   * This saves having to upload the same image repeatedly for **Item** that are visibly the same (e.g. same color, different sizes).
   */
  @Field(() => GraphQLJSON, { nullable: true })
  imageDefaults: ProductImageDefault[];

  /**
   * **Product** highlights, displayed as bullet points on Farmstore and Kiosk.
   */
  @Field(() => GraphQLJSON, { nullable: true })
  highlights: Array<string>;

  /**
   * Optional keywords to add to this Product for use in searches on Farmstore.
   *
   * @defaultValue null
   */
  @Field(() => GraphQLJSON, { nullable: true })
  keywords: Array<string>;

  /**
   * A list of states (abbreviations) that this **Product** is excluded for sale in.
   *
   * For example, if `excludedStates = ['CA']` then the **Product** cannot be sold in California.
   *
   * @defaultValue null
   */
  @Field(() => GraphQLJSON, { nullable: true })
  excludedStates: Array<string>;

  /** @deprecated Ubiquitous Language updated to `Item`, use the `items` property instead. */
  @HideField()
  children?: void;

  @Field((type) => Brand, { nullable: true })
  brand?: Brand;

  @Field((type) => [Species], { nullable: "itemsAndList" })
  species?: Species[];

  @Field((type) => [Category], { nullable: "itemsAndList" })
  relatedCategories?: Category[];

  /**
   * All {@link Category} that this Product is associated with.
   *
   * This comes from two different sources:
   * 1) The Category associated with each {@link Fineline} from the {@link Item}s belonging to this Product
   * 2) The "related categories" of the Product set in Back40
   *
   * @readonly
   */
  @Field((type) => [Category], { nullable: "itemsAndList" })
  categories?: Category[];

  @Field((type) => Woosync, { nullable: true })
  woosync?: Woosync;

  @Field((type) => ProductWarning, { nullable: true })
  prop65Warning?: ProductWarning;

  @Field((type) => [ProductWarning], { nullable: "itemsAndList" })
  productWarnings?: ProductWarning[];

  @Field((type) => [Product], { nullable: "itemsAndList" })
  relatedProducts?: Product[];

  @Field((type) => [Chemical], { nullable: "itemsAndList" })
  chemicals?: Chemical[];

  /** @internal */
  @Field((type) => Int, { nullable: true })
  _brandId: number;

  constructor(props: ProductBase) {
    super(props);
    this.items = props.items.map((i) => new Item(i));
  }
}

/**
 * A **Product** is a collection of one-or-more **{@link Item}**, with each **Item** being differentiated by its **{@link Size}** and/or **{@link Color}**.
 * A **Product** is not sold directly as it does not have a *SKU* (or *Item Number*).
 *
 * An example of this could be a Jacket (**Product**) that comes in a number of different sizes and colors (**Item**).
 *
 * We use a **Product** to group together **Item** and define information about it to be used on Farmstore (Ecom) and Kiosk.
 * This includes information such as a title, description, highlights, and a number of configurable options about how it is sold.
 *
 * A **Product** with only one **Item** would be published to Farmstore as a *Simple Product*, while a **Product** with two or more **Item** would be published as a *Variable Product* (with each **Item** being a *Variation*).
 */
@ObjectType("Product", {})
export class Product extends ProductBase implements Entity<ProductBase> {
  /**
   * A non-existent "virtual SKU" for this **Product** that is used in Ecom - this is in the format `ECOM-{id}`.
   *
   * Every Product published in WooCommerce (Farmstore) needs to have a SKU, even *Variable Products* which are not sold directly.
   *
   * @remarks
   * This is strictly only used in the context of Farmstore (Ecom), this SKU doesn't technically exist and cannot be processed by any other systems.
   *
   * @example ECOM-1723
   */
  @Field((type) => String)
  get ecomSKU() {
    return `ECOM-${this.id}`;
  }

  /**
   * Gets the total `projectedSalesVelocity` of all {@link Item}s in this **Product**.
   */
  @Field((type) => Int)
  get totalProjectedSales() {
    if (!this.items) return null;

    return this.items.reduce((acc, item) => {
      return acc + (item.projectedSales || 0);
    }, 0);
  }

  toObject() {
    return null;
  }
}
