import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";

export enum EntityTypes {
  PACKAGE = "package",
  REFUND = "refund",
  ORDER = "order",
  PRODUCT = "productGroups",
}

@ObjectType({ isAbstract: true })
export class ActivityLogBase extends EntityBase<ActivityLogBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  /**
   * The action that prompted the log's creation
   */
  @Field({ nullable: true })
  action: string;

  /**
   * Message that was added to the log
   */
  @Field({ nullable: true })
  message: string;

  /**
   * Timestamp that the log was created at
   */
  @Field({ nullable: true })
  createdAt: string;

  /**
   * User or system that created the log
   */
  @Field({ nullable: true })
  user: string;
}

@ObjectType()
export class ActivityLog
  extends ActivityLogBase
  implements Entity<ActivityLogBase>
{
  toObject(): ActivityLogBase {
    return null;
  }
}

@InputType()
export class ActivityLogInput extends OmitType(
  ActivityLogBase,
  ["id", "createdAt"] as const,
  InputType
) {}

@InputType()
export class GetActivityLogParams {
  @Field()
  entity: string;
  @Field(() => Int)
  entityId: number;
}

@InputType()
export class CreateActivityLogParams {
  @Field()
  entity: string;
  @Field(() => Int)
  entityId: number;
  @Field({ nullable: true })
  user: string;
  @Field({ nullable: true })
  message: string;
  @Field({ nullable: true })
  action: string;
}

@InputType()
export class DeleteActivityLogsParams {
  @Field()
  entity: string;
  @Field(() => Int)
  entityId: number;
}
