import { Field, InputType, Int } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Address } from "../../../../interfaces";

/**
 * Expected shape that the UPS adapter is expecting
 */
export interface ShipmentPayload {
  description?: string;
  shipTo: {
    name: string;
    phone: string;
    email: string;
    address: Address;
  };
  shipFrom: {
    name: string;
    phone: string;
    address: Address;
  };
  service?: string;
  reference: string;
  upsPackages: {
    weight: {
      value: string;
      unit: string;
    };
    dimensions: {
      length: string;
      width: string;
      height: string;
      unit: string;
    };
  }[];
  deliveryOptions: { signature: boolean };
}

@InputType()
export class ShipmentPayloadInput {
  @Field(() => Int, {
    description: "Id for the package we are creating a shipment for",
  })
  id: number;

  @Field(() => GraphQLJSON, {
    description: "Shipment info for UPS adapter",
  })
  payload: ShipmentPayload;

  @Field(() => String, {
    description: "User that is creating the shipment label",
  })
  user: string;
}
