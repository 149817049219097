import { Field, ObjectType } from "@nestjs/graphql";
import { GraphQLJSONObject } from "graphql-type-json";

/**
 * Convertor errors response interface, id any.
 */
export interface UpdateEmailResponseErrors {
  /**
   * Error message.
   */
  message: string;

  /**
   * MJML TagName error occurred.
   */
  tagName: string;
}

/**
 * Convertor response interface.
 */
@ObjectType()
export class UpdateEmailResponse {
  /**
   * Converted HTML Template based on Email.
   */
  // html: string;

  /**
   * Converted MJML Template based on Email.
   */
  // mjml: string;

  /**
   * Error representation if any. See {@link IMjmlServerResponseErrors}.
   */
  @Field((type) => GraphQLJSONObject, { nullable: true })
  errors: UpdateEmailResponseErrors[];
}
