import { InputType, OmitType } from "@nestjs/graphql";
import { FinelineBase } from "../fineline.model";

/** @ignore */
@InputType()
export class FinelineInput extends OmitType(
  FinelineBase,
  ["items", "categories"] as const,
  InputType
) {}
