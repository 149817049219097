import {
  Field,
  Float,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { GraphQLJSON } from "graphql-type-json";
import { Address, DateTime, Entity, EntityBase } from "../../../interfaces";
import { ActivityLog } from "../../activity-log";
import { Transaction } from "../../transactions";
import { Package, PackageInput } from "../package/package.model";
import { Refund } from "../refund/refund.model";
import { Giftcard } from "./vo/giftcard.model";

dayjs.extend(utc);
dayjs.extend(timezone);

export const orderStatuses = [
  "failed",
  "on-hold",
  "pending",
  "processing",
  "processed",
  "completed",
  "refunded",
] as const;

export type OrderStatus = (typeof orderStatuses)[number];

/** @ignore */
@ObjectType({ isAbstract: true })
export class OrderBase extends EntityBase<OrderBase> {
  @Field(() => Int, { nullable: true })
  id: number;

  /**
   * The Order Number assigned to this order, this is customer facing.
   *
   * The services that ingest Orders are responsible for generating a unique `orderNumber`.
   * They are always prefixed with a letter based on the source, and some unique numeric ID.
   *
   * @example
   * F-123456
   *
   * This is an order that was received from Farmstore (F) and had the WooCommerce ID 123456.
   *
   * @example W-987654
   *
   * This is an order from Wilco (W) and it was given a unique ID of 987654.
   */
  @Field(() => String, { nullable: true })
  orderNumber: string;

  /**
   * The ID from the source of the Order.
   *
   * @readonly
   */
  @Field(() => String, { nullable: true })
  externalId: string;

  /**
   * The source of the Order.
   *
   * @readonly
   */
  @Field(() => String, { nullable: true })
  externalSource: "farmstore" | "epicor";

  /**
   * The date that the Order was placed.
   */
  @Field((type) => String, { nullable: true })
  orderDate: DateTime;

  /**
   * The status of the Order.
   */
  @Field((type) => String, { nullable: true })
  status: OrderStatus;

  @Field((type) => String, { nullable: true })
  customerFirstName: string;
  @Field((type) => String, { nullable: true })
  customerLastName: string;

  /**
   * WFR account number.
   */
  @Field((type) => String, { nullable: true })
  customerAccountNumber: string;

  @Field((type) => String, { nullable: true })
  customerPhone: string;
  @Field((type) => String, { nullable: true })
  customerEmail: string;
  @Field((type) => String, { nullable: true })
  customerIp: string;

  @Field(() => GraphQLJSON, { nullable: true })
  billingAddress: Address;

  @Field(() => GraphQLJSON, { nullable: true })
  shippingAddress: Address;

  /**
   * Purchase Order number provided by the Customer when they placed the order.
   */
  @Field((type) => String, { nullable: true })
  poNumber: string;

  @Field((type) => String, { nullable: true })
  paymentMethod: string;
  @Field((type) => String, { nullable: true })
  paymentTransactionId: string;

  /**
   * The total amount of tax paid on this Order.
   */
  @Field((type) => Int, { nullable: true })
  orderTax: number;

  /**
   * The total of the Order.
   *
   * @remarks EXCLUSIVE of tax
   */
  @Field((type) => Int, { nullable: true })
  orderTotal: number;

  /**
   * The total savings received from Best Pricing Logic.
   *
   * This does not include coupons or other cart-level promotions.
   */
  @Field((type) => Int, { nullable: true })
  savingsTotal: number;

  /**
   * Total discount from coupons.
   */
  @Field((type) => Float, { nullable: true })
  couponTotal: number;

  /**
   * Total tax discounted from coupons.
   */
  @Field((type) => Float, { nullable: true })
  couponTax: number;

  /**
   * A concatenated string of coupons used.
   */
  @Field((type) => String, { nullable: true })
  couponUsed: string;

  /**
   * This flag is false by default and only set to true if we have done a
   * total refund on the order that has a coupon. This flag is set
   * to true if a refund for this order has already taken into account
   * the coupon
   */
  @Field((type) => Boolean, { nullable: true })
  isCouponAccomodated?: boolean;

  /**
   * Total donations
   */
  @Field((type) => Int, { nullable: true })
  donation: number;

  @Field((type) => GraphQLJSON, { nullable: true })
  giftcards: Giftcard[];

  /**
   * Processing flag for order created event
   * @Internal
   */
  _isProcessing?: boolean;

  /**
   * Flag to determine whether order has had an email sent
   * for it
   * @Internal
   */
  _orderReceivedEmailSent?: boolean;

  /**
   * Anti-fraud information about this order
   */
  @Field(() => GraphQLJSON, { nullable: true })
  antiFraud: {
    score: number;
    warnings: string[];
  };

  @Field((type) => String, { nullable: true })
  alternativePickupPerson: string;

  @Field(() => String, { nullable: true })
  approvedBy?: string;

  @Field((type) => String, { nullable: true })
  approvedAt?: DateTime;

  /**
   * Activity logs - this can be actions, user notes, communications, etc.
   */
  @Field(() => [ActivityLog], { nullable: true })
  activityLog: ActivityLog[];

  /**
   * Date & time the Order moved to `completed` status.
   */
  @Field((type) => String, { nullable: true })
  completedAt: DateTime;

  @Field((type) => String, { nullable: true })
  integratedAt?: DateTime;

  @Field((type) => String, { nullable: true })
  integratedRef?: DateTime;

  @Field((type) => String, { nullable: true })
  createdAt?: DateTime;

  @Field((type) => String, { nullable: true })
  updatedAt?: DateTime;

  packages?: Package[];

  constructor(props: OrderBase) {
    super(props);
    this.packages = props.packages
      ? props.packages.map((p) => new Package(p))
      : null;
  }
}

/**
 * An Order is a purchase from a Customer.
 *
 * It can come from different sources, such as Farmstore.com or from a Wilco store.
 *
 * Order Numbers are generated in Back40 (OmniAPI) as they're ingested from the external source.
 * They are sequentially numbered, and to differentiate the source, we prefix the order with `F-` (Farmstore) or `W-` (Wilco).
 *
 * The process to generate aN Order Number is as follows.
 *
 * - Generate a "unique order key" for each Order, for Farmstore this is simply the Order ID,
 * and for Wilco this is a concatenation of Doc # + Customer Number + Date
 *
 * - Insert that "unique order key" into the `order_ids` table, which is assigned an autoinc ID number.
 *
 * - Take the ID from `order_ids` and combine it with the `F-` or `W-` prefix, resulting in our Order Number
 */
@ObjectType("Order", {})
export class Order extends OrderBase implements Entity<OrderBase> {
  /**
   * Aggregate all transactions that belong to this order's packages/refunds
   * so that we can display them at a top level
   */
  @HideField()
  get transactions() {
    return this.packages.reduce((transactions: Transaction[], _package) => {
      // Aggregate all of our refund transactions
      const refundTransactions = _package.refunds.reduce(
        (_transactions: Transaction[], refund) =>
          refund.transaction
            ? [..._transactions, refund.transaction]
            : _transactions,
        []
      );

      transactions = transactions.concat(refundTransactions);

      // If our package has a transaction, add it to our list
      if (_package.transaction) transactions.push(_package.transaction);

      return transactions;
    }, []);
  }

  @HideField()
  get totals() {
    return this.packages.reduce(
      (totals, _package) => {
        const {
          saved,
          tax,
          shipping,
          subtotal,
          refunded,
          netTotal,
          refundedShipping,
          couponTotal,
        } = _package.totals;
        return {
          saved: totals.saved.add(saved),
          tax: totals.tax.add(tax),
          shipping: totals.shipping.add(shipping),
          subtotal: totals.subtotal.add(subtotal),
          netTotal: totals.netTotal.add(netTotal),
          refundedShipping: totals.refundedShipping.add(refundedShipping),
          couponTotal: totals.couponTotal.add(couponTotal),
          refunded: totals.refunded.add(refunded),
        };
      },
      {
        saved: currency(0),
        tax: currency(0),
        shipping: currency(0),
        subtotal: currency(0),
        netTotal: currency(0),
        refundedShipping: currency(0),
        couponTotal: currency(0),
        refunded: currency(0),
      }
    );
  }

  /**
   * Format data for UPS shipment
   */
  @HideField()
  get shipTo() {
    return {
      firstName: this.customerFirstName,
      lastName: this.customerLastName,
      phone: this.customerPhone,
      email: this.customerEmail,
      address: this.shippingAddress
        ? this.shippingAddress
        : this.billingAddress,
    };
  }

  @Field(() => Float, { nullable: true })
  get orderNetTotal() {
    return currency(this.orderTotal).add(this.orderTax || 0);
  }

  /**
   * Formats an address into a string. Uses `\n` for line breaks.
   */
  formatAddress() {
    let formattedAddress = this.billingAddress.streetAddress1;

    if (this.billingAddress.streetAddress2) {
      formattedAddress += `\n${this.billingAddress.streetAddress2}`;
    }

    formattedAddress += `\n${this.billingAddress.city} ${this.billingAddress.state}, ${this.billingAddress.zip}`;

    return formattedAddress;
  }

  formatShippingAddress() {
    /**
     * If we don't have a shipping address, use our billing address
     */
    if (!this.shippingAddress && !this.shippingAddress?.streetAddress1) {
      return this.formatAddress();
    }

    let formattedAddress = this.shippingAddress.streetAddress1;

    if (this.shippingAddress.streetAddress2) {
      formattedAddress += `\n${this.shippingAddress.streetAddress2}`;
    }

    formattedAddress += `\n${this.shippingAddress.city} ${this.shippingAddress.state}, ${this.shippingAddress.zip}`;

    return formattedAddress;
  }

  fullname() {
    return `${this.customerFirstName} ${this.customerLastName}`;
  }

  /**
   * Accepts a four-digit sequence number
   * and returns a fully formatted `orderNumber`.
   *
   * @param sequence a four-digit sequence number as a string, left-filled with 0s
   * @returns A formatted `orderNumber`
   */
  generateOrderNumber(sequence: number) {
    let orderNumberParts = [];

    // prefix
    if (this.externalSource === "farmstore") orderNumberParts.push("E");
    else if (this.externalSource === "epicor") orderNumberParts.push("W");

    // date
    const date = dayjs(this.orderDate)
      .tz("America/Los_Angeles")
      .format("YYMMDD");
    orderNumberParts.push(date);

    // sequence
    orderNumberParts.push(sequence.toString().padStart(4, "0"));

    this.orderNumber = orderNumberParts.join("-");
    return this.orderNumber;
  }

  /**
   * Receives potential refunds and an additional amount to check against. Once all of
   * the refunds have been validated, this function will attempt to spread the
   * additional amount across the refunds as needed. If it fails, it sets an
   * error message and returns, otherwise, it will return the updated refunds
   *
   * @param potentialRefunds - Refunds to validate without additional amounts
   * @param additionalAmount - Additional amount to add after validation
   * @returns
   */
  processAndValidateRefunds(
    potentialRefunds: Refund[],
    additionalAmount: number
  ): { refunds: Refund[]; error: string } {
    const _additionalAmount = additionalAmount;

    for (let i = 0; i < potentialRefunds.length; i++) {
      // Get package to process
      const potentialRefund = potentialRefunds[i];

      // Get associated refund
      const _package = this.packages.find(
        (_package) => _package.id === potentialRefund._packageId
      );

      if (!_package) continue;

      // Validate our package given a potentialRefund
      let {
        hasValidTotal, // Should have a total > 0
        isQuantityAvailable, //  True - if lineItem quantites are valid
        isRefundable, // Above is true and refund amount doesn't exceed the amount left on package
        amountAvailableToRefund, // Amount package can refund
        isShippingChargeValid, // Tells us if our shipping refund is valid
        shippingChargeRefund, // Amount we are refunding for shipping
      } = _package.validateRefund(potentialRefund);

      /**
       * This shouldn't ever happen but in case it does we can't do anything
       * so log and return (This should be prevented by our Form validator - see below)
       */
      if (!isQuantityAvailable) {
        console.log("Potential Refund: ", potentialRefund);
        console.log("Package: ", _package);
        return {
          refunds: null,
          error: `Package does not have quantity available to refund`,
        };
      }

      if (!isShippingChargeValid) {
        console.log("Potential Refund: ", potentialRefund);
        console.log("Package: ", _package);
        return {
          refunds: null,
          error: `Package cannot refund shippingCharge`,
        };
      }

      /**
       * This condition will only ever be true given the following:
       *
       * 1) We either have the quantity to refund or we are only refunding an additional amount
       * 2) Regardless of 1, the package's amount it can refund would exceed its total
       * if it accepted this refund
       *
       * In this case, we want to refund the quantity of the item but have another refund
       * that is refunding an additionalAmount.
       *
       * Solution:
       *
       * 1) Take the additionalAmount left on the package andset the refund amount to that
       * value
       * 2) Total up the lineItem quantities/amounts. Divide the total amount by the total
       * quantity and set the amount of each lineItem to: (average amount * respective quantity)
       *
       * This will ensure that all lineItems have a reduced unitPrice that reflects the amount we
       * are refunding on the package
       */
      if (!isRefundable) {
        // Set our refund amount
        potentialRefund.total =
          amountAvailableToRefund + shippingChargeRefund * -1;

        /**
         * Get the totalQuantity of items refunded
         */
        const totalQuantity = potentialRefund.lineItems.reduce(
          (totalQuantity, item) => (totalQuantity += item.quantity),

          0
        );

        // Get the average of the amount we have left to refund and the totalItem quantity
        const averageAmount = (amountAvailableToRefund / totalQuantity) * -1;

        // Overwrite lineItem amounts given new average
        potentialRefund.lineItems.forEach((item) => {
          item.total = item.quantity * averageAmount;
        });

        this._checkShippingCharge(_package, 0, potentialRefund);

        // If we are still not refundable something is wrong, log and return
        if (!_package.validateRefund(potentialRefund).isRefundable) {
          console.log("Potential Refund: ", potentialRefund);
          console.log("Package: ", _package);
          return {
            refunds: null,
            error: `Package is not refundable!`,
          };
        }

        /**
         * We continue on to the next package here because this package cannot handle
         * the additionalAmount if any
         */
        continue;
      }

      /**
       * This can occur if we have sent multiple refunds to be tested. This effectively will
       * just weed out any refunds that have no values and let us continue on to the
       * other refunds as needed
       */
      if (!hasValidTotal && (!additionalAmount || additionalAmount <= 0)) {
        console.log("[Order Model] Skipped refund due to no amount added.");
        continue;
      }

      /**
       * If we have an additionalAmount as a part of this refund, apply what we can to
       * this package's refund
       */
      if (additionalAmount > 0) {
        // Get the amount to add to this package's refund
        const amountToAdd =
          additionalAmount <= amountAvailableToRefund
            ? additionalAmount
            : amountAvailableToRefund;

        amountAvailableToRefund = amountAvailableToRefund - amountToAdd;

        // Decrement the amount that we added
        additionalAmount =
          currency(additionalAmount).subtract(amountToAdd).value;

        // Add amount to our refund
        potentialRefund.total = currency(potentialRefund.total).add(
          amountToAdd
        ).value;
      } else {
        amountAvailableToRefund -= potentialRefund.total;
      }

      this._checkShippingCharge(
        _package,
        amountAvailableToRefund,
        potentialRefund
      );

      // If we are still not refundable something is wrong, log and return
      if (!_package.validateRefund(potentialRefund).isRefundable) {
        console.log("Potential Refund: ", potentialRefund);
        console.log("Package: ", _package);
        return {
          refunds: null,
          error: `Package is not refundable!`,
        };
      }
    }

    /**
     * If we still have an additional amount, then our refunds could not add all of it
     * and we are invalid
     */
    if (additionalAmount > 0) {
      console.log("Potential Refund: ", potentialRefunds);
      console.log("Package: ", _additionalAmount);
      return {
        refunds: null,
        error: `Refunds could not compensate additionalAmount!`,
      };
    }

    const hasProblem = potentialRefunds.reduce(
      (hasProblem, refund) =>
        hasProblem || (!refund.total && refund.lineItems.length > 0),
      false
    );

    /**
     * If we do not have a total, there is something wrong with this refund. It is possible that we
     * are syncing and could not match externalIds
     */
    if (hasProblem) {
      return {
        refunds: null,
        error: `Refund has no total. Check to see that orderItems match!`,
      };
    }

    const validRefunds = potentialRefunds.filter((refund) => !!refund.total);

    /**
     * If we made it this far, ensure we filter out any refunds that have no amounts
     * to refund. If we end up with no refunds, create an error
     */
    return {
      refunds: validRefunds.length === 0 ? null : validRefunds,
      error:
        validRefunds.length === 0
          ? "Validation returned no refunds to process"
          : null,
    };
  }

  private _checkShippingCharge(
    _package: Package,
    amountAvailableToRefund: number,
    refund: Refund
  ) {
    /**
     * If this package is fully refunded, make sure the shipping (if any) has been refunded
     * as well
     */
    if (
      amountAvailableToRefund <= 0 &&
      _package.fulfillmentMethod === "Ship To Home"
    ) {
      const { shipping, refundedShipping } = _package.totals;

      let leftToRefund = shipping.add(refundedShipping);
      const refundedOnPotentialRefund = refund.shippingItems.reduce(
        (total, item) => total.add(item.total),
        currency(0)
      );

      leftToRefund = leftToRefund.add(refundedOnPotentialRefund);

      /**
       * If we still have a shipping charge left to refund, add it to the refund
       */
      if (leftToRefund.value !== 0) {
        refund.shippingItems.push({
          id: _package.shippingCharge.id,
          externalId: _package.shippingCharge.externalId,
          tax: null,
          total: leftToRefund.value * -1,
        });

        refund.total += leftToRefund.value;
      }
    }
  }

  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }
}

@InputType()
export class OrderInput extends OmitType(
  Order,
  ["packages", "activityLog"] as const,
  InputType
) {
  @Field(() => [PackageInput])
  packages?: PackageInput[];
}
