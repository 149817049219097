import { EmailImageBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailImageBlock(
      "https://media.back40.cloud/email-builder/assets/logo-Wilco.png",
      { width: { value: 150, unit: "px" } }
    ),
  ],
];

export class EmailWilcoHeader extends Structure {
  readonly icon = "maximize";
  readonly name = "header";

  constructor(options?: IEmailStructureOptions) {
    super("cols_1", ELEMENTS, {
      ...options,
      background: {
        color: "#F8F5EC",
      },
    });
  }
}
