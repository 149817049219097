import { Field, Float, ID, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../interfaces";

@ObjectType({ isAbstract: true })
export class RecapTransactionBase extends EntityBase<RecapTransactionBase> {
  @Field((type) => ID)
  itemNumber: string;

  @Field((type) => Int)
  total: number;

  @Field((type) => Int)
  quantity: number;

  @Field({ nullable: true })
  reference: string;

  @Field((type) => Int)
  inStoreSales: number;

  @Field((type) => Int)
  onlineSales: number;

  @Field((type) => Int)
  avgQuantity: number;

  @Field((type) => Int)
  transactions?: number;

  @Field((type) => Float)
  dailyUnitsSold?: number;
  @Field((type) => Float)
  dailyAnnualUnitsSold?: number;
  @Field((type) => Float)
  dailySixtyUnitsSold?: number;

  constructor(props?: RecapTransactionBase) {
    super(props);
  }
}

@ObjectType("RecapTransaction", {})
export class RecapTransaction
  extends RecapTransactionBase
  implements Entity<RecapTransactionBase>
{
  toObject() {
    return null;
  }
}

@ObjectType()
export class AggregatedSale {
  @Field((type) => ID)
  itemNumber: string;

  @Field({ nullable: true })
  orderDate: string;

  @Field((type) => Int)
  quantity: number;
}
