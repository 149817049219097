import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../interfaces";

@ObjectType({ isAbstract: true })
export class OptionBase extends EntityBase<OptionBase> {
  @Field((type) => Int)
  id: number;
  @Field((type) => String)
  key: string;
  @Field((type) => GraphQLJSON)
  value: any;
}

@ObjectType("Option", {})
export class Option extends OptionBase implements Entity<OptionBase> {
  toObject() {
    return null;
  }
}

@InputType()
export class OptionInput extends OmitType(Option, [] as const, InputType) {}
export enum Options {
  NOTIFY_EMAIL = "fulfillment_notify_email",
}
