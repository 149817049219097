import { Field, ID, InputType, ObjectType, OmitType } from "@nestjs/graphql";

@ObjectType()
export class AdCampaign {
  @Field((type) => ID)
  id: number;

  companyName: string;
  startDate: string;
  endDate: string;
}

@InputType()
export class AdCampaignInput extends OmitType(
  AdCampaign,
  [] as const,
  InputType
) {}
