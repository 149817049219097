/**
 * Products are something that can be purchased by a Customer.
 *
 * There are two main entities within the Products domain - {@link Product} and {@link Item}.
 *
 * An *Item* is identified by an immutable & unique **SKU** that is provided into the domain from Wilco's Epicor system. It represents a physical product that can be sold to a Customer.
 *
 * Meanwhile a *Product* can be thought of as a collection of one-or-more *Items*, and cannot be sold.
 *
 * @module Products
 */

// Entities
export * from "./brand/brand.model";
export * from "./brand/graphql/brand-input.graphql";
export * from "./category/category.model";
export * from "./category/graphql/category-input.schema";
export * from "./category/graphql/get-categories-config.schema";
export * from "./chemical/chemical.model";
export * from "./chemical/graphql/chemical-input.model";
export * from "./fineline/fineline.model";
export * from "./fineline/graphql/fineline-input.schema";
export * from "./fineline/graphql/get-fineline-config.schema";
export * from "./item-color/graphql/item-color-input.schema";
export * from "./item-color/graphql/item-color-result.graphql";
export * from "./item-color/item-color.model";
export * from "./item-size/graphql/item-size-input.schema";
export * from "./item-size/graphql/item-size-result.graphql";
export * from "./item-size/item-size.model";
export * from "./item/graphql/item-input.schema";
export * from "./item/graphql/kiosk-item.graphql";
export * from "./item/item.model";
export * from "./item/vo/item-location-data.model";
export * from "./item/vo/item-location-secondary-data.model";
export * from "./item/vo/item-master-data.model";
export * from "./item/vo/item-sales-velocity-data.model";
export * from "./product-warning/product-warning.model";
export * from "./product/graphql/get-products-config.schema";
export * from "./product/graphql/kiosk-product.graphql";
// GQL
export * from "./brand/graphql/brand-result.graphql";
export * from "./category/graphql/category-result.graphql";
export * from "./chemical/graphql/chemical-result.graphql";
export * from "./product/graphql/product-input.schema";
export * from "./product/graphql/search-kiosk.schema";
export * from "./product/product.model";
export * from "./product/vo/product-image-default.model";
export * from "./product/vo/product-publish-settings.model";
export * from "./species/graphql/species-input.schema";
export * from "./species/graphql/species-result.graphql";
export * from "./species/species.model";
export * from "./woosync/graphql/woosync-input.schema";
export * from "./woosync/woosync.model";
