import { EmailImageBlock, EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailImageBlock(
      "https://media.back40.cloud/email-builder/assets/icon-ship.png"
    ),
    new EmailTextBlock(
      '<p class="ql-align-center">Your order {orderNumber} has shipped!</p>',
      {
        font: {
          size: 20,
          weight: 800,
        },
        padding: {
          bottom: 5,
        },
      }
    ),
    new EmailTextBlock(
      '<p class="ql-align-center">Estimated delivery on {estimatedDelivery}</p>',
      {
        font: { size: 14 },
        padding: {
          top: 0,
        },
      }
    ),
  ],
];

export class EmailWilcoMessage extends Structure {
  readonly icon = "message";
  readonly name = "message";
  readonly options;

  constructor(options?: IEmailStructureOptions) {
    super("cols_1", ELEMENTS, options);
  }
}
