import { ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class EcomOrderBase extends EntityBase<EcomOrderBase> {
  id: number;
  order_number: number;
  created_at: string;
  updated_at: string;
  completed_at: string;
  status: string;
  currency: string;
  total: string;
  subtotal: string;
  total_line_items_quantity: number;
  total_tax: string;
  total_shipping: string;
  cart_tax: string;
  shipping_tax: string;
  total_discount: string;
  shipping_methods: string;
  payment_details: {
    method_id: string;
    method_title: string;
    paid: boolean;
  };
  billing_address: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
    phone: string;
  };
  shipping_address: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
  note: string;
  customer_ip: string;
  customer_user_agent: string;
  customer_id: number;
  view_order_url: string;
  line_items: {
    id: number;
    subtotal: string;
    subtotal_tax: string;
    total: string;
    total_tax: string;
    price: string;
    quantity: number;
    tax_class?: string;
    name: string;
    product_id: number;
    sku: string;
    meta: {
      key: string;
      label: string;
      value: string;
    }[];
  }[];
  shipping_lines: {
    id: number;
    method_id: string;
    method_title: string;
    total: string;
  }[];
  tax_lines: {
    id: number;
    rate_id: string;
    code: string;
    title: string;
    total: string;
    compound: boolean;
  }[];
  fee_lines: any[];
  coupon_lines: any[];
  customer: {
    id: number;
    created_at: string;
    email: string;
    first_name: string;
    last_name: string;
    username: string;
    last_order_id: string;
    last_order_date: string;
    orders_count: number;
    total_spent: string;
    avatar_url: string;
    //   billing_address: BillingAddress2
    //   shipping_address: ShippingAddress2
  };
}

/**
 * An Ecom Order is an order that exists on Farmstore, this model reflects how that data is stored.
 *
 * It's not "part of our Domain" though, it only exists here for use in Adapter's that should map data into an Order.
 */
@ObjectType()
export class EcomOrder extends EcomOrderBase implements Entity<EcomOrderBase> {
  toObject() {
    return null;
  }
}
