import { Image } from "../../../../interfaces";

/**
 * A default image configuration owned by a {@link Product} to be applied to matching {@link Item}s based on size and/or color.
 *
 * @category Value Object
 */
export interface ProductImageDefault {
  image: Image;
  sizeId: number | any;
  colorId: string;
}
