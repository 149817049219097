import { Field, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces";
import { SpecialtyDiscount } from "../../promotions";
import { Profile } from "../profile/profile.model";

/** @ignore */
@ObjectType({ isAbstract: true })
export class CustomerBase extends EntityBase<CustomerBase> {
  /**
   * The ID is the Customer Number that we receive from Epicor
   */
  @Field({ nullable: true })
  id: string;

  /**
   * The name comes from Epicor, it's very dirty and inconsistent data.
   *
   * It'll sometimes be the full name of a Customer, or multiple names, or a company name.
   */
  @Field({ nullable: true })
  name: string;

  @Field({ nullable: true })
  address1: string;

  @Field({ nullable: true })
  address2: string;

  @Field({ nullable: true })
  city: string;

  @Field({ nullable: true })
  state: string;

  @Field({ nullable: true })
  zip: string;

  /**
   * Phone number with area code
   */
  @Field({ nullable: true })
  phoneNumber: string;

  /**
   * Category plan that this customer belongs to.
   */
  @Field({ nullable: true })
  categoryPlan: string;

  @Field({ nullable: true })
  taxable: boolean;

  @Field({ nullable: true })
  dateAccountOpened: string;

  @Field({ nullable: true })
  lastPurchaseDate: string;

  @Field({ nullable: true })
  lastActivityDate: string;

  @Field({ nullable: true })
  createdAt: string;

  @Field({ nullable: true })
  updatedAt: string;

  @Field((type) => String, { nullable: true })
  militaryDiscountTill: string;

  @Field((type) => String, { nullable: true })
  seniorDiscountTill: string;

  @Field((type) => [SpecialtyDiscount], { nullable: "itemsAndList" })
  specialtyDiscounts?: SpecialtyDiscount[];

  @Field((type) => [Profile], { nullable: "itemsAndList" })
  profiles?: Profile[];
}

/**
 * A Customer is a record that comes into the app via Epicor.
 */
@ObjectType()
export class Customer extends CustomerBase implements Entity<CustomerBase> {
  toObject() {
    return null;
  }
}
