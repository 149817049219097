import { Field, Int, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ProductWarningBase extends EntityBase<ProductWarningBase> {
  @Field((type) => Int, { nullable: true })
  id: number;
  @Field({ nullable: true })
  title?: string;
  @Field({ nullable: true })
  type?: string;
  @Field({ nullable: true })
  message?: string;
  @Field((type) => GraphQLJSON, { nullable: true })
  args?: any[];
  @Field((type) => GraphQLJSON, { nullable: true })
  warningArgs?: any[];
}

@ObjectType("ProductWarning", {})
export class ProductWarning
  extends ProductWarningBase
  implements Entity<ProductWarningBase>
{
  toObject(): ProductWarningBase {
    return null;
  }
}
