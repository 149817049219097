import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Entity, EntityBase } from "../../../interfaces/entity.interface";

/** @ignore */
@ObjectType({ isAbstract: true })
export class ItemColorBase extends EntityBase<ItemColorBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * The name of the ItemColor.
   *
   * This is public facing and will be shown on Ecom.
   */
  @Field({ nullable: true })
  name: string;
}

/**
 * A ItemColor is one of two (along with {@link ItemSize}) attributes that is used to differentiate an {@link Item} in a {@link Product}
 *
 * A {@link Product} can (optionally) be associated with an ItemColor.
 */
@ObjectType("ItemColor", {})
export class ItemColor extends ItemColorBase implements Entity<ItemColorBase> {
  toObject() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
