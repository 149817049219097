export default function createEnum<T extends { [key: string]: any }>(
  obj: T
): Readonly<[{ [K in keyof T]: T[K] }, string[]]> {
  // Create our "Enum" object
  const optionsObj = {} as {
    [K in keyof T]: T[K];
  };

  // Get all keys
  const keys = Object.keys(obj) as Array<keyof T>;
  // Set our values to our keys
  const values = Object.values(obj) as typeof obj[keyof typeof obj];

  // Map keys to themselves (basically an enum)
  for (const key of keys) {
    optionsObj[key] = obj[key];
  }

  // Return our enum and value array as immutable objects
  return [optionsObj, values] as const;
}

/**
 * This version is WIP
 */
// function createEnum<T extends { [key: string]: string }>(args: T[]) {
//   if (!args || args.length === 0) return;

//   const reducedObj: T = args.reduce(
//     (reduced, obj) => ({ ...reduced, ...obj }),
//     {} as T
//   );

//   type Keys = typeof reducedObj;

//   // Create our "Enum" object
//   const objEnum = {} as {
//     [K in keyof Keys]: Keys[K];
//   };

//   // Get all keys
//   const keys = Object.keys(reducedObj) as Array<keyof T>;

//   // Get values
//   const allValues = Object.values(
//     reducedObj
//   ) as typeof reducedObj[keyof typeof reducedObj][];

//   // Map keys to themselves (basically an enum)
//   for (const key of keys) {
//     objEnum[key] = reducedObj[key];
//   }

//   // Return our enum and value array as immutable objects
//   return [
//     objEnum,
//     allValues,
//     ...args.map((arg) => Object.values(arg)),
//   ] as const;
// }
