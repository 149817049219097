import { defaultsDeep } from "lodash";
import { EmailBlock, IEmailBlockOptions } from "../email.interface";

export type TEmailQRCodeBlockOptions = Pick<IEmailBlockOptions, null>;

export class EmailQRCode implements EmailBlock {
  readonly type = "qr-code";
  readonly icon = "receipt";
  readonly state = null;
  public options: TEmailQRCodeBlockOptions = {};
  public value: string = "hello world";

  constructor(value: string, options?: TEmailQRCodeBlockOptions) {
    this.value = value;
    this.options = defaultsDeep(options, this.options);
  }
}
