import { EmailOrderTableBlock, EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

const getElements = (mode): TEmailBlocks[][] => [
  [
    new EmailTextBlock(
      `
    <p><strong>Order Details</strong></p>
    <p><strong style="color: rgb(0, 71, 178);">Order #{orderNumber}</strong></p>
    <p>Order Date: {orderDate}</p>
    `,
      { padding: { left: 0 } }
    ),
    new EmailOrderTableBlock(mode),
  ],
];

export class EmailOrderTable extends Structure {
  readonly icon = "table_chart";
  readonly options;
  mode: "total" | "refund" | "brief";
  name;

  constructor(
    mode: "total" | "refund" | "brief",
    options?: IEmailStructureOptions
  ) {
    super("cols_1", getElements(mode), options);
    this.mode = mode;
    this.name = mode + " table";
  }
}
