import { InputType, OmitType } from "@nestjs/graphql";
import { ChemicalBase } from "../chemical.model";

/**
 * Input of a Chemical
 *
 * @category GQL Input
 */
@InputType()
export class ChemicalInput extends OmitType(
  ChemicalBase,
  [] as const,
  InputType
) {}
