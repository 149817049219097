import { Field, Int, ObjectType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";

export interface WoosyncChild {
  reason: string;
  publish: boolean;
  itemNumber: string;
}

@ObjectType({ isAbstract: true })
export class WoosyncBase extends EntityBase<WoosyncBase> {
  @Field((type) => Int)
  id: number;

  @Field((type) => Int, { nullable: true })
  ecomId: number;

  @Field(() => String, { nullable: true })
  ecomUrl: string;

  @Field(() => String, { nullable: true })
  status: string;

  @Field(() => String, { nullable: true })
  lastRun: string;

  @Field(() => String, { nullable: true })
  datePublished: string;

  @Field(() => GraphQLJSON, { nullable: true })
  children: WoosyncChild[];

  @Field(() => [String], { nullable: true })
  syncData: string[];
}

@ObjectType("Woosync", {})
export class Woosync extends WoosyncBase implements Entity<WoosyncBase> {
  toObject() {
    return null;
  }
}
