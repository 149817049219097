import { Field, InputType, Int } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { Brand } from "../../brand/brand.model";
import { Item } from "../../item/item.model";
import { Product } from "../product.model";

@InputType({
  description: `Relations of Products`,
})
export class IProductRelations {
  @Field(() => GraphQLJSON, {
    nullable: true,
    description: `Where in the list to search from`,
  })
  withRelations?: Array<"items" | "brand" | "*">;
}

/**
 * Params used to search for a Products
 */
@InputType({
  description: `Params used to search for a Products`,
})
export class IGetProductsConfig extends IProductRelations {
  /**
   * Value to search products by - skus
   */
  @Field(() => String, {
    nullable: true,
    description: `Value to search products by - skus`,
  })
  search?: string;

  /**
   * Filter Products that are one of the provided Product IDs
   */
  @Field(() => [Int], {
    nullable: true,
    description: `Where in the list to search from`,
  })
  withIds?: Array<Product["id"]>;

  /**
   * Filter Products that have one of the provided Item IDs as a child Item.
   */
  @Field(() => [Int], {
    nullable: true,
    description: `Where in the list to search from`,
  })
  withItemIds?: Array<Item["id"]>;

  /**
   * Filter Products that are associated to one of the provided Brand IDs
   */
  @Field(() => [Int], {
    nullable: true,
    description: `Where in the list to search from`,
  })
  withBrandIds?: Array<Brand["id"]>;

  /**
   * Returns Products with provided fineline codes
   */
  @Field(() => [String], {
    nullable: true,
    description: `Returns Products with provided fineline codes`,
  })
  withFinelines?: string[];

  /**
   * Products to exclude from the search
   */
  @Field(() => [String], {
    nullable: true,
    description: `Products to exclude from the search`,
  })
  exclude?: string[];

  /**
   * Products to include from the search
   */
  @Field(() => [String], {
    nullable: true,
    description: `Products to include from the search`,
  })
  include?: string[];

  /**
   * Returns Products that are published/unpublished/both
   */
  @Field(() => String, {
    nullable: true,
    description: `Returns Products that are published/unpublished/both`,
  })
  showOnly?: string;

  /**
   * Sorts the products based on a key passed
   */
  @Field(() => String, {
    nullable: true,
    description: `Sorts the products based on a key passed`,
  })
  sortBy?: string;

  @Field(() => Boolean, {
    nullable: true,
    description: `Returns products with valid kiosk items`,
  })
  validKioskItems?: boolean;

  @Field(() => Int, {
    nullable: true,
    description: `Amount of products to return`,
  })
  limit?: number;
  @Field(() => Int, {
    nullable: true,
    description: `Where in the list to search from`,
  })
  offset?: number;

  @Field(() => Boolean, {
    nullable: true,
    description: `Returns products that have featured set to true`,
  })
  featured?: boolean;
}
