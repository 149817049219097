import { EmailTextBlock, TEmailBlocks } from "../blocks";
import { IEmailStructureOptions } from "../email.interface";
import { Structure } from "./structure.def";

/**
 * If we are sending package sent order, replace billing info with
 * shipping address and remove contact info
 */
const ELEMENTS: TEmailBlocks[][] = [
  [
    new EmailTextBlock("Billing Information", {
      font: { size: 16, weight: 800 },
    }),
    new EmailTextBlock(
      `
      <p><strong>{customerName}</strong></p>
      <p>{customerCity}, {customerState} {customerZip}</p>
      <p>{customerPhoneNumber}</p>
      <p>{customerEmailAddress}</p>
      `,
      { font: { size: 12 } }
    ),
  ],
];

export class EmailWilcoCustomerInfo extends Structure {
  readonly icon = "info";
  readonly name = "customer info";
  readonly options;

  constructor(options?: IEmailStructureOptions) {
    super("cols_1", ELEMENTS, options);
  }
}
