import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import { DateTime, Entity, EntityBase } from "../../../interfaces";
import { Store } from "../../stores";
import { Package, PackageInput } from "../package/package.model";

@InputType()
export class FulfillmentParamsInput {
  @Field(() => [Int])
  ids?: number[];

  @Field(() => [Int])
  packageIds?: number[];

  @Field(() => Int)
  storeNumber?: number;

  @Field(() => String)
  reservedUser?: string;

  /**
   * Employee that has picked/staged the package
   */
  @Field(() => String)
  picker?: string;

  /**
   * Employee that has loaded out the package. Note that there will only ever be
   * loaders for Pickup Packages
   */
  @Field(() => String)
  loader?: string;

  /**
   * Employee that has audited a package and determined that it should be completed
   */
  @Field(() => String, { nullable: true })
  auditor?: string;

  @Field(() => String)
  status?: FulfillmentBase["status"];

  @Field(() => Boolean)
  createdAt?: boolean;

  @Field(() => Int)
  updatedAt?: string;

  @Field(() => String, { nullable: true })
  relation?: string;
}

/** @ignore */
@ObjectType({ isAbstract: true })
export class FulfillmentBase extends EntityBase<FulfillmentBase> {
  @Field((type) => Int)
  id: number;

  /**
   * In most cases will just be the {@link Package} Number.
   */
  @Field((type) => String, { nullable: true })
  fulfillmentNumber: string;

  @Field((type) => String)
  status: "on-hold" | "pending" | "processing" | "processed" | "completed";

  /**
   * When a Fulfillment is picked, it will be staged in a certain location in the store.
   *
   * @example
   * Ecomm Rack
   */
  @Field((type) => String, { nullable: true })
  stagedAt: string;

  /**
   * References the user who picked (or picking) the order.
   */
  @Field((type) => String, { nullable: true })
  picker: string;

  /**
   * Employee that has loaded out the package. Note that there will only ever be
   * loaders for Pickup Packages
   */
  @Field(() => String, { nullable: true })
  loader?: string;

  /**
   * Employee that has audited a package and determined that it should be completed
   */
  @Field(() => String, { nullable: true })
  auditor?: string;

  /**
   * References the account that is trying to reserve fulfillments before a picker has
   * been selected.
   */
  @Field((type) => String, { nullable: true })
  reservedUser: string;

  /**
   * In most cases this will be the {@link Package} `store`.
   *
   * This exists for future functionality to do internal stock transfers.
   */
  @Field((type) => String, { nullable: true })
  destinationStore: Store["id"];

  /**
   * In most cases this will be the {@link Package} `store` warehouse.
   *
   * This exists for future functionality to do internal stock transfers.
   */
  @Field((type) => String, { nullable: true })
  destinationDepartment: string;

  /**
   * This time is used to determine when a user reserved this fulfillment to be
   * picked. The time is used in the timeout logic in picker-details component
   */
  @Field((type) => String, { nullable: true })
  reservedAt: DateTime;

  @Field((type) => String, { nullable: true })
  processingAt: DateTime;

  @Field((type) => String, { nullable: true })
  notifiedAt?: DateTime;

  @Field((type) => String, { nullable: true })
  processedAt: DateTime;

  @Field((type) => String, { nullable: true })
  completedAt: DateTime;

  @Field((type) => String, { nullable: true })
  createdAt?: DateTime;

  @Field((type) => String, { nullable: true })
  updatedAt?: DateTime;

  /** @internal */
  @Field((type) => Int, { nullable: true })
  _packageId: Package["id"];

  @Field((type) => Package, { nullable: true })
  package?: Package;
}

/**
 * A Fulfillment is a "job" that needs to be completed by an Employee.
 *
 * They must physically go and pick the {@link OrderItem}s for a {@link Package} in the store/warehouse,
 * so that the {@link Package} fulfillment can progress.
 *
 * In majority of cases, a Fulfillment will be created at the same time as the {@link Package} - the exception
 * to this is Delivery {@link Package}s which aren't picked until the day before their scheduled delivery.
 *
 */
@ObjectType()
export class Fulfillment
  extends FulfillmentBase
  implements Entity<FulfillmentBase>
{
  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }
}

@InputType()
export class FulfillmentInput extends OmitType(
  Fulfillment,
  ["package"] as const,
  InputType
) {
  @Field(() => PackageInput)
  package?: PackageInput;
}
