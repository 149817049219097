import { Field, Float, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";
import { Entity, EntityBase } from "../../../interfaces";
import { Item } from "../../products";

/** @ignore */
@ObjectType({ isAbstract: true })
export class CartItemBase extends EntityBase<CartItemBase> {
  /**
   * The key for the WC Cart Item
   */
  cartItemKey: string;

  /**
   * Item Number of the cart item
   */
  itemNumber: string;

  /**
   * How many of this item are in the cart
   */
  quantity: number;

  /**
   * The sale price of the item (if applicable, may be 0)
   */
  @Field((type) => Float, { nullable: true })
  salePrice: number;

  /**
   * The regular price of the item
   */
  regularPrice: number;

  /**
   * The fulfillment method of this item - pickup, ship or delivery
   */
  fulfillmentMethod: string;

  /**
   * The store number for where this item will be fulfilled
   */
  storeNumber: number;

  /**
   * Item data
   */
  item?: Item;

  /**
   * Internal property to be used by discounted cart iterations
   */
  @Field((type) => GraphQLJSON)
  _constraints?: {};

  constructor(props: CartItemBase) {
    super(props);
    this.item = new Item(props.item);
  }
}

/**
 * Represents an Item in our Cart.
 */
@ObjectType()
export class CartItem extends CartItemBase implements Entity<CartItemBase> {
  toObject() {
    return {
      cartItemKey: this.cartItemKey,
      itemNumber: this.itemNumber,
      quantity: this.quantity,
      salePrice: this.salePrice,
      regularPrice: this.regularPrice,
      fulfillmentMethod: this.fulfillmentMethod,
      storeNumber: this.storeNumber,
      item: this.item,
      _constraints: this._constraints,
    };
  }
}
