const actions = ["Read", "Write", "Update", "Delete"] as const;
const apps = {
  Fulfillment: actions,
  Orders: actions,
  Products: actions,
  Categories: actions,
  Customer: actions,
  Attributes: actions,
  Content: actions,
  Reports: actions,
  Brands: actions,
  Promotions: actions,
  Media: actions,
  Ad: actions,
  Collection: [...actions, "update-self"],
  Signage: actions,
  Display: actions,
  Store: actions,
  Admin: ["read", "farmstore"] as const,
  Developer: ["developer"] as const,
} as const;

type TApps = keyof typeof apps;
type TActions<T extends TApps> = (typeof apps)[T][number];

export const PermissionsEnum = (() =>
  ({
    ...Object.keys(apps).reduce(
      (acc, app) => ({
        ...acc,
        [app]: {
          ...apps[app].reduce(
            (acc2, action) => ({
              ...acc2,
              [action]: `${app}:${action}`.toLowerCase(),
            }),
            {}
          ),
        },
      }),
      {}
    ),
  } as {
    [k in TApps]: { [k2 in TActions<k>]: string };
  }))();
