/**
 * Builder direction types.
 * @default ltr
 */
export type TEmailDirection = "ltr" | "rtl";
/**
 * Builder units types.
 * @default px
 */
export type TEmailUnits = "%" | "px" | "cover" | "contain";
/**
 * Builder align types.
 * @default left
 */
export type TEmailAlign = "left" | "center" | "right";
/**
 * Builder vertical align types.
 * @default middle
 */
export type TEmailVerticalAlign = "top" | "middle" | "bottom";
/**
 * Builder Line-height types.
 * @default none
 */
export type TEmailLineHeight = "%" | "px" | "none";
/**
 * Builder Link target.
 * @default _blank
 */
export type TEmailLinkTarget = "_blank" | "_self" | "_parent" | "_top";
/**
 * Builder font style types.
 * @default normal
 */
export type TEmailFontStyle = "italic" | "normal" | "oblique";
/**
 * Builder font weight types.
 * @default inherit
 */
export type TEmailFontWeight =
  | number
  | "bold"
  | "bolder"
  | "inherit"
  | "initial"
  | "light"
  | "normal";
/**
 * Builder background-repeat types.
 * @default no-repeat
 */
export type TEmailBackgroundRepeat =
  | "no-repeat"
  | "repeat"
  | "repeat-x"
  | "repeat-y";

/**
 * Builder border styles interface.
 */
export interface IEmailBorder {
  /* Border color */
  color?: string;
  /**
   * Border style.
   * @default solid
   */
  style?: "solid" | "dotted" | "dashed" | "double" | "groove";
  /**
   * Border width
   */
  width?: number;
  /**
   * Border radius
   */
  radius?: number;
}

/**
 * Builder padding styles interface.
 */
export interface IEmailPadding {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

/**
 * Builder margin styles interface.
 */
export interface IEmailMargin {
  top?: number;
  bottom?: number;
}

/**
 * Builder Width and Height styles interface.
 */
export interface IEmailWidthHeight {
  value: number;
  /**
   * Width and Height unit type.
   * @default px
   */
  unit: TEmailUnits;
  // Either full width or full height styles.
  auto?: boolean;
  // Units to be shown as options.
  units?: TEmailUnits[];
}

/**
 * Builder background styles interface.
 */
export interface IEmailBackground {
  color?: string;
  url?: string;
  repeat?: TEmailBackgroundRepeat;
  size?: IEmailWidthHeight;
}

/**
 * Builder font styles interface.
 */
export interface IEmailFont {
  family?: string;
  fallback?: string;
  size?: number;
  style?: TEmailFontStyle;
  weight?: TEmailFontWeight;
}

export type IEmailFontFamily = Set<string>;

/**
 * Builder line-height styles interface.
 */
export interface IEmailLineHeight {
  value?: number;
  unit?: TEmailLineHeight;
}
