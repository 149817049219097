/**
 * @packageDocumentation
 * @module SignCollection
 */

import { Field, InputType, Int, ObjectType, OmitType } from "@nestjs/graphql";
import GraphQLJSON from "graphql-type-json";
import { SignLabelInput } from ".";
import { Entity, EntityBase } from "../../interfaces";
import { AdMedia } from "../promotions";
import { CollectionApproval } from "./graphql/sign-collection-input.schema";
import { SignLabel } from "./sign-label.model";

@ObjectType({ isAbstract: true })
export class SignCollectionBase extends EntityBase<SignCollectionBase> {
  /**
   * Unique id for collection
   */
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * Id for associated media - if any
   */
  @Field((type) => Int, { nullable: true })
  mediaId: number;

  /**
   * Boolean to determine if this collection is viewable
   */
  @Field({ nullable: true })
  archived: boolean;

  /**
   * Name of the collection
   */
  @Field({ nullable: true })
  name: string;

  /**
   * Name of the user that owns this collections (if any)
   *
   * This only applies to once off collections
   */
  @Field({ nullable: true })
  userName: string;

  /**
   * Users that can see a default collection created by another user
   */
  @Field((type) => GraphQLJSON, { nullable: true })
  sharedUsers: string[];

  /**
   * This field is used for default collections to distinguish
   * which store they should belong to.
   */
  @Field((type) => Int, { nullable: true })
  storeNumber: number;

  /**
   * By default a collection has a status of completed when it is initially created.
   * When a user wants to generate a set of PDFs for a collection, they make a request
   * and we set the status to processing.
   *
   * Processing a collection is a CPU intensive task. Depending on how many versions and
   * templates that are requested by the user, one collection could be creating several
   * jobs for the generator. In order to track the collection's progress, we create jobs
   * for the collection.
   *
   * While the collection has jobs, it is in a processing state. If/When all of its jobs are
   * complete or fail, we set the status to completed. This flag is used client side to
   * determine when the collection is done processing.
   */
  @Field((type) => String, { nullable: true })
  status: "processing" | "completed";

  @Field((type) => GraphQLJSON, { nullable: true })
  approvedBy: CollectionApproval;

  @Field((type) => [SignLabel], { nullable: "itemsAndList" })
  signLabels?: SignLabel[];

  @Field((type) => AdMedia, { nullable: true })
  media?: AdMedia;

  constructor(props) {
    super(props);
    this.media = props.media;
    this.signLabels = props.signLabels
      ? props.signLabels.map((label) => new SignLabel(label))
      : [];
  }
}

@ObjectType()
export class SignCollection
  extends SignCollectionBase
  implements Entity<SignCollectionBase>
{
  toObject() {
    return null;
  }
}

@InputType()
export class SignCollectionInput extends OmitType(
  SignCollection,
  ["media", "signLabels"] as const,
  InputType
) {
  @Field(() => [SignLabelInput])
  signLabels?: [SignLabelInput];
}
