import { InputType, OmitType } from "@nestjs/graphql";
import { ItemSizeBase } from "../item-size.model";

/**
 * Input of an Item Size
 *
 * @category GQL Input
 */
@InputType()
export class ItemSizeInput extends OmitType(
  ItemSizeBase,
  [] as const,
  InputType
) {}
