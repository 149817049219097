import { Field, ID, ObjectType } from "@nestjs/graphql";
import { GraphQLJSON } from "graphql-type-json";

import { QuantityBreak } from "../../../../domain/promotions";
import { Entity, EntityBase } from "../../../../interfaces/entity.interface";
import { Image } from "../../../../interfaces/image.interface";
import { ItemColor } from "../../item-color/item-color.model";
import { ItemSize } from "../../item-size/item-size.model";
import { Item } from "../item.model";
import { ItemLocationData } from "../vo/item-location-data.model";
import { ItemLocationSecondaryData } from "../vo/item-location-secondary-data.model";

@ObjectType({ isAbstract: true })
export class KioskItemBase extends EntityBase<KioskItemBase> {
  @Field(() => ID, { nullable: true })
  id: string;

  @Field(() => GraphQLJSON, {
    nullable: true,
  })
  size?: ItemSize;
  @Field(() => GraphQLJSON, {
    nullable: true,
  })
  color?: ItemColor;
  @Field(() => GraphQLJSON, { nullable: true })
  image?: Image;
  @Field(() => GraphQLJSON, {
    nullable: true,
    description: "Location Data received from Epicor",
  })
  locationData?: ItemLocationData;
  @Field(() => GraphQLJSON, {
    nullable: true,
  })
  locationSecondaryData?: ItemLocationSecondaryData;
  @Field(() => QuantityBreak, { nullable: true })
  quantityBreak?: QuantityBreak;

  constructor(props: KioskItemBase) {
    super(props);
    this.quantityBreak = props.quantityBreak
      ? new QuantityBreak(props.quantityBreak)
      : null;
  }
}

@ObjectType("KioskItem", {})
export class KioskItem extends KioskItemBase implements Entity<KioskItemBase> {
  static fromItem(item: Item) {
    return new KioskItem({
      id: item.id,
      size: item.size,
      color: item.color,
      image: item.image,
      locationData: item.locationData,
      locationSecondaryData: item.locationSecondaryData,
      quantityBreak: item.quantityBreak
        ? new QuantityBreak(item.quantityBreak)
        : null,
    });
  }

  getStoreData(store: number) {
    const promotionPrice: number | null =
      this.locationData["promotionPrice" + store];
    const retailPrice: number | null = this.locationData["retailPrice" + store];
    const qtyAvailable: number | null =
      this.locationData["quantityAvailable" + store];

    return {
      id: this.id,
      displayPrice: promotionPrice ?? retailPrice,
      promotionPrice,
      qtyAvailable,
      retailPrice,
      quantityBreak: this.quantityBreak,
    };
  }

  toObject() {
    return null;
  }
}
