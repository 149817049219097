import { Field, InputType, registerEnumType } from "@nestjs/graphql";

export enum ZebraAsset {
  WILCO_LOGO = "WILCO_LOGO",
  WILCO_LOGO_LG = "WILCO_LOGO_LG",
}

registerEnumType(ZebraAsset, {
  name: "ZebraAsset",
});

export const ZEBRA_ASSET_MAP = new Map<ZebraAsset, string>([
  [ZebraAsset.WILCO_LOGO, "wilco-logo.png"],
  [ZebraAsset.WILCO_LOGO_LG, "wilco-logo-lg.png"],
]);

@InputType()
export class GetImageConversion {
  @Field((type) => ZebraAsset)
  key: ZebraAsset;
}
