import { Field, InputType, OmitType } from "@nestjs/graphql";
import { DisplayScene } from "../display-scene.model";
import { SceneColumnInput } from "../scene-column/graphql/scene-column.input";

@InputType()
export class DisplaySceneInput extends OmitType(
  DisplayScene,
  ["columns"] as const,
  InputType
) {
  @Field(() => [SceneColumnInput])
  columns?: SceneColumnInput[];
}
