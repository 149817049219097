import {
  Field,
  HideField,
  InputType,
  Int,
  ObjectType,
  OmitType,
} from "@nestjs/graphql";
import * as currency from "currency.js";
import { GraphQLJSON } from "graphql-type-json";
import { Checklist, DateTime, Entity, EntityBase } from "../../../interfaces";
import { ActivityLog } from "../../activity-log";
import { Store, StoreInput } from "../../stores";
import { Transaction } from "../../transactions";
import { Delivery } from "../delivery/delivery.model";
import {
  Fulfillment,
  FulfillmentInput,
} from "../fulfillment/fulfillment.model";
import { OrderItem, OrderItemInput } from "../order-item/order-item.model";
import { Order, OrderInput } from "../order/order.model";
import { Refund, RefundInput } from "../refund/refund.model";
import {
  ShippingCharge,
  ShippingChargeInput,
} from "../shipping-charge/shipping-charge.model";

export interface ItemTotals {
  shipping: currency;
  subtotal: currency;
  tax: currency;
  saved: currency;
  total: currency;
  refunded?: currency;
  refundedShipping?: currency;
}

export const PACKAGE_STATUS_MAP = new Map<
  string,
  Map<string, { label: string; verb?: string }>
>([
  [
    "Pickup",
    new Map([
      ["on-hold", { label: "On Hold" }],
      ["pending", { label: "Pending Pick" }],
      ["processing", { label: "Picking", verb: "Start Picking" }],
      ["processed", { label: "Pickup Ready", verb: "Finish Picking" }],
      ["completed", { label: "Completed", verb: "Picked Up" }],
      ["refunded", { label: "Refunded" }],
    ]),
  ],
  [
    "Ship To Home",
    new Map([
      ["on-hold", { label: "On Hold" }],
      ["pending", { label: "Pending Pick" }],
      ["processing", { label: "Picking", verb: "Start Picking" }],
      ["processed", { label: "Ready To Ship", verb: "Ready To Ship" }],
      ["completed", { label: "Completed", verb: "Shipped" }],
      ["refunded", { label: "Refunded" }],
    ]),
  ],
  [
    "Delivery",
    new Map([
      ["on-hold", { label: "On Hold" }],
      ["pending", { label: "Pending" }],
      ["processing", { label: "Scheduling", verb: "Schedule Order" }],
      ["processed", { label: "Scheduled", verb: "Order Scheduled" }],
      ["completed", { label: "Delivered" }],
      ["refunded", { label: "Refunded" }],
    ]),
  ],
]);

export const initialStatuses: ReadonlyArray<string> = [
  "on-hold",
  "pending",
  "processing",
  "processed",
];

export const finalizedStatuses: ReadonlyArray<string> = [
  "completed",
  "refunded",
] as const;
export const packageStatuses = [
  ...initialStatuses,
  ...finalizedStatuses,
] as const;

type IPackageStatus = (typeof packageStatuses)[number];

/** @ignore */
@ObjectType({ isAbstract: true })
export class PackageBase extends EntityBase<PackageBase> {
  @Field((type) => Int, { nullable: true })
  id: number;

  /**
   * [Fulfillment Method]-[Unique ID]-[Store Number]
   * P-123456-01
   * D-123456-12
   *
   * Fulfillment Method prefixes are:
   *
   * **P** = Pickup
   *
   * **S** = Ship To Home
   *
   * **D** = Wilco Delivery
   */
  @Field((type) => String, { nullable: true })
  packageNumber: string;

  /**
   * The status of the Package.
   */
  @Field((type) => String, { nullable: true })
  status: IPackageStatus;

  @Field((type) => Boolean, { nullable: true })
  isFocused: boolean;

  @Field((type) => Boolean, { nullable: true })
  threeDayEmailSent?: boolean;

  @Field((type) => Boolean, { nullable: true })
  fiveDayEmailSent?: boolean;

  @Field((type) => String, { nullable: true })
  fulfillmentMethod: "Pickup" | "Ship To Home" | "Delivery";

  /**
   * ID (store number) of the {@link Store} that is fulfilling this Package
   */
  @Field((type) => Int, { nullable: true })
  storeNumber: Store["id"];

  /**
   * Checklists are (optionally) used in every stage of the Package fulfillment to control workflow.
   *
   * Checklist
   *
   * @example
   * ```
   * {
   *  'pending': [ ... ]
   *  'processing': [
   *    {
   *      label: string,
   *      checked: boolean,
   *      required: boolean,
   *      action: string
   *    }
   *  ],
   *  ...
   * }
   * ```
   */
  @Field(() => GraphQLJSON, { nullable: true })
  checklists: Checklist<IPackageStatus>;

  /**
   * Activity logs - this can be actions, user notes, communications, etc.
   */
  @Field(() => [ActivityLog], { nullable: true })
  activityLog: ActivityLog[];

  @Field(() => GraphQLJSON, { nullable: true })
  meta: any;

  /**
   * A DateTime after which it's expected a Customer will pickup this Package.
   *
   * This defaults to standard fulfillment promises (4 business hours) but can be overwritten by
   * the Customer at checkout, or by staff via the Order/Fulfillment app.
   *
   * @remarks
   * Only used for Pickup packages
   */
  @Field((type) => String, { nullable: true })
  pickupAfter: DateTime;

  /**
   * By default this is the package's createdAt timestamp. The nuance comes in when
   * we want to defer this timestamp for tracking reasons in Mission Control or some
   * other store analytic. (Currently this will be overwritten when a package goes
   * from On-hold => Pending)
   */
  @Field((type) => String, { nullable: true })
  pendingAt: DateTime;

  @Field((type) => String, { nullable: true })
  processingAt: DateTime;

  @Field((type) => String, { nullable: true })
  processedAt: DateTime;

  @Field((type) => String, { nullable: true })
  completedAt: DateTime;

  @Field((type) => String, { nullable: true })
  createdAt?: DateTime;

  @Field((type) => String, { nullable: true })
  updatedAt?: DateTime;

  @Field((type) => Int, { nullable: true })
  _orderId: Order["id"];

  @Field((type) => Order, { nullable: true })
  order?: Order;

  @Field((type) => [OrderItem], { nullable: "itemsAndList" })
  orderItems: OrderItem[];

  @Field((type) => [Delivery], { nullable: "itemsAndList" })
  deliveries?: Delivery[];

  @Field((type) => [Fulfillment], { nullable: "itemsAndList" })
  fulfillments?: Fulfillment[];

  @Field((type) => ShippingCharge, { nullable: true })
  shippingCharge?: ShippingCharge;

  @Field((type) => [Refund], { nullable: "itemsAndList" })
  refunds?: Refund[];

  @Field((type) => Store, { nullable: true })
  store?: Store;

  @Field((type) => Transaction, { nullable: true })
  transaction?: Transaction;

  constructor(props: PackageBase) {
    super(props);

    this.order = props.order ? new Order(props.order) : null;
    this.orderItems = !!props.orderItems
      ? props.orderItems.map((i) => new OrderItem(i))
      : null;
    this.fulfillments = !!props.fulfillments
      ? props.fulfillments.map((f) => new Fulfillment(f))
      : null;
    this.shippingCharge = !!props.shippingCharge
      ? new ShippingCharge(props.shippingCharge)
      : null;
    this.refunds = !!props.refunds
      ? props.refunds.map((r) => new Refund(r))
      : null;
    this.store = props.store ? new Store(props.store) : null;
  }
}

/**
 * A Package is a subset of an {@link Order} with a specific fulfillment type and store.
 *
 * Package Numbers are generated on a sequential basis per store, and are prefixed with the
 * fulfillment type (`P`ickup, `S`hipping and `D`elivery) and postfixed with the store number.
 *
 * Package Numbers are generated in Back40 as they are created, by doing the following:
 *
 * - A scheduled service pre-generates 1000 package IDs per store in a table called `order_package_ids`
 *
 * - Assign an `owner` to one of those IDs using `UPDATE SET owner = xxx WHERE owner IS NULL AND store = xxx LIMIT 1
 *
 * - Take the ID from `order_package_ids` and combine it with our prefix (`P`, `S` or `D`) and our postfix (store number)
 *
 * TODO (Architecture) - How will order syncing connect the packages? What about if a package is converted?
 * Perhaps non-created syncs should be more targeted (status, AF or refunds).
 * Order syncing could be two ways, we update Ecom order items with a package ID they belong to
 *
 * @example
 * P-12345-01
 */
@ObjectType("Package", {})
export class Package extends PackageBase implements Entity<PackageBase> {
  /**
   * Helper function to generate a Reference value.
   *
   * E.g. `EP-123456-12`
   */
  @HideField()
  get reference() {
    const epicorPrefix = "E";

    return epicorPrefix + this.packageNumber.replace(/-/g, "_");
  }

  /**
   * Adds all of the totals of the orderItems belonging to a package to display as an aggregate
   */
  // @Field(() => GraphQLJSON)
  @HideField()
  get totals() {
    const subtotal = this.orderItems.reduce(
      (total, item) => total.add(item.netTotal ?? 0),
      currency(0)
    );
    /**
     * Total tax for all items
     */
    const tax = this.orderItems.reduce(
      (tax, item) => tax.add(item.tax ?? 0),
      currency(0)
    );
    /**
     * The amount saved by the customer!
     */
    const saved = this.orderItems.reduce(
      (saved, item) => saved.add(item.saved ?? 0),
      currency(0)
    );
    /**
     * Shipping cost
     */
    const shipping = currency(this.shippingCharge?.total ?? 0);

    /**
     * Total of the entire package - includes shipping and tax
     */
    const netTotal = currency(subtotal).add(shipping).add(tax);

    /**
     * The amount refunded agaist the shippingCharge of the package (if any)
     */
    const refundedShipping = this.refunds?.reduce((total, refund) => {
      if (!refund.shippingItems) return total;

      const amount = refund.shippingItems.reduce(
        (amount, item) => amount.add(item.total),
        currency(0)
      );

      return total.add(amount);
    }, currency(0));

    /**
     * Total amount refunded as tracked by refunds
     */
    const refunded = this.refunds?.reduce(
      (total, refund) => total.add(refund.total),
      currency(0)
    );

    /**
     * Coupon total (if any) that has been accomodated by our refunds
     */
    const couponTotal = this.refunds?.reduce(
      (total, refund) => total.add(refund?.couponTotal ?? 0),
      currency(0)
    );

    return {
      saved,
      tax,
      shipping,
      subtotal,
      /** @remarks This is a positive number */
      refunded,
      /** @deprecated use `netTotal` */
      total: netTotal,
      netTotal,
      refundedShipping,
      couponTotal,
    };
  }

  /**
   * Generates a `packageNumber` for this Package based on it's fulfillment info, and the provided `orderNumber`
   * @param orderNumber The `orderNumber` from the Order
   * @returns A `packageNumber`
   */
  generatePackageNumber(orderNumber: string) {
    const packageNumberParts = [];

    // prefix
    switch (this.fulfillmentMethod) {
      case "Pickup":
        packageNumberParts.push("P");
        break;
      case "Ship To Home":
        packageNumberParts.push("S");
        break;
      case "Delivery":
        packageNumberParts.push("D");
    }

    // order number
    const orderNumberParts = orderNumber.split("-");
    packageNumberParts.push(...orderNumberParts.slice(1));

    // store number
    packageNumberParts.push(this.storeNumber.toString().padStart(2, "0"));

    return packageNumberParts.join("-");
  }

  /**
   * Generates a order child number for F1.0 orderChild
   */
  generateOrderChildNumber() {
    if (!this.store || !this.order) {
      console.error(
        "Could not generate order child number. Please pass store and order entity to package"
      );

      return null;
    }

    return [
      this.fulfillmentMethod.slice(0, 1),
      this.order.externalId,
      this.storeNumber.toString().padStart(2, "0"),
    ].join("-");
  }

  /**
   * Determines what orderItems can be refunded based on the current packages refunds
   * and the quantityRefunded on its orderItems
   *
   * @returns Refundable orderItems for a package
   */
  @HideField()
  get refundableItems() {
    return this.orderItems
      .filter(
        (i) => !!(i.quantityRefunded - this.orderItemRefundQuantity(i.id))
      )
      .reduce((acc, orderItem) => {
        // Check all refunds for this orderItem and get the total quantity (NEGATIVE VALUE)
        const totalRefundedQuantity = this.orderItemRefundQuantity(
          orderItem.id
        );

        /**
         * If our totals are equal, that means that we have refund objects that
         * account for our quantityRefunded and we don't need to create another
         * refund for this orderItem
         */
        if (
          Math.abs(orderItem.quantityRefunded) ===
            Math.abs(totalRefundedQuantity) || // Are we already accounted for?
          Math.abs(totalRefundedQuantity) >= orderItem.quantity // Are we already fully refunded?
        ) {
          return acc;
        }

        if (orderItem.quantityRefunded > 0 || totalRefundedQuantity > 0) {
          console.log(
            "[Package Model] Bad data. Refund attempted to create with positive valuse"
          );
          return acc;
        }

        // Get the amount that we still need to refund
        const quantityDifference =
          orderItem.quantityRefunded - totalRefundedQuantity;

        // Calculate our start position
        const startPos = Math.abs(totalRefundedQuantity);
        // Calculate our end position
        const endPos = startPos + quantityDifference * -1;

        // Distribute our item tax across its quantity
        const distributedTaxes = currency(orderItem.tax).distribute(
          orderItem.quantity
        );

        // Reduce distributed amounts in order - This is an ordered list so we have to take into account the refunds that came before this
        const itemTaxTotal = distributedTaxes
          .slice(startPos, endPos)
          .reduce((total, tax) => currency(total).add(tax), currency(0))
          .multiply(-1);

        /**
         * Multiply our unitPrice, by the amount we are refunding.
         * Afterwards, add our tax to the total
         */
        const total = currency(orderItem.unitPrice)
          .multiply(quantityDifference)
          .add(itemTaxTotal);

        /**
         * We have quantity to refund but don't have a refund to account for it yet.
         * Create a lineItem and add it to the list of items that need to be refunded
         */
        const lineItem = {
          orderItemId: orderItem.id,
          externalId: orderItem.externalId,
          quantity: quantityDifference,
          tax: itemTaxTotal.value,
          total: total.value,
          adjustInventory: false,
        };

        // Otherwise return this item to be refunded
        return [...acc, lineItem];
      }, []);
  }

  /**
   * Determines if the package has been staged, either by fulfillment or by orderItems
   */
  @HideField()
  get isStaged() {
    return !!this.fulfillments[0].notifiedAt;
  }

  @HideField()
  get stagedAt() {
    if (!this.fulfillments && this.fulfillments.length === 0) return null;
    if (
      !this.fulfillments[0].stagedAt &&
      !this.orderItems &&
      this.orderItems.length === 0
    )
      return null;
    let stagedAt = this.fulfillments[0].stagedAt ?? null;
    stagedAt = stagedAt
      ? stagedAt
      : this.orderItems.length > 0
      ? this.orderItems[0].stagedAt
      : null;

    return stagedAt;
  }

  @HideField()
  get isFullyRefunded() {
    const { netTotal, refunded, couponTotal } = this.totals;

    const totalRefunded = currency(refunded).add(couponTotal);

    return netTotal.value === totalRefunded.value;
  }

  /**
   * Gets the total refunded quantity for a particular orderItem.
   *
   * @param externalId - OrderItem id
   * @returns
   */
  orderItemRefundQuantity(id: number): number {
    return this.refunds.reduce(
      (quantity, refund) =>
        (quantity += refund.lineItems
          .filter((lineItem) => lineItem.orderItemId === id)
          .reduce((q, item) => (q += item.quantity), 0)),
      0
    );
  }

  /**
   * Takes in a refund and determines if we can add it to the package's
   * current refunds. If the package can refund the items/amount it
   * will return true, otherwise false.
   *
   * Currently our validation logic checks 3 issues. A refund is valid if
   *
   * 1. LineItem quantity to be refunded <= quantityAvailable (quantity - quantityRefunded)
   * of an OrderItem.
   * 2. ShippingItems (these being shipping charge refunds) do not exceed the total shipping
   * charge of this package (if it even has one).
   * 3. If there is an additional amount that is not accounted for by 1/2, and the package can
   * account for that additional amount, then we are valid.
   *
   * If any of the above fails our validation, the refund is not refundable in its
   * current state.
   *
   * @param potentialRefund Refund that we are validating against the current package
   * @returns
   */
  validateRefund(potentialRefund: Refund) {
    if (!this.refunds || !this.orderItems) {
      console.log("[Package Model] Refunds/OrderItems object not found!!!");
      return {
        hasValidTotal: false,
        isRefundable: false,
        isQuantityAvailable: false,
        isShippingChargeValid: false,
        shippingChargeRefund: 0,
        amountAvailableToRefund: 0,
      };
    }

    const {
      total: totalRefundableAmount,
      refunded: totalRefundedAmount,
      refundedShipping: totalRefundedShipping,
      shipping: totalShippingCharge,
    } = this.totals;

    /**
     * This is by default valid if we have no shippingItems to refund
     */
    let isShippingChargeValid =
      !potentialRefund?.shippingItems ||
      potentialRefund?.shippingItems.length === 0;

    /**
     * This is a negative number. Gives us the amount of shipping the user wishes to refund
     * against this package
     *
     * This is a negative number
     */
    const shippingChargeRefund =
      potentialRefund?.shippingItems.reduce(
        (amount, item) => amount.add(item.total),
        currency(0)
      ).value || 0;

    /**
     * Problem 1:
     *
     * Ensure our lineItem quantities match the quantity available of the
     * OrderItems of this package.
     *
     * If we don't have lineItems then this automatically passes
     */
    const isQuantityAvailable =
      potentialRefund?.lineItems && potentialRefund?.lineItems.length === 0
        ? true
        : potentialRefund?.lineItems.reduce((isQuantityAvailable, lineItem) => {
            // If we are already false, short circuit
            if (!isQuantityAvailable) return isQuantityAvailable;

            const orderItem = this.orderItems.find(
              (orderItem) => orderItem.externalId === lineItem.externalId
            );

            // If we don't have a matching orderItem, there is a problem
            if (!orderItem) {
              console.log(
                `[Package Model] OrderItem not with externalId: ${lineItem.externalId} found!!!`
              );
              return false;
            }

            const totalRefundedAccountedFor = this.orderItemRefundQuantity(
              orderItem.id
            );

            // Calculate the total refunded with this refund
            const totalRefunded =
              (lineItem.quantity + totalRefundedAccountedFor) * -1;

            // If we exceed the total quantity, we are invalid
            if (totalRefunded > orderItem.quantity) {
              console.log(
                `[Package Model] Total refunded is greater than orderItem quantity: `,
                orderItem,
                totalRefunded
              );

              return false;
            }

            return isQuantityAvailable;
          }, true);

    // If we have failed condition 1, return
    if (!isQuantityAvailable)
      return {
        hasValidTotal: false,
        isRefundable: false,
        isQuantityAvailable: false,
        isShippingChargeValid,
        shippingChargeRefund,
        amountAvailableToRefund: 0,
      };

    /**
     * Problem 2:
     *
     * If we have shippingItems attached to this refund, then we need compare those items
     * with this package's shippingCharge (Take not that we should not be able to have
     * shippingItems on a Pickup package).
     */
    if (!isShippingChargeValid) {
      // If we are a Pickup package, we cannot process this reques
      if (this.fulfillmentMethod !== "Ship To Home") {
        console.log(
          `[Package Model] No shipping charge found on package: ${this}`
        );
        return {
          hasValidTotal: false,
          isRefundable: false,
          isQuantityAvailable,
          isShippingChargeValid,
          shippingChargeRefund: 0,
          amountAvailableToRefund: 0,
        };
      }

      /**
       * As long as we are >= 0 we are valid. ShippingCharge values are not considered when
       * dealing with additional amount logic
       */
      isShippingChargeValid =
        currency(totalShippingCharge)
          .add(totalRefundedShipping)
          .add(shippingChargeRefund).value >= 0;
    }

    /**
     * Problem 3:
     *
     * If we have made it this far, then we need to check to make sure the package can
     * refund the total amount of the new refund.
     *
     * totalRefundedAmount - totalRefundableAmount = amountAvailableToRefund
     *
     * (These values do not include the incoming refund)
     */

    /**
     * Calculate the amount we have left that we can refund against the package
     */
    const amountAvailableToRefund = currency(totalRefundableAmount)
      .subtract(totalRefundedAmount)
      .subtract(totalShippingCharge)
      .subtract(totalRefundedShipping).value;

    /**
     * isRefundable - This is true if the refund amount can be accomadated by this
     * package. If our total is <=0 we are also invalid
     *
     * isQuantityAvailable - If we have made it here, then this is true as we have
     * checked it above
     *
     * amountAvailableToRefund - Returns the packages amountAvailableToRefund (Does not include shipping)
     *
     * isShippingChargeValid - Returns true if the refund can accomadate a shipping charge,
     * doesn't have a shipping charge, or wasn't passed a shipping charge
     */
    return {
      hasValidTotal: currency(potentialRefund.total).value > 0,
      isRefundable:
        amountAvailableToRefund >=
        currency(potentialRefund.total).add(shippingChargeRefund).value,
      isQuantityAvailable: true,
      isShippingChargeValid,
      shippingChargeRefund,
      amountAvailableToRefund,
    };
  }

  /**
   * Creates a refund based on the package's current refundableItems. This does
   * not take into account additional amounts. This is a barebones refund, any
   * other fields would need to be passed to the function or modified later.
   *
   * @param refund
   * @returns
   */
  createRefund({
    refund,
    shippingRefundAmount,
  }: {
    refund?: Partial<Refund>;
    shippingRefundAmount?: number;
  }) {
    const lineItems = refund?.lineItems ?? this.refundableItems;
    const total = lineItems
      .reduce((total, item) => total.add(item.total * -1), currency(0))
      .add(shippingRefundAmount);
    let shippingItems = [];

    if (shippingRefundAmount) {
      const { shipping, refundedShipping } = this.totals;

      const amountAvailable = shipping.subtract(refundedShipping);

      if (amountAvailable.value < shippingRefundAmount) {
        console.log(
          "[Package Model] Invalid shippingRefundAmount",
          amountAvailable.value,
          shippingRefundAmount
        );
        return null;
      }

      shippingItems.push({
        externalId: this.shippingCharge.externalId,
        total: shippingRefundAmount * -1,
      });
    }

    // If a refund was not passed and we have no lineItems, we can't create a refund.
    if (!refund && lineItems.length === 0 && shippingItems.length === 0)
      return null;

    return new Refund({
      id: null,
      _packageId: this.id,
      total: total.value,
      lineItems,
      shippingItems,
      ...refund,
    });
  }

  /**
   * TODO
   *
   * @returns
   */
  toObject() {
    return null;
  }
}

@InputType()
export class PackageInput extends OmitType(
  Package,
  [
    "orderItems",
    "deliveries",
    "fulfillments",
    "refunds",
    "order",
    "shippingCharge",
    "store",
    "activityLog",
    "transaction",
  ] as const,
  InputType
) {
  @Field(() => [OrderItemInput])
  orderItems?: OrderItemInput[];

  @Field(() => [FulfillmentInput])
  fulfillments?: FulfillmentInput[];
  @Field(() => [RefundInput], { nullable: true })
  refunds?: RefundInput[];
  @Field(() => ShippingChargeInput, { nullable: true })
  shippingCharge?: ShippingChargeInput;
  @Field(() => OrderInput, { nullable: true })
  order?: OrderInput;
  @Field(() => StoreInput, { nullable: true })
  store?: StoreInput;
}
