import { Field, Int, ObjectType } from "@nestjs/graphql";
import { Refund } from "../refund.model";

@ObjectType()
export class PaginatedRefund {
  @Field((type) => [Refund])
  results: Refund[];

  @Field((type) => Int)
  total: number;
}
