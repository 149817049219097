import { Field, ObjectType } from "@nestjs/graphql";
import { DomainResponse, ResponseStatus } from "../../../../interfaces";
import { Fulfillment } from "../fulfillment.model";

@ObjectType()
export class ReserveFulfillmentResponse extends DomainResponse {
  @Field(() => ResponseStatus)
  status: ResponseStatus;

  @Field(() => String)
  message: string;

  @Field(() => [Fulfillment], { nullable: "itemsAndList" })
  result?: Fulfillment[];

  constructor(props: Partial<DomainResponse>) {
    super(props);
  }
}
