export interface AdStoreVersion {
  name: string;
  selected: boolean;
  stores: number[];
  isDefault: boolean;
  type: "template" | "created";
}
export interface IncludedMedia {
  name: string;
  selected: boolean;
  isDefault: boolean;
}
