import { fabric } from "fabric";
import { TokenValue } from "../domain/signs";
export type Token =
  | fabric.IText
  | fabric.Image
  | fabric.Group
  | fabric.Object
  | fabric.Textbox;

export class PriceGroup extends fabric.Group {
  constructor(objects, options, isAlreadyGrouped = true) {
    super(objects, options, isAlreadyGrouped);
  }
  /**
   * When we initialize, we need to make sure that we are centered,
   * relative to the time in which we created the template. Due to
   * the dynamic nature of creating Signs, by calling resize group
   * we can ensure that regardless of what omni sent us, we should
   * be able to make it look how the user intended it.
   */
  initialize(elements, options?: any): fabric.Object {
    const group = fabric.Group.prototype.initialize.call(
      this,
      elements,
      options
    );
    if (options?.canvasWidth) {
      this.resizeGroup(options?.canvasWidth);
    }
    return group;
  }

  private _resizeChildren(objects: ResizeTextBox[]) {
    // if (objects.length !== 5) {
    //   throw new Error("Invalid price-group: _resizeChildren FN");
    // }

    // Destructure our price-group - The prefix for a price group
    // will always be the same fontsize as the price token
    const [_, accessory, price, suffix, withRewards] = objects;

    // Use its new fontSize to scale the supplemental tokens
    const { fontSize: _fontSize } = price;
    const values = { fontSize: _fontSize / 2 };

    [accessory, suffix].forEach((token) => {
      Object.keys(values).map((key) => {
        token._set(`${key}`, values[key]);
      });
    });

    if (withRewards) {
      withRewards._set("fontSize", _fontSize / 8);
    }
  }

  resizeGroup(canvasWidth?: number) {
    let hasResized = false;
    let width = this._recalculateWidth();

    /**
     * If we hit this, then we have run into a scaled up token that is now
     * too big to fit in the canvas. We will reduce its scale to 1 or until
     * it fits into the canvas. If this isn't enough, then we will reduce fontChris Bates
     */
    if (canvasWidth && this.scaleX > 1 && width >= canvasWidth / 1.05) {
      while (width >= canvasWidth / 1.05 && this.scaleX > 1) {
        let newScale = this.scaleX / 1.05;
        newScale = newScale >= 1 ? newScale : 1;
        this.scale(newScale);

        width = this._recalculateWidth();
        hasResized = true;
        width += width * 0.05;
      }
    }

    const {
      mt: { x: prevXPos },
    } = this.calcCoords();
    this.getObjects().forEach((obj: ResizeTextBox) =>
      obj.resizeSelf(canvasWidth)
    );
    this._resizeChildren(this.getObjects() as ResizeTextBox[]);

    /**
     * Get the current scaled width of all of our objects in our
     * group so that way we can resize out group if need be.
     */
    // width = this._recalculateWidth();
    width = this.getObjects()
      .filter(
        (element) =>
          element.name !== "suffix" && element.name !== "with-rewards-fixed"
      )
      .reduce((total, element) => (total += element.getScaledWidth()), 0);

    if (this.name === "price-group") {
      const [price, suffix, withRewards] = this.getObjects().filter(
        (element) =>
          element.name === "suffix" ||
          element.name === "with-rewards-fixed" ||
          element.name === "price"
      ) as ResizeTextBox[];

      /**
       * If the suffix has a value, use its scaled width, otherwise
       * if withRewards has an opacity, use its scaled width
       */
      if (withRewards && !!withRewards.get("opacity")) {
        width += withRewards.getScaledWidth();
      } else if (!!suffix.get("text")) {
        width += suffix.getScaledWidth();
      }

      this._set("height", price.height);
    }

    if (canvasWidth) {
      if (width > canvasWidth) {
        width = canvasWidth;
      }
    }

    // Set new width of PriceGroup
    this._set("width", width);

    const {
      mt: { x: xPos },
    } = this.calcCoords();

    // Get difference in positions
    const xPosDiff = xPos - prevXPos;

    // Set our new left position
    let newLeft = this.left - xPosDiff;

    newLeft = hasResized
      ? canvasWidth / 2 - (width * this.scaleX) / 2
      : newLeft;

    // Shift group left
    this._set("left", newLeft);
    const objects = this.getObjects();

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      const fontSize = (obj as ResizeTextBox).get("fontSize");
      let top = (this.height / 2 + fontSize / 6) * -1;
      // let top = (this.height / 2) * -1;

      // If this is the first one
      if (i === 0) {
        /**
         * This left position is relative to the group, not the canvas.
         * A left of 0 is the center of the group, since we want to start
         * on the left border of the group, we need a - left value of
         * half the width of the group.
         */
        const left = (this.width / 2) * -1;
        obj._set("left", left);
        obj._set("top", top);
      } else {
        /**
         * Everything else repositions themselves next
         * to the one before it
         */
        const { left, width, height, top: _top } = objects[i - 1];
        const prevObj = objects[i - 1];
        // const _height = (obj as ResizeTextBox).get("height");

        // if (obj.name === "price") {
        //   obj._set("left", left + width);
        //   obj._set("top", top - _height / 2);
        // } else
        if (obj.name === "with-rewards-fixed") {
          const previousValue = (prevObj as ResizeTextBox).get("text");
          const formattedTop = !!previousValue
            ? _top + height
            : (this.height / 4) * -1;

          obj._set("left", left);
          obj._set("top", formattedTop);
        } else {
          obj._set("left", left + width);
          obj._set("top", top);
        }
      }
    }
  }

  private _measureToken(element: fabric.Textbox, scale: number) {
    let pieces = element.text ? element.text.split("") ?? [] : [];

    return (
      pieces.reduce(
        (total, piece, i) => (total += element._measureWord([piece], 0, i)),
        0
      ) * scale
    );
  }

  private _recalculateWidth() {
    const objects = this.getObjects();
    let width = 0;

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];

      if (obj.name === "with-rewards-fixed") continue;

      if (obj.name === "suffix" && this.name === "price-group") {
        const nextObj = objects[i + 1];
        const isVisible = !!nextObj
          ? (nextObj as ResizeTextBox).get("opacity") > 0
          : false;
        const hasText = !!(obj as ResizeTextBox).get("text");

        /**
         * If 'with-rewards-fixed' is visible and we have a text value in our suffix
         * add width
         */
        width +=
          isVisible || hasText
            ? (obj as ResizeTextBox)._offset() * this.scaleX
            : 0;
        continue;
      }

      width += this._measureToken(obj as fabric.Textbox, this.scaleX);
    }

    return width;
  }
}

export class ResizeTextBox extends fabric.Textbox {
  resizeSelf(canvasWidth?: number, scaleFactor = 1) {
    // If we have more than one textline, then we need to adjust our top
    // position to be relative to the group we are going to shrink to fit to
    let pieces = this.text ? this.text.split("") ?? [] : [];
    let resize = this.textLines.length > 1;

    const values = {
      width: this.width * scaleFactor,
    };

    if (this.name === "with-rewards" || this.name === "sub-title") return;

    if (this.name === "with-rewards-fixed") {
      const rewards = "REWARDS";
      const _width = rewards
        .split("")
        .reduce(
          (total, piece, i) => (total += this._measureWord([piece], 0, i)),
          0
        );
      this._set("width", _width);
      return;
    }

    if (this.name !== "title" && this.name !== "sub-title") {
      values.width = pieces.reduce(
        (total, piece, i) => (total += this._measureWord([piece], 0, i)),
        0
      );

      resize = resize || values.width.toFixed(0) !== this.width.toFixed(0);
    }

    /**
     * If a token exceeds the width of the canvas (as it does with BOGO), on
     * init we will reduce the fontSize of the token until it falls within
     * the proper width of the canvas
     */
    if (canvasWidth) {
      // Max width is 95% of canvasWidth (for padding)
      const PADDING = canvasWidth * 0.05;
      const maxWidth = canvasWidth - PADDING;
      const CHAR_LIMIT = 50;

      // If we are too big, make sure we are within the char limit and scale down
      // the fontSize
      if (values.width > maxWidth || resize) {
        if (this.text && this.text.length > CHAR_LIMIT) {
          this._set("text", this.text.slice(0, CHAR_LIMIT));
          pieces = this.text.split("");
        }

        this._set("left", PADDING / 2);
        while (values.width > maxWidth || resize) {
          this._set("fontSize", this.fontSize - 0.2);

          values.width = pieces.reduce(
            (total, piece, i) => (total += this._measureWord([piece], 0, i)),
            0
          );

          values.width *= scaleFactor;
          values.width += values.width * 0.05;
          resize = false;
        }
      }
    }

    values.width = values.width / scaleFactor;

    /**
     * If our width didn't change and we aren't initializing
     * skipperino
     */
    if (values.width === this.width && !canvasWidth) return;

    Object.keys(values).map((key) => this._set(`${key}`, values[key]));
  }

  _offset() {
    return ["9", "9"].reduce(
      (total, piece, i) => (total += this._measureWord([piece], 0, i)),
      0
    );
  }
}
export class TokenManager {
  private _token: Token;

  constructor(token: TokenValue | Token, canvasWidth = null) {
    if (token instanceof TokenValue) {
      this._token = this.JSONToToken(token, canvasWidth);
    } else {
      this._token = token;
    }
  }

  get token() {
    return this._token;
  }

  private JSONToToken(token, canvasWidth?: number): Token {
    switch (token.type) {
      case "textbox":
        const textBox = new ResizeTextBox("textbox", {
          ...token.attributes,
          text: token.attributes?.text ?? "",
        }).setControlsVisibility({
          mt: false,
          mb: false,
          tl: false,
          tr: false,
          br: false,
          bl: false,
          mtr: false,
        });

        if (canvasWidth) {
          textBox.resizeSelf(canvasWidth);
        }

        return textBox;
      case "price-group":
        return new PriceGroup(
          token.objects.map((childToken) => this.JSONToToken(childToken)),
          {
            ...token.attributes,
            canvasWidth,
          }
        ).setControlsVisibility({
          mtr: false,
          mr: false,
          ml: false,
          mt: false,
          mb: false,
        });
      case "date-group":
      case "sku-group":
      case "group":
        return new fabric.Group(
          token.objects.map((childToken) => this.JSONToToken(childToken)),
          token.attributes
        ).setControlsVisibility({
          mtr: false,
          mr: false,
          ml: false,
          mt: false,
          mb: false,
        });
      default:
        console.warn("[JSONToToken Fn] Type not setup:", token.type);
        return null;
    }
  }

  /**
   * As our needs for this expand we may need to add more token types. If
   * we do, add them to the switch and modify the TokenType
   *
   * @param token - This could be any fabric element
   * @returns - returns necessary data to reconstruct fabric object
   */
  private tokenToJSON(token: Token): TokenValue {
    const { type } = token;

    return new TokenValue({
      type,
      objects: type.includes("group")
        ? (token as fabric.Group)
            .getObjects()
            .map((obj) => this.tokenToJSON(obj))
        : null,
      attributes: { ...token.toObject(), name: token.name },
    });
  }

  toJSON() {
    return this.tokenToJSON(this.token);
  }
}
