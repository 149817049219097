import { Field, HideField, InputType, Int } from "@nestjs/graphql";
import { Category } from "../category.model";

/** @ignore */
@InputType()
export class IGetCategoriesConfig {
  @Field((type) => Int, { nullable: true })
  limit?: number;
  @Field((type) => Int, { nullable: true })
  offset?: number;
  @Field((type) => Boolean, { nullable: true })
  hasGroup?: boolean;
  @Field((type) => [Int], { nullable: true })
  withIds?: Array<Category["id"]>;
  @Field((type) => [Int], { nullable: true })
  withParentIds?: Array<Category["id"]>;
  @Field((type) => [Int], { nullable: true })
  ids?: number[];
  @HideField()
  withRelations?: Array<"parent">;
}
